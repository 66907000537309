import { ARG_TEMPLATE_ID, ARG_UID } from '../../utils/constants';

/**
 * An abstract class that represents a path to a location in Firebase Storage.
 * @param path The path to the location in Firebase Storage.
 */
export abstract class FireStorageReferencePath {
  protected constructor(public path: string) { }

  /**
   * A class that represents the path to a user's profile in Firebase Storage.
   */
  static ProfileReferencePath = class extends FireStorageReferencePath {
    constructor() {
      super(`users/${ARG_UID}`);
    }

    /**
     * Gets the path to a user's profile folder.
     * @param uid The ID of the user whose profile folder to get.
     * @return A FolderReferencePath object that represents the path to the user's profile folder.
     */
    getPath(uid: string): FolderReferencePath {
      return new FolderReferencePath(this.path.replace(ARG_UID, uid));
    }

    /**
     * Gets the path to a file in a user's profile folder.
     * @param uid The ID of the user whose profile folder contains the file.
     * @param fileName The name of the file.
     * @return A FileReferencePath object that represents the path to the file.
     */
    getFilePath(uid: string, fileName: string): string {
      return this.path.replace(ARG_UID, uid)
        .concat("/")
        .concat(fileName);
    }
  };

  /**
   * A class that represents the path to a template in Firebase Storage.
   */
  static TemplateReferencePath = class extends FireStorageReferencePath {
    constructor() {
      super(`users/${ARG_UID}/templates/${ARG_TEMPLATE_ID}/`);
    }

    /**
     * Gets the path to a user's template folder.
     * @param uid The ID of the user whose template folder to get.
     * @param templateId The ID of the template within the user's template folder.
     * @return A FolderReferencePath object that represents the path to the user's template folder.
     */
    getPath(uid: string, templateId: string): FolderReferencePath {
      return new FolderReferencePath(
        this.path.replace(ARG_UID, uid).replace(ARG_TEMPLATE_ID, templateId)
      );
    }

    /**
     * Gets the path to a file in a user's template folder.
     * @param uid The ID of the user whose template folder contains the file.
     * @param templateId The ID of the template within the user's template folder.
     * @param fileName The name of the file.
     * @return A FileReferencePath object that represents the path to the file.
     */
    getFilePath(uid: string, templateId: string, fileName: string): string {
      return this.path
        .replace(ARG_UID, uid)
        .replace(ARG_TEMPLATE_ID, templateId)
        .concat(fileName)
        ;
    }
  };
}

/**
 * Data class representing a path to a file in Firebase Storage.
 * @param path The path to the file in Firebase Storage.
 */
export class FileReferencePath {
  constructor(public path: string) { }
}

/**
 * Data class representing a path to a folder in Firebase Storage.
 * @param path The path to the folder in Firebase Storage.
 */
export class FolderReferencePath {
  constructor(public path: string) { }
}
