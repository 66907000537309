import Stripe from "stripe";
import { OrderType } from "./OrderType";
import { PayPalOrderRequest } from "./paypal/PayPalOrderRequest";
import { PayPalOrderResponse as PayPalOrderResponse } from "./paypal/PayPalOrderResponse";
import { DocumentData, Timestamp } from 'firebase/firestore';

/**
 * Represents the details of an order.
 */
export class OrderDetails {
    /**
     * Creates an instance of `OrderDetails`.
     * @param {Timestamp} timestamp The timestamp of the order.
     * @param {string} referenceId The reference ID of the order.
     * @param {string} orderId The ID of the order.
     * @param {string} durationKey The duration key of the promotion.
     * @param {string} price The price of the order.
     * @param {string} currency The currency of the order.
     * @param {OrderType} type The type of the order.
     * @param {PayPalOrderRequest} [paypalRequest] The request that created this order.
     * @param {PayPalOrderResponse} [paypalResponse] The order that created this order.
     * @param {Stripe.Checkout.Session} [stripeSession] The Stripe Payment Intent request.
     */
    constructor(
        public timestamp: Timestamp,
        public referenceId: string,
        public orderId: string,
        public durationKey: string,
        public price: string,
        public currency: string,
        public type: OrderType,
        public paypalRequest: PayPalOrderRequest | null,
        public paypalResponse: PayPalOrderResponse | null,
        public stripeSession: Stripe.Checkout.Session | null // Add this line
    ) {
        this.timestamp = timestamp;
        this.referenceId = referenceId;
        this.orderId = orderId;
        this.durationKey = durationKey;
        this.price = price;
        this.currency = currency;
        this.type = type;
        this.paypalRequest = paypalRequest;
        this.paypalResponse = paypalResponse;
        this.stripeSession = stripeSession;
    }

    isUserRelevant() {
        return this.paypalResponse?.status !== "PENDING" ||
            this.stripeSession?.status != "expired";

    }

    isCompleted() {
        return this.paypalResponse?.status === "COMPLETED" ||
            this.stripeSession?.status === "complete";
    }

    getSource() {
        if (this.paypalResponse) {
            return "PayPal";
        } else if (this.stripeSession) {
            return "Stripe";
        } else {
            return "Unknown";
        }
    }

}

/**
 * Firestore data converter for `OrderDetails`.
 */
export const orderDetailsConverter = {
    /**
     * Converts an `OrderDetails` object to a Firestore document.
     * @param {OrderDetails} orderDetails The `OrderDetails` object to convert.
     * @return {DocumentData} The Firestore document data.
     */
    toFirestore(orderDetails: OrderDetails): DocumentData {
        return {
            timestamp: orderDetails.timestamp,
            referenceId: orderDetails.referenceId,
            orderId: orderDetails.orderId,
            durationKey: orderDetails.durationKey,
            price: orderDetails.price,
            currency: orderDetails.currency,
            type: orderDetails.type,
            paypalRequest: orderDetails.paypalRequest,
            paypalResponse: orderDetails.paypalResponse,
            stripeSession: orderDetails.stripeSession,
        };
    },
    /**
     * Converts a Firestore document to an `OrderDetails` object.
     * @param {DocumentSnapshot} snapshot The Firestore document snapshot.
     * @return {OrderDetails} The `OrderDetails` object.
     */
    fromFirestore(snapshot: DocumentData): OrderDetails {
        const data = snapshot.data();
        return new OrderDetails(
            data.timestamp,
            data.referenceId,
            data.orderId,
            data.durationKey,
            data.price,
            data.currency,
            data.type,
            data.paypalRequest,
            data.paypalResponse,
            data.stripeSession
        );
    },
};
