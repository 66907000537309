/**
 * Model for TemplatePromotionConfig.
 */
export class TemplatePromotionConfig {
    public key: string;
    public price: string;
    public duration: string;
    public currencyCode: string;

    constructor(key: string, price: string, duration: string, currencyCode: string) {
        this.key = key;
        this.price = price;
        this.duration = duration;
        this.currencyCode = currencyCode;
    }

    /**
     * Converts an array of remote config objects to an array of TemplatePromotionConfig objects.
     * @param configArray
     * @return An array of TemplatePromotionConfig objects.
     */
    static fromRemoteConfigArray(configArray: any[]): TemplatePromotionConfig[] {
        return configArray.map((config) => {
            return new TemplatePromotionConfig(config.key, config.price, config.duration, config.currency_code);
        });
    }

    /**
     * Converts a remote config object to a TemplatePromotionConfig object.
     * @param config 
     * @return A TemplatePromotionConfig object.
     */
    static fromRemoteConfig(config: any): TemplatePromotionConfig {
        const mappedConfig = {
            key: config.key,
            price: config.price,
            duration: config.duration,
            currencyCode: config.currency_code,
        };

        return new TemplatePromotionConfig(mappedConfig.key, mappedConfig.price, mappedConfig.duration, mappedConfig.currencyCode);
    }
}