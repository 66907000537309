export class Logger {

    constructor(private className: string) { }

    /**
     * Checks if the application is running on localhost.
     * @returns `true` if running on localhost, `false` otherwise.
     */
    private static isLocalhost(): boolean {
        return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    }

    /**
     * Custom logging function.
     * @param message - The message to log.
     * @param optionalParams - Additional parameters to log.
     */
    public log(message?: any, ...optionalParams: any[]): void {
        console.log(`${this.className}:`, message, ...optionalParams);
    }

    /**
     * Custom error logging function. Logs errors only if the application is running on localhost.
     * @param message - The error message to log.
     * @param optionalParams - Additional parameters to log.
     */
    public logError(message?: any, ...optionalParams: any[]): void {
        if (Logger.isLocalhost()) {
            console.error(`${this.className} (Error):`, message, ...optionalParams);
        }
    }
}
