import { Logger } from "../utils-logging";

const logger = new Logger("utils-urls-template");

/**
 * Resolve the actual Notion template product ID.
 * @param paramId The template product ID.
 * @return The actual template product ID.
 */
export function getNotionTemplateId(paramId: string | undefined): string | undefined {
    let actualId = null;
    if (paramId && paramId.includes("-")) {
        const parts = paramId.split("-");
        const lastPart = parts[parts.length - 1];
        actualId = lastPart;
        logger.log("loadTemplate: Actual ID", actualId);
    } else {
        actualId = paramId;
    }
    return actualId;
}

/**
 * Get the Notion template title URL.
 * @param title The template title.
 * @param templateId The template product ID.
 * @return The Notion template title URL.
 */
export function getNotionTemplateTitleUrl(title: string, templateId: string): string {
    const titleSlug = title.toLowerCase().replace(/[^a-z0-9\s]/g, "").replace(/\s+/g, "-");
    const id = titleSlug + "-" + templateId;
    const url = `https://www.elcovia.com/t/${id}`;
    logger.log("loadTemplate: Data", { title, templateId, titleSlug, id, url });
    return url;
}