import React, { useState, useEffect } from "react";
import SwiperCore, { EffectCreative, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { localizedValueWithKey } from "../../../../utils/supported-locales";
import SwiperIndicatorComponent from "../../../../components/common/state/SwiperIndicator";

SwiperCore.use([EffectCreative, Autoplay]);

interface TemplateCoverImageSectionProps {
  customImageClasses?: string;
  coverImages: Record<string, string[]>;
  title: Record<string, string>;
  locale: string;
  onViewImages?: (images: string[]) => void;
}

const TemplateCoverImageSection: React.FC<TemplateCoverImageSectionProps> = ({
  customImageClasses,
  coverImages,
  title,
  locale,
  onViewImages,
}) => {
  const [swiperSize, setSwiperSize] = useState(0);
  const [swiperActiveIndex, setSwiperActiveIndex] = useState(0);

  useEffect(() => {
    setSwiperSize(localizedValueWithKey(coverImages, locale)?.length || 0);
  }, [coverImages, locale]);

  return (
    <>
      {(localizedValueWithKey(coverImages, locale)?.length || 0) > 1 ? (
        <div className="">
          <div
            className={
              customImageClasses || "h-full w-full object-cover object-center"
            }
          >
            <Swiper
              pagination={{ clickable: true }}
              modules={[EffectCreative, Autoplay]}
              navigation={true}
              loop={true}
              speed={1000}
              onClick={() => {
                const images = localizedValueWithKey(coverImages, locale);
                if (images && onViewImages) {
                  onViewImages(images);
                }
              }}
              className="coverImagesSwiper"
              autoplay={{ delay: 8000 }}
              spaceBetween={20}
              style={{ padding: "0px" }}
              onSlideChange={(swiper) =>
                setSwiperActiveIndex(swiper.activeIndex)
              }
            >
              {localizedValueWithKey(coverImages, locale)?.map(
                (image: string, index) => (
                  <SwiperSlide key={index} className="p-1">
                    <div className="shadow-md">
                      <img
                        src={image}
                        alt={localizedValueWithKey(title, locale)!!}
                      />
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
          <div className="w-full mt-4 flex justify-center">
            <SwiperIndicatorComponent
              current={swiperActiveIndex}
              max={swiperSize}
            />
          </div>
        </div>
      ) : (
        <div className="lg:col-span-2">
          <img
            onClick={() => {
              const images = localizedValueWithKey(coverImages, locale);
              if (images && onViewImages) {
                onViewImages(images);
              }
            }}
            src={localizedValueWithKey(coverImages, locale)?.[0] || ""}
            alt={localizedValueWithKey(title, locale)!!}
            className={
              customImageClasses ||
              "h-full w-full object-cover object-center shadow-md"
            }
          />
        </div>
      )}
    </>
  );
};

export default TemplateCoverImageSection;
