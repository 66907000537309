import Stripe from 'stripe';
import { app } from '../utils/firebase';
import { HttpsCallableResult, getFunctions, httpsCallable } from "firebase/functions";

// Name of functions
const FUNCTION_PUBLISH_DRAFT = "publishDraft";
const FUNCTION_PROMOTE_TEMPLATE = "promoteTemplate";
const FUNCTION_ADD_TO_MAILCHIMP_LIST = "addToMailchimpList"
const FUNCTION_DELETE_ACCOUNT_DATA = "deleteAccountData"

const FUNCTION_UPDATE_PROFILE_VIEWS = "updateProfileViews";

const FUNCTION_UPDATE_TEMPLATE_VIEWS = "updateTemplateViews";
const FUNCTION_UPDATE_TEMPLATE_LIKES = "updateTemplateLikes";
const FUNCTION_UPDATE_UNDO_TEMPLATE_LIKES = "updateUndoTemplateLikes";

const FUNCTION_MARKETPLACE_TEMPLATE_CHECKOUT = "createMarketplaceTemplateCheckout";
const FUNCTION_MARKETPLACE_GET_STRIPE_ONBOARDING_LINK = "getStripeAccountOnboardingLink"

const FUNCTION_MARKETPLACE_UNLOCK_FREE_TEMPLATE = "unlockMarketplaceFreeTemplate";

const FUNCTION_ORDER_STRIPE_TEMPLATE_PROMOTION = "orderStripeTemplatePromotion";
const FUNCTION_ORDER_PAYPAL_TEMPLATE_PROMOTION = "orderPayPalTemplatePromotion";
const FUNCTION_CHECK_PAYPAL_ORDER_AND_PROMOTE_TEMPLATE = "checkPayPalOrderAndPromoteTemplate";

const FUNCTION_ORDER_STRIPE_PROFILE_PROMOTION = "orderStripeProfilePromotion";
const FUNCTION_ORDER_PAYPAL_PROFILE_PROMOTION = "orderPayPalProfilePromotion";
const FUNCTION_CHECK_PAYPAL_ORDER_AND_PROMOTE_PROFILE = "checkPayPalOrderAndPromoteProfile";

const FUNCTION_ADMIN_UPDATE_TEMPLATE_METADATA = "onUpdateTemplateMetaData";
const FUNCTION_ADMIN_UPDATE_EXTERNAL_URL = "onUpdateExternalUrl";
const FUNCTION_ADMIN_PUBLISH_TEMPLATE_PINTEREST = "onPublishTemplateOnPinterest";



// Region of the Firebase Functions
const FUNCTIONS_REGION = "europe-west3";

// Initialize Firebase functions instance with region
const firebaseFunctions = getFunctions(app, FUNCTIONS_REGION);

/**
 * Publishes a draft template.
 * @param templateId The ID of the template to publish.
 */
export function publishDraftTemplate(templateId: string): Promise<HttpsCallableResult> {
  const publish = httpsCallable(firebaseFunctions, FUNCTION_PUBLISH_DRAFT);
  return publish({ templateId: templateId });
}

export function unlockMarketplaceFreeTemplate(templateId: string): Promise<HttpsCallableResult> {
  const unlock = httpsCallable(firebaseFunctions, FUNCTION_MARKETPLACE_UNLOCK_FREE_TEMPLATE);
  return unlock({ templateId: templateId });
}

export function unlockMarketplaceFreeTemplateAnonymously(templateId: string, userName: string, userMail: string): Promise<HttpsCallableResult> {
  const unlock = httpsCallable(firebaseFunctions, FUNCTION_MARKETPLACE_UNLOCK_FREE_TEMPLATE);
  return unlock({ templateId: templateId, name: userName, mail: userMail });
}

/**
 * Updates the metadata of a template.
 * @param templateId The ID of the template to update.
 * @param forceUpdate Whether to force update the metadata.
 */
export function adminUpdateTemplateMetadata(templateId: string, forceUpdate: boolean): Promise<HttpsCallableResult> {
  const update = httpsCallable(firebaseFunctions, FUNCTION_ADMIN_UPDATE_TEMPLATE_METADATA);
  return update({ templateId: templateId, forceUpdate: forceUpdate });
}

/**
 * Updates the external URL of a template.
 * @param templateId The ID of the template to update.
 * @param type The type of the external URL.
 * @param url The URL to set.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
*/
export async function adminUpdateTemplateExternalUrl(templateId: string, type: string, url: string): Promise<HttpsCallableResult> {
  const update = httpsCallable(firebaseFunctions, FUNCTION_ADMIN_UPDATE_EXTERNAL_URL);
  return update({ templateId: templateId, externalUrlKey: type, externalUrlValue: url });
}

/**
 * Publishes a template on Pinterest.
 * @param templateId The ID of the template to publish.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function adminPublishTemplateOnPinterest(templateId: string): Promise<HttpsCallableResult> {
  const publish = httpsCallable(firebaseFunctions, FUNCTION_ADMIN_PUBLISH_TEMPLATE_PINTEREST);
  return publish({ templateId: templateId });
}

/**
 * Creates a Stripe order for a template product.
 * @param templateId The ID of the template to promote.
 * @param localeKey The locale key of the user.
 * @param customPrice The custom price of the template.
 * @returns {Promise<Stripe.Checkout.Session>} The result of the function call.
 */
export function createStripeMarketplaceTemplateCheckoutSession(templateId: string, localeKey: string, customPrice: number | null): Promise<HttpsCallableResult<MarketplaceCheckoutResult>> {
  const checkout = httpsCallable<unknown, MarketplaceCheckoutResult>(firebaseFunctions, FUNCTION_MARKETPLACE_TEMPLATE_CHECKOUT);
  return checkout({ templateId: templateId, localeKey: localeKey, customPrice: customPrice });
}

type MarketplaceCheckoutResult = {
  session: string;
  stripeAccountId: string;
}

/**
 * Creates a Stripe order for the promotion of the specified template.
 * @param templateId The ID of the template to promote.
 * @param purchaseKey The purchase key of the order to that paid for this promotion.
 * @returns {Promise<Stripe.Checkout.Session>} The result of the function call.
 */
export function createStripeTemplatePromotionOrder(templateId: string, purchaseKey: string): Promise<HttpsCallableResult<string>> {
  const order = httpsCallable<unknown, string>(firebaseFunctions, FUNCTION_ORDER_STRIPE_TEMPLATE_PROMOTION);
  return order({ templateId: templateId, purchaseKey: purchaseKey });
}

/**
 * Get the Stripe onboarding link for the current user.
 * @returns {Promise<Stripe.AccountLink>} The result of the function call.
 */
export function getStripeOnboardingLink() {
  const order = httpsCallable<unknown, Stripe.AccountLink>(firebaseFunctions, FUNCTION_MARKETPLACE_GET_STRIPE_ONBOARDING_LINK);
  return order();
}

/**
 * Creates a Stripe order for the promotion of the profile.
 * @param purchaseKey The purchase key of the order to that paid for this promotion.
 * @returns {Promise<Stripe.Checkout.Session>} The result of the function call.
 */
export function createStripeProfilePromotionOrder(purchaseKey: string): Promise<HttpsCallableResult<string>> {
  const order = httpsCallable<unknown, string>(firebaseFunctions, FUNCTION_ORDER_STRIPE_PROFILE_PROMOTION);
  return order({ purchaseKey: purchaseKey });
}

/**
 * Creates a PayPal order for the promotion of the specified template.
 * @param templateId The ID of the template to promote.
 * @param purchaseKey The purchase key of the order to that paid for this promotion.
 */
export function createPaypalTemplatePromotionOrder(templateId: string, purchaseKey: string): Promise<HttpsCallableResult> {
  const order = httpsCallable(firebaseFunctions, FUNCTION_ORDER_PAYPAL_TEMPLATE_PROMOTION);
  return order({ templateId: templateId, purchaseKey: purchaseKey });
}

/**
 * Creates a PayPal order for the promotion of the specified template.
 * @param referenceId The ID of the order to that paid for this promotion.
 */
export function checkPayPalOrderAndPromoteTemplate(referenceId: string): Promise<HttpsCallableResult> {
  const promote = httpsCallable(firebaseFunctions, FUNCTION_CHECK_PAYPAL_ORDER_AND_PROMOTE_TEMPLATE);
  return promote({ referenceId: referenceId });
}

/**
 * Creates a PayPal order for the promotion of the profile.
 * @param purchaseKey The purchase key of the order to that paid for this promotion.
 */
export function createPaypalProfilePromotionOrder(purchaseKey: string): Promise<HttpsCallableResult> {
  const order = httpsCallable(firebaseFunctions, FUNCTION_ORDER_PAYPAL_PROFILE_PROMOTION);
  return order({ purchaseKey: purchaseKey });
}

/**
 * Creates a PayPal order for the promotion of the profile.
 * @param referenceId The ID of the order to that paid for this promotion.
 */
export function checkPayPalOrderAndPromoteProfile(referenceId: string): Promise<HttpsCallableResult> {
  const promote = httpsCallable(firebaseFunctions, FUNCTION_CHECK_PAYPAL_ORDER_AND_PROMOTE_PROFILE);
  return promote({ referenceId: referenceId });
}

/**
 * Creates a promotion for the specified template and saves it to Firestore.
 * @param templateId The ID of the template to promote.
 * @param orderId The ID of the order to that paid for this promotion.
 */
export function promoteTemplate(templateId: string, orderId: string): Promise<HttpsCallableResult> {
  const promote = httpsCallable(firebaseFunctions, FUNCTION_PROMOTE_TEMPLATE);
  return promote({ templateId: templateId, orderId: orderId });
}

/**
 * Signs up a user to the Mailchimp list.
 * @param name The name of the user to sign up.
 * @param mail The email of the user to sign up.
 */
export function signupToMailchimpList(name: string, mail: string): Promise<HttpsCallableResult> {
  const signup = httpsCallable(firebaseFunctions, FUNCTION_ADD_TO_MAILCHIMP_LIST);
  return signup({ name: name, mail: mail });
}

/**
 * Deletes the user's account data.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function deleteAccountData(): Promise<HttpsCallableResult> {
  const deleteAccount = httpsCallable(firebaseFunctions, FUNCTION_DELETE_ACCOUNT_DATA);
  return deleteAccount();
}

/**
 * Updates the views of the specified profile.
 * @param templateId The ID of the template to update.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function updateProfileViews(profileId: string): Promise<HttpsCallableResult> {
  const updateViews = httpsCallable(firebaseFunctions, FUNCTION_UPDATE_PROFILE_VIEWS);
  return updateViews({ profileId: profileId });
}

/**
 * Updates the views of the specified template.
 * @param templateId The ID of the template to update.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function updateTemplateViews(templateId: string): Promise<HttpsCallableResult> {
  const updateViews = httpsCallable(firebaseFunctions, FUNCTION_UPDATE_TEMPLATE_VIEWS);
  return updateViews({ templateId: templateId });
}

/**
 * Updates the likes of the specified template.
 * @param templateId The ID of the template to update.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function updateTemplateLikes(templateId: string): Promise<HttpsCallableResult> {
  const updateLikes = httpsCallable(firebaseFunctions, FUNCTION_UPDATE_TEMPLATE_LIKES);
  return updateLikes({ templateId: templateId });
}

/**
 * Updates the likes of the specified template.
 * @param templateId The ID of the template to update.
 * @returns {Promise<HttpsCallableResult>} The result of the function call.
 */
export function updateUndoTemplateLikes(templateId: string): Promise<HttpsCallableResult> {
  const updateUndoLikes = httpsCallable(firebaseFunctions, FUNCTION_UPDATE_UNDO_TEMPLATE_LIKES);
  return updateUndoLikes({ templateId: templateId });
}