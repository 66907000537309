export const ImageTargetKeys = {
    LINKED_IN_SINGLE_IMAGE: "LINKED_IN_SINGLE_IMAGE",
    TWITTER_SINGLE_IMAGE: "TWITTER_SINGLE_IMAGE",
    FACEBOOK_SINGLE_IMAGE: "FACEBOOK_SINGLE_IMAGE",
    INSTAGRAM_SQUARE: "INSTAGRAM_SQUARE",
    INSTAGRAM_PORTRAIT: "INSTAGRAM_PORTRAIT",
    INSTAGRAM_STORY: "INSTAGRAM_STORY",
    REDDIT_SINGLE_IMAGE: "REDDIT_SINGLE_IMAGE",
    OPEN_GRAPH: "OPEN_GRAPH",
    TWITTER_CARD: "TWITTER_CARD",
    SCHEMA_ORG: "SCHEMA_ORG",
    FAVICON: "FAVICON",
    APPLE_TOUCH_ICON: "APPLE_TOUCH_ICON",
    LOGO: "LOGO",
    STACKED_IMAGE: "STACKED_IMAGE",
};