import { connect } from "react-redux";

import { RootState } from "../../../store";

const ComingSoon = () => {
  return (
    <>
      {/* This `Helmet` block sets the title, description, canonical link, and Open Graph metadata for the screen. */}
      <div>
        <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] lg:grid-cols-[max(50%,36rem),1fr]">
          <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
            <span className="sr-only">Elcovia</span>
            <img
              className="h-10 w-auto sm:h-12"
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="Elcovia"
            />
          </header>
          <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
            <div className="max-w-lg">
              <h1 className="text-4xl font-bold text-indigo-500 mb-4">
                Coming Soon
              </h1>
              <p className="text-lg text-gray-600">
                This page will be available in the future.
              </p>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(ComingSoon);
