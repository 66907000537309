import React from "react";
import { TemplateFeature } from "../../models/TemplateFeature";

interface TemplateFeatureProps {
  feature: TemplateFeature;
}

const TemplateFeatureItemComponent: React.FC<TemplateFeatureProps> = ({
  feature,
}) => {
  return (
    <div className="transition-all ease-in-out duration-300 group relative bg-white divide-y divide-solid divide-gray-200/50 shadow-sm rounded-lg border border-1 border-gray-200/50 hover:shadow-md hover:md:scale-[1.04]">
      <div className="w-full aspect-[4/3]">
        <img
          src={feature.image || ""}
          alt={feature.title}
          className="h-full w-full object-cover object-center lg:h-full lg:w-full rounded-t-lg "
        />
      </div>
      <div>
        <div className="p-4">
          <p className="text-lg font-semibold text-gray-900">{feature.title}</p>
          <p className="mt-1 text-md font-normal text-gray-800 leading-7 line-clamp-2">
            {feature.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TemplateFeatureItemComponent;
