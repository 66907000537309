import { LanguageIcon } from "@heroicons/react/20/solid";
import { BanknotesIcon, SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";

import { Template } from "../../models/Template";
import { getFormattedPrice } from "../../utils/utils-formatting";
import { classNames } from "../../utils/utils-react";

interface Props {
  template: Template;
}

const TemplatesStatusltem: React.FC<Props> = ({ template }) => {
  return (
    <div className="flex gap-x-3">
      {template.isPaid ? (
        <p
          className={classNames(
            "text-xs font-medium text-gray-600 flex gap-x-1 bg-gray-100 py-0.5 px-1 rounded-md",
            template.price ? "text-gray-900" : "w-5 h-5 justify-center"
          )}
        >
          {"$" + (template.price ? getFormattedPrice(template.price) : "")}
        </p>
      ) : (
        <p className="text-xs font-medium text-green-600 flex gap-x-1 bg-green-100 py-0.5 px-1 rounded-md">
          {"Free"}
        </p>
      )}

      {template.locales.length > 1 && (
        <LanguageIcon
          className="text-gray-600 h-4 w-4 flex-shrink-0"
          aria-hidden="true"
        />
      )}

      {template.isPromoted && (
        <SparklesIcon
          className="text-purple-600 h-4 w-4 flex-shrink-0"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default TemplatesStatusltem;
