import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { ConfirmationDialogComponent } from "../../../components/common/dialogs/ConfirmationDialog";
import TextFieldComponent from "../../../components/common/input/TextFieldComponent";
import { ExternalUrlType } from "../../../models/ExternalUrlType";
import { Profile } from "../../../models/Profile";
import { Template } from "../../../models/Template";
import { ImageTargetKeys } from "../../../models/metadata/ImageTarget";
import { getUserByUserId } from "../../../services/firestore-service";
import {
  adminUpdateTemplateMetadata,
  adminPublishTemplateOnPinterest,
  adminUpdateTemplateExternalUrl,
} from "../../../services/functions-service";
import { setPathId, Destination } from "../../../utils/constants-navigation";
import { localizedValue } from "../../../utils/supported-locales";
import { Logger } from "../../../utils/utils-logging";

const TemplateMetaDataItem: React.FC<{
  templateOrigin: Template;
  markTemplateAsDone: (template: Template) => void;
}> = ({ templateOrigin, markTemplateAsDone }) => {
  const logger = new Logger("TemplateMetaDataItem");
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  const [user, setUser] = React.useState<Profile | null>(null);
  const [template, setTemplate] = React.useState<Template>(templateOrigin);

  const metaData = template.metaData;
  const images = (metaData && localizedValue(metaData.images)) || null;
  const navigate = useNavigate();

  /**
   * Load the user by user id.
   */
  async function loadUser(userId: string) {
    const user = await getUserByUserId(userId);
    setUser(user);
  }

  /**
   * Load the user if the template has a user id.
   */
  useEffect(() => {
    if (template.userId && user == null) {
      loadUser(template.userId);
    }
  }, [template]);

  /**
   * Update the template meta data.
   */
  async function updatetemplateMetaData() {
    logger.log("updatetemplateMetaData");
    try {
      await adminUpdateTemplateMetadata(template.id, true);
      logger.log("updatetemplateMetaData", "success");
    } catch (error) {
      logger.logError("updatetemplateMetaData", error);
    }
  }

  /**
   * Publish the template on Pinterest.
   */
  async function publishTemplateOnPinterest() {
    logger.log("publishTemplateOnPinterest", template.id);
    try {
      await adminPublishTemplateOnPinterest(template.id);
      logger.log("publishTemplateOnPinterest", "success");
    } catch (error) {
      logger.logError("publishTemplateOnPinterest", error);
    }
  }

  /** Update the external URL for the given type. */
  async function updateExternalUrl(type: string, url: string) {
    logger.log("updateExternalUrl", type, url);
    try {
      await adminUpdateTemplateExternalUrl(template.id, type, url);
      logger.log("updateExternalUrl", "success");
    } catch (error) {
      logger.logError("updateExternalUrl", error);
    }
  }

  /**
   * Extract the Instagram handle from the given URLs.
   * @param urls The URLs to extract the Instagram handle from.
   * @returns The Instagram handle or null if none was found.
   */
  function extractInstagramHandle(urls: string[]): string | null {
    const instagramRegex =
      /https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_.]+)/;

    for (const url of urls) {
      const match = url.match(instagramRegex);
      if (match && match[2]) {
        return "@" + match[2]; // match[2] contains the Instagram handle
      }
    }

    return null;
  }

  function downloadImage(imageUrl: string, fileName: string) {
    var link = document.createElement("a");
    logger.log("fileName", fileName);
    link.href = imageUrl;
    link.download = fileName;
    link.click();
  }

  const handleUrlChange = (type: string, newValue: string) => {
    // Clone the existing template to not directly mutate state
    const updatedTemplate = _.cloneDeep(template);

    // Update the URL for the specific type
    if (!updatedTemplate.externalUrls) {
      updatedTemplate.externalUrls = {};
    }
    updatedTemplate.externalUrls[type] = newValue;
    setTemplate(updatedTemplate); // Assuming you have a method to update your component's state
  };

  if (metaData == null || metaData === undefined)
    return (
      <a>
        <ButtonComponent
          text={"Update MetaData"}
          style={"standard"}
          onClick={updatetemplateMetaData}
        />
      </a>
    );

  return (
    <>
      <ConfirmationDialogComponent
        show={showConfirmationDialog}
        title={`Mark "${localizedValue(metaData.title)}" as shared?`}
        description={
          "This will mark the template as shared. This action cannot be undone."
        }
        onPositive={() => {
          markTemplateAsDone(template);
          setShowConfirmationDialog(false);
        }}
        onNegative={() => {
          setShowConfirmationDialog(false);
        }}
      />
      <div className="group relative bg-white divide-y divide-solid rounded-lg drop-shadow hover:drop-shadow-lg">
        <img
          onClick={() => {
            const fileName =
              (localizedValue(metaData.title) || "") + "-thumbnail";
            const imageUrl = localizedValue(template.thumbnailImage) || "";
            downloadImage(imageUrl, fileName);
          }}
          src={images?.[ImageTargetKeys.INSTAGRAM_SQUARE]?.[0] || ""}
          className="rounded-t-lg w-full aspect-[1/1]"
        />
        <div>
          <div className="flex p-3 gap-3">
            <ButtonComponent
              text={"Thumbnail"}
              style={"standard"}
              onClick={() => {
                const fileName =
                  (localizedValue(metaData.title) || "") + "-thumbnail";
                const imageUrl = localizedValue(template.thumbnailImage) || "";
                downloadImage(imageUrl, fileName);
              }}
            />
            <ButtonComponent
              text={"Cover Image"}
              style={"standard"}
              onClick={() => {
                const fileName =
                  (localizedValue(metaData.title) || "") + "-cover";
                const imageUrl =
                  localizedValue(template.coverImages)?.[0] || "";
                downloadImage(imageUrl, fileName);
              }}
            />
          </div>
          <div className="pt-4 pb-4 ml-4 mr-4">
            <p className="text-lg font-semibold text-gray-900">
              {localizedValue(metaData.title)} | 
              {(user && extractInstagramHandle(user.links)) ||
                user?.name ||
                "Unknown"}
            </p>
            <p className="mt-2 text-sm text-gray-600 leading-6">
              {localizedValue(metaData.desc)}
            </p>
            <div className="flex flex-wrap gap-3 mt-4">
              <ButtonComponent
                text={"Copy for IG"}
                style={"standard"}
                onClick={() => {
                  const value =
                    "Title: " +
                    localizedValue(metaData.title) +
                    "\nDescription: " +
                    localizedValue(metaData.desc) +
                    "\n\nCreator: " +
                    ((user && extractInstagramHandle(user.links)) ||
                      user?.name ||
                      "Unknown") +
                    " - @notion_hq";
                  navigator.clipboard.writeText(value);
                }}
              />

              <ButtonComponent
                text={"Copy URL"}
                style={"standard"}
                onClick={() => {
                  const templateUrl =
                    "https://elcovia.com/" +
                    setPathId(Destination.TEMPLATE_ID_SHORT, template.id);
                  navigator.clipboard.writeText(templateUrl);
                }}
              />
              <ButtonComponent
                text={"Update"}
                style={"standard"}
                onClick={updatetemplateMetaData}
              />

              {/* NOT WORKING CURRENTLY: AUTO PUBLISH PINTEREST */}
              {/* <ButtonComponent
                text={"Publish on Pinterest"}
                style={"standard"}
                onClick={publishTemplateOnPinterest}
              /> */}

              <ButtonComponent
                text={"View"}
                style={"standard"}
                onClick={() => {
                  const url = setPathId(Destination.TEMPLATE_ID, template.id);
                  window.open(url, "_blank");
                }}
              />

              <ButtonComponent
                onClick={() => {
                  setShowConfirmationDialog(true);
                }}
                style={"structural"}
                text={"Hide"}
              />
            </div>
          </div>
          <div className="p-4 space-y-3">
            {Object.values(ExternalUrlType).map((type) => (
              <div className="flex gap-x-3" key={type}>
                <TextFieldComponent
                  type="text"
                  placeholder={type.toLowerCase()}
                  value={template.externalUrls?.[type] || ""}
                  onChange={(e) => {
                    handleUrlChange(type, e.target.value);
                  }}
                />
                <ButtonComponent
                  text="Update"
                  style="standard"
                  onClick={() => {
                    updateExternalUrl(
                      type,
                      template.externalUrls?.[type] || ""
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateMetaDataItem;
