type SwiperIndicatorProps = {
  current: number;
  max: number;
};

function SwiperIndicatorComponent({ current, max }: SwiperIndicatorProps) {
  return (
    <div className="flex items-center">
      {Array.from({ length: max }, (_, index) => (
        <span
          key={index}
          className={`mx-1 h-2 w-2 rounded-full ${
            index === current ? "bg-gray-500" : "bg-gray-200"
          }`}
        />
      ))}
    </div>
  );
}

export default SwiperIndicatorComponent;
