import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";

interface GalleryViewerProps {
  data: GalleryViewData;
  onClose: () => void;
}

export type GalleryViewData = {
  visible: boolean;
  images: string[];
};

const GalleryViewer: React.FC<GalleryViewerProps> = ({ data, onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (thumbnailsRef.current) {
      const scrollContainer = thumbnailsRef.current;
      const activeThumbnail: HTMLImageElement | null =
        scrollContainer.querySelector(`img:nth-child(${selectedIndex + 1})`);

      if (activeThumbnail) {
        const scrollAmount =
          activeThumbnail.offsetLeft +
          activeThumbnail.clientWidth / 2 -
          scrollContainer.clientWidth / 2;
        scrollContainer.scroll({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  }, [selectedIndex]);

  // Function to handle keyboard navigation
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      navigate(-1);
    } else if (event.key === "ArrowRight") {
      navigate(1);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [data.images.length]);

  const navigate = (direction: number) => {
    setSelectedIndex((prevIndex) => {
      const imageCount = data.images.length;
      const nextIndex = (prevIndex + direction + imageCount) % imageCount;
      return nextIndex;
    });
  };

  return (
    <Transition.Root show={data.visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={() => onClose()}
          />
        </Transition.Child>
        <div className="lg:m-24 lg:border lg:border-1 lg:border-gray-200 lg:rounded-xl lg:shadow-xl fixed inset-0 z-50 flex flex-col bg-white">
          <div
            className="flex-grow flex items-center justify-center p-16"
            onClick={() => onClose()}
          >
            <img
              onClick={() => onClose()}
              src={data.images[selectedIndex]}
              className="max-w-auto rounded-lg shadow:sm shadow-md border border-1 border-gray-200"
            />
          </div>
          <div className="mb-16">
            {data.images.length > 1 && (
              <div className="overflow-x-auto" ref={thumbnailsRef}>
                <div className="flex justify-center gap-x-4 p-4">
                  {data.images.map((image, index) => (
                    <img
                      key={image}
                      src={image}
                      alt={`Thumbnail ${index}`}
                      onClick={() => setSelectedIndex(index)}
                      className={`cursor-pointer ${
                        selectedIndex === index
                          ? "shadow-lg scale-[1]"
                          : "shadow-md scale-[0.9]"
                      } w-36 shrink-0 object-cover rounded-md border border-1 border-gray-200 transition-all ease-in-out duration-300`}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GalleryViewer;
