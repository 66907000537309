import React from "react";
import { connect, useSelector } from "react-redux";
import { Logger } from "../../../utils/utils-logging";
import ButtonComponent from "../../../components/common/buttons/ButtonComponent";
import { ExternalUrlType } from "../../../models/ExternalUrlType";
import { Profile } from "../../../models/Profile";
import { Template } from "../../../models/Template";
import { ImageTargetKeys } from "../../../models/metadata/ImageTarget";
import { getTemplates, getUsers } from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { FIREBASE_ADMIN_UID } from "../../../utils/constants";
import { setPathId, Destination } from "../../../utils/constants-navigation";
import { localizedValue } from "../../../utils/supported-locales";
import { getNotionTemplateTitleUrl } from "../../../utils/urls/utils-urls-template";
import { PinData, convertPinsToCsv, downloadCsv } from "./PinterestExport";

/**
 * Export data to CSV.
 * @param filename The filename.
 * @param header The header.
 * @param data The data.
 */
function exportJSON(filename: string, data: string) {
  const blob = new Blob([data], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const DataExportPage = () => {
  const logger = new Logger("DataExportPage");

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [templates, setTemplates] = React.useState<Template[] | null>(null);
  const [creators, setCreators] = React.useState<Profile[] | null>(null);
  const [users, setUsers] = React.useState<Profile[] | null>(null);

  const baseUrl = "https://www.elcovia.com";

  /**
   * Export templates to JSON.
   */
  function exportTemplates() {
    if (templates == null) return;
    const jsonExportData = templates.map((template) => {
      const templateUrl = setPathId(
        Destination.TEMPLATE_ID_SHORT,
        template.id || ""
      );
      const creatorUrl = setPathId(
        Destination.USER_ID_SHORT,
        template.creatorId || ""
      );
      return {
        title: template.title,
        description: template.desc,
        coverImages: template.coverImages,
        price: template.price,
        template_url: baseUrl + templateUrl,
        creator_url: baseUrl + creatorUrl,
        creator_id: template.userId,
      };
    });

    const jsonData = JSON.stringify(jsonExportData, null, 2);
    exportJSON("templates.json", jsonData);
  }

  function exportTemplatesCoverImagesForPinterest() {
    if (templates == null) return;
    const filteredTemplates = templates.filter((template) => {
      const openGraphTitle = localizedValue(template.metaData?.title);
      const openGraphDescription = localizedValue(template.metaData?.desc)?.[0];

      if (!openGraphTitle || !openGraphDescription) {
        return false;
      }

      if (!template.externalUrls) {
        return true;
      }

      if (template.externalUrls[ExternalUrlType.PINTEREST_LARGE]) {
        return false;
      }
      return true;
    });

    logger.log("Filtered Templates", filteredTemplates);
    const pinData = filteredTemplates
      .map((template) => {
        const creatorProfile = users?.find(
          (user) => user.id == template.userId
        );
        const openGraphDescription = localizedValue(
          template.metaData?.desc
        )?.[0];
        const keywords = localizedValue(template.metaData?.keywords)?.[0];
        const title = localizedValue(template.title);
        const titleUrl = title
          ? getNotionTemplateTitleUrl(title, template.id)
          : null;
        const url =
          baseUrl + setPathId(Destination.TEMPLATE_ID_SHORT, template.id);
        const imageUrl = localizedValue(template.metaData?.images)?.[
          ImageTargetKeys.STACKED_IMAGE
        ]?.[0];
        const boardTemplates = "Notion Templates (Rich)";

        if (!title || !openGraphDescription || !url || !imageUrl) return;

        const pinData: PinData = {
          title:
            title +
            (creatorProfile && creatorProfile.name !== undefined
              ? ` - ${creatorProfile!.name}`
              : ""),
          description: openGraphDescription,
          pinterestBoard: boardTemplates,
          mediaUrl: imageUrl,
          link: titleUrl || url,
          keywords: keywords
            ? cleanKeywords(keywords) + ",notion, elcovia, notion template"
            : "notion, elcovia, notion template",
          // publishDate: new Date().toISOString(),
        };
        logger.log("PinData", pinData);
        return pinData;
      })
      .filter((pin): pin is PinData => pin !== undefined);

    if (!pinData || pinData.length === 0) return;
    const csv = convertPinsToCsv(pinData);
    downloadCsv(csv, "templates_rich_pinterest_pins.csv");
  }

  function exportTemplatesForPinterest() {
    if (templates == null) return;
    const filteredTemplates = templates.filter((template) => {
      const openGraphTitle = localizedValue(template.metaData?.title);
      const openGraphDescription = localizedValue(template.metaData?.desc)?.[0];

      if (!openGraphTitle || !openGraphDescription) {
        return false;
      }

      if (!template.externalUrls) {
        return true;
      }

      if (template.externalUrls[ExternalUrlType.PINTEREST]) {
        return false;
      }
      return true;
    });

    logger.log("Filtered Templates", filteredTemplates);
    const pinData = filteredTemplates
      .map((template) => {
        const creatorProfile = users?.find(
          (user) => user.id == template.userId
        );

        const openGraphDescription = localizedValue(
          template.metaData?.desc
        )?.[0];
        const keywords = localizedValue(template.metaData?.keywords)?.[0];
        const title = localizedValue(template.title);
        const titleUrl = title
          ? getNotionTemplateTitleUrl(title, template.id)
          : null;
        const url =
          baseUrl + setPathId(Destination.TEMPLATE_ID_SHORT, template.id);
        const imageUrl = localizedValue(template.thumbnailImage);
        const boardTemplates = "Notion Templates"; // "897342363193412873";

        if (!title || !openGraphDescription || !url || !imageUrl) return;

        const pinData: PinData = {
          title:
            title +
            (creatorProfile && creatorProfile.name !== undefined
              ? ` - ${creatorProfile!.name}`
              : ""),
          description: openGraphDescription,
          pinterestBoard: boardTemplates,
          mediaUrl: imageUrl,
          link: titleUrl || url,
          keywords: keywords
            ? cleanKeywords(keywords) + ",notion, elcovia, notion template"
            : "notion, elcovia, notion template",
          // publishDate: new Date().toISOString(),
        };
        logger.log("PinData", pinData);
        return pinData;
      })
      .filter((pin): pin is PinData => pin !== undefined);

    if (!pinData || pinData.length === 0) return;
    const csv = convertPinsToCsv(pinData);
    downloadCsv(csv, "templates_pinterest_pins.csv");
  }

  function cleanKeywords(keywords: string): string {
    // Normalize spaces and remove new lines
    let cleaned = keywords.replace(/\s+/g, " ").replace(/[\r\n]+/g, "");

    // Remove unwanted characters (e.g., hashtags, numeration)
    cleaned = cleaned.replace(/[#0-9]*/g, "");

    // Split by comma or space, trim, remove empty, and deduplicate
    const keywordsArray = cleaned
      .split(/[ ,]+/)
      .map((s) => s.trim())
      .filter((value, index, self) => value && self.indexOf(value) === index);

    // Rejoin into a comma-separated string
    let result = keywordsArray.join(", ");

    // Step 6: Ensure no leading or trailing commas, or ".," pattern at the beginning
    result = result.replace(/^[\.,\s]+/, "").replace(/[\.,\s]+$/, "");

    return result;
  }

  /**
   * Export creators to JSON.
   */
  function exportCreators() {
    if (creators == null) return;
    const jsonExportData = creators.map((creator) => {
      const urls = [];
      if (
        creator.profileId != null &&
        creator.profileId != undefined &&
        creator.profileId != ""
      ) {
        const creatorUrlId = setPathId(
          Destination.USER_ID_SHORT,
          creator.profileId || ""
        );
        urls.push(baseUrl + creatorUrlId);
      }
      const creatorUrl = setPathId(Destination.USER_ID_SHORT, creator.id);
      urls.push(baseUrl + creatorUrl);

      return {
        creator_urls: urls,
        name: creator.name,
        profile_id: creator.profileId,
        bio: creator.bio,
        image: creator.image,
        verified: creator.verified,
        notion_certified: creator.notionCertified,
      };
    });
    const jsonData = JSON.stringify(jsonExportData, null, 2);
    exportJSON("creators.json", jsonData);
  }

  const loadTemplatesWithMetaData = async () => {
    try {
      const templates = await getTemplates();
      const sortedTemplates = templates?.sort((a, b) => {
        if (a.created == null || b.created == null) return 0;
        return b.created.toMillis() - a.created.toMillis();
      });

      logger.log("Templates", JSON.stringify(templates));
      setTemplates(sortedTemplates || null);
    } catch (error) {
      logger.logError("loadTemplatesWithMetaData", error);
    }
  };

  const loadCreatorsWithMetaData = async () => {
    try {
      const users = await getUsers();
      const creators = users.filter((user) => user.creator == true);
      logger.log("Creators", JSON.stringify(creators));
      setCreators(creators || null);
    } catch (error) {
      logger.logError("loadTemplatesWithMetaData", error);
    }
  };

  const loadUsers = async () => {
    try {
      const users = await getUsers();
      const creators = users.filter((user) => user.creator == true);
      logger.log("Users", JSON.stringify(creators));
      setUsers(creators || null);
    } catch (error) {
      logger.logError("loadUsers", error);
    }
  };

  React.useEffect(() => {
    if (isSignedIn && userProfile?.id == FIREBASE_ADMIN_UID) {
      if (templates == null) loadTemplatesWithMetaData();
      if (creators == null) loadCreatorsWithMetaData();
      if (users == null) loadUsers();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="ml-auto">
        <ButtonComponent
          baseClassNames="mt-4"
          style="structural"
          text={"Export Templates Pinterest.csv"}
          onClick={() => exportTemplatesForPinterest()}
        />

        <ButtonComponent
          baseClassNames="mt-4"
          style="structural"
          text={"Export Templates (CoverImages) Pinterest.csv"}
          onClick={() => exportTemplatesCoverImagesForPinterest()}
        />

        <ButtonComponent
          baseClassNames="mt-4"
          style="structural"
          text={"Export templates.json"}
          onClick={() => exportTemplates()}
        />

        <ButtonComponent
          baseClassNames="mt-4"
          style="structural"
          text={"Export creators.json"}
          onClick={() => exportCreators()}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(DataExportPage);
