import React from "react";

const LoadingScreenComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="mt-8 w-10 h-10 border-t-4 border-b-0 border-black border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default LoadingScreenComponent;
