import React from "react";
import { localizedValueWithKey } from "../../../../utils/supported-locales";
import { getFormattedPrice } from "../../../../utils/utils-formatting";
import { Template } from "../../../../models/Template";

interface TemplateHeaderSectionProps {
  template: Template;
  locale: string;
  subtitleChildren?: React.ReactNode;
  buttonChildren?: React.ReactNode;
}

const TemplateHeaderSection: React.FC<TemplateHeaderSectionProps> = ({
  template,
  locale,
  subtitleChildren,
  buttonChildren,
}) => {
  return (
    <div className="flex">
      <div>
        <div className="flex items-center gap-x-6">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {localizedValueWithKey(template.title, locale)}
          </h1>
          {template.price && (
            <p className="mt-1 px-2.5 py-1.5 text-md text-green-500 ring-1 ring-green-500 rounded-md">
              ${getFormattedPrice(template.price)}
              {template.priceSuggested && "+"}
            </p>
          )}
        </div>
        {subtitleChildren}
      </div>

      {buttonChildren}
    </div>
  );
};

export default TemplateHeaderSection;
