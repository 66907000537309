import React from "react";
import {
  SocialNetwork,
  getDomainFromUrl,
  getSocialNetowrkColor,
  getSocialNetworkName,
  getSocialNetworkSvgIcon,
  getSocialNetworkType,
} from "../../../utils/utils-formatting";
import { classNames } from "../../../utils/utils-react";
import { Logger } from "../../../utils/utils-logging";

const logger = new Logger("LargeSocialButtonComponent");

interface Props {
  onClick?: () => void;
  href: string;
  target?: string;
  rel?: string;
  parentClassNames?: string;
}
type PlatformDetail = {
  baseClassName: string;
  icon: string;
};

interface SpecificSocialNetworkDetails {
  [SocialNetwork.Gumroad]: PlatformDetail;
  [SocialNetwork.Payhip]: PlatformDetail;
  [SocialNetwork.Etsy]: PlatformDetail;
  [SocialNetwork.Lemonsqueezy]: PlatformDetail;
}

const details: SpecificSocialNetworkDetails = {
  [SocialNetwork.Gumroad]: {
    baseClassName: "bg-[#FF90E8] hover:bg-[#F277D8]",
    icon: process.env.PUBLIC_URL + "/icons/icon_platform_gumroad.svg",
  },
  [SocialNetwork.Payhip]: {
    baseClassName: "bg-[#B2C3F1] hover:bg-[#A4B9F2]",
    icon: process.env.PUBLIC_URL + "/icons/icon_platform_payhip.svg",
  },
  [SocialNetwork.Etsy]: {
    baseClassName: "bg-[#FEFCDB] hover:bg-[#F1EEC6]",
    icon: process.env.PUBLIC_URL + "/icons/icon_platform_etsy.svg",
  },
  [SocialNetwork.Lemonsqueezy]: {
    baseClassName: "bg-[#FF90E8] hover:bg-[#b23386]",
    icon: process.env.PUBLIC_URL + "/icons/icon_platform_etsy.svg",
  },
};

function getDetail(type: SocialNetwork): PlatformDetail | undefined {
  if (type in details) {
    return details[type as keyof typeof details];
  }
  return undefined;
}

const PlatformRedirectButtonComponent: React.FC<Props> = ({
  onClick,
  href,
  target,
  rel,
  parentClassNames,
}) => {
  const domain = getDomainFromUrl(href);
  const type = getSocialNetworkType(domain);
  const name = getSocialNetworkName(domain);
  const icon = getSocialNetworkSvgIcon(domain);
  const color = getSocialNetowrkColor(domain)?.replace("", "");

  logger.log("init", {
    domain,
    name,
    icon,
    color,
  });

  const detailsa = getDetail(type as SocialNetwork);
  if (!detailsa) {
    logger.log("No details found for social network", { type });
    return null;
  }

  const baseClassNames = classNames(
    "flex border border-gray-300 items-center justify-center",
    "transition-all ease-in-out duration-300 rounded shadow-sm font-medium outline-none",
    "aspect-[5/2] w-full",
    detailsa.baseClassName
  );

  if (onClick)
    return (
      <div className={parentClassNames}>
        <a onClick={onClick} target={target || ""} rel={rel || ""}>
          <button type="button" className={baseClassNames}>
            <img className="w-full h-full" src={detailsa.icon} alt={name} />
          </button>
        </a>
      </div>
    );

  return (
    <div className={parentClassNames}>
      <a href={href || "#"} target={target || ""} rel={rel || ""}>
        <button type="button" className={baseClassNames}>
          <img className="w-full h-full" src={icon} alt={name} />
        </button>
      </a>
    </div>
  );
};

export default PlatformRedirectButtonComponent;
