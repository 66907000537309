import React from "react";

import { FAQ } from "./FAQ";
import { FAQItem } from "./FAQItem";

interface FAQGroupItemProps {
  faqsChunked: FAQ[][];
}

export const FAQGroupItem: React.FC<FAQGroupItemProps> = ({ faqsChunked }) => (
  <div className="mt-12 grid grid-cols-2 gap-x-12 mb-24">
    {faqsChunked.map((faqs, i) => (
      <dl className="space-y-6 divide-y divide-gray-900/10">
        {faqs.map((faq) => (
          <FAQItem {...faq} />
        ))}
      </dl>
    ))}
  </div>
);
