import { DateTimeFormatter, LocalDate, ZoneOffset } from '@js-joda/core';
import { WeekFields } from '@js-joda/locale_en-us';

export class PeriodId {
    private readonly key: string;

    constructor(key: string) {
        this.key = key;
    }

    // static day(): PeriodId {
    //     return new PeriodId(`day_${PeriodId.getDayTimeId()}`);
    // }

    static week(): PeriodId {
        return new PeriodId(`week_${PeriodId.getWeekTimeId()}`);
    }

    static month(): PeriodId {
        return new PeriodId(`month_${PeriodId.getMonthTimeId()}`);
    }

    static lastMonth(): PeriodId {
        return new PeriodId(`month_${PeriodId.getLastMonthTimeId()}`);
    }

    static year(): PeriodId {
        return new PeriodId(`year_${PeriodId.getYearTimeId()}`);
    }

    static total(): PeriodId {
        return new PeriodId('total');
    }

    getKey(): string {
        return this.key;
    }

    private static getDayTimeId(): string {
        const now = LocalDate.now(ZoneOffset.UTC);
        return now.format(DateTimeFormatter.ofPattern('uuuuMMdd'));
    }

    private static getWeekTimeId(): string {
        const now = LocalDate.now(ZoneOffset.UTC);
        const weekNumber = now.get(WeekFields.ISO.weekOfWeekBasedYear());
        return `${weekNumber}-${now.year()}`;
    }

    private static getMonthTimeId(): string {
        const now = LocalDate.now(ZoneOffset.UTC);
        return now.format(DateTimeFormatter.ofPattern('uuuuMM'));
    }

    private static getLastMonthTimeId(): string {
        const now = LocalDate.now(ZoneOffset.UTC);
        const lastMonth = now.minusMonths(1);
        return lastMonth.format(DateTimeFormatter.ofPattern('uuuuMM'));
    }

    private static getYearTimeId(): string {
        const now = LocalDate.now(ZoneOffset.UTC);
        return now.format(DateTimeFormatter.ofPattern('uuuu'));
    }
}

export default PeriodId;

