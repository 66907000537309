import React, { useState } from "react";

interface DragAndDropComponentProps {
  items: string[];
  className?: string;
  childItem: (index: number, coverImage: string) => React.ReactNode;
  addOnView?: React.ReactNode;
  onOrderChange: (newOrder: string[]) => void;
}
const DragAndDropGridComponent: React.FC<DragAndDropComponentProps> = ({
  items,
  className,
  childItem,
  addOnView,
  onOrderChange,
}) => {
  const [coverImages, setCoverImages] = useState<string[]>([]);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ): void => {
    event.preventDefault();
    setTargetIndex(targetIndex);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const sourceIndex = Number(event.dataTransfer.getData("text/plain"));

    if (targetIndex !== null && sourceIndex !== targetIndex) {
      const updatedCoverImages = [...coverImages];
      const [removedItem] = updatedCoverImages.splice(sourceIndex, 1);
      updatedCoverImages.splice(targetIndex, 0, removedItem);

      setCoverImages(updatedCoverImages);
      onOrderChange(updatedCoverImages);
    }
    setTargetIndex(null);
  };

  React.useEffect(() => {
    setCoverImages(items);
  }, [items]);

  return (
    <div>
      {(items.length > 0 || addOnView) && (
        <div className={className}>
          {coverImages.map((coverImage, index) => (
            <div
              draggable
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={(event) => handleDragOver(event, index)}
              onDrop={handleDrop}
            >
              {childItem(index, coverImage)}
            </div>
          ))}
          {addOnView}
        </div>
      )}
    </div>
  );
};

export default DragAndDropGridComponent;
