import { DocumentData, Timestamp } from "firebase-admin/firestore";
import * as admin from "firebase-admin";

/**
 * Enumeration for different order types used in DiscoverList.
 */
export enum DiscoverCounterType {
  LIKES = "likes",
  VIEWS = "views",
}

/**
 * Enumeration for different data types used in DiscoverList.
 */
export enum DiscoverDataType {
  TEMPLATES_CATEGORY = "templatesCategory",
  TEMPLATES = "templates",
  CREATORS = "creators",
}

/**
 * Class representing a Discover entry.
 */
export class Discover {
  /**
   * Timestamp when the Discover entry was created.
   */
  public timestamp: Timestamp;

  /**
   * Map containing DiscoverList objects.
   */
  public data = new Map<string, DiscoverList>();

  /**
   * Constructor to create a Discover instance.
   * @param {Timestamp} timestamp - Timestamp when the Discover entry was created.
   */
  constructor(timestamp: Timestamp) {
    this.timestamp = timestamp;
  }
}

/**
 * Firestore converter for Discover objects.
 */
export const discoverConverter = {
  toFirestore(discover: Discover): DocumentData {
    const data = {
      timestamp: discover.timestamp,
      data: Object.fromEntries(Array.from(discover.data.entries()).map(([key, value]) => [key, discoverListConverter.toFirestore(value)])),
    };

    return data;
  },

  fromFirestore(snapshot: DocumentData): Discover {
    const data = snapshot.data();
    const timestamp = data.timestamp as admin.firestore.Timestamp;
    const discover = new Discover(timestamp);

    for (const key in data.data) {
      const value = data.data[key];
      const discoverList = discoverListConverter.fromFirestore(value); // Make sure 'value' is a valid Firestore snapshot
      discover.data.set(key, discoverList);
    }
    return discover;
  },
};

/**
 * Class representing a Discover List.
 */
export class DiscoverList {
  /**
   * Type of ordering for the Discover List (Likes or Views).
   */
  public counterType: DiscoverCounterType;

  /**
   * Type of data contained in the Discover List (Templates or Creators).
   */
  public dataType: DiscoverDataType;

  /**
   * Time period associated with the Discover List.
   */
  public timePeriod: string;

  /**
   * Public key of the Discover List.
   */
  public categoryKey: string | null = null;

  /**
   * Array of data entries in the Discover List.
   */
  public data: string[];

  /**
   * Returns the key of the Discover List.
   * @return {string} The key of the Discover List.
   */
  getKey(): string {
    const parts = [this.dataType, this.categoryKey, this.counterType, this.timePeriod].filter((part) => part !== null && part !== undefined);
    return parts.join("-");
  }

  /**
   * Constructor to create a DiscoverList instance.
   * @param {DiscoverCounterType} orderType - Type of ordering for the Discover List (Likes or Views).
   * @param {DiscoverDataType} dataType - Type of data contained in the Discover List (Templates or Creators).
   * @param {string} timePeriod - Time period associated with the Discover List.
   * @param {string} categoryKey - Public key of the Discover List.
   * @param {string[]} data - Array of data entries in the Discover List.
   */
  constructor(orderType: DiscoverCounterType, dataType: DiscoverDataType, timePeriod: string, categoryKey: string | null, data: string[]) {
    this.counterType = orderType;
    this.dataType = dataType;
    this.data = data;
    this.categoryKey = categoryKey;
    this.timePeriod = timePeriod;
  }
}

/**
 * Firestore converter for DiscoverList objects.
 */
export const discoverListConverter = {
  /**
   * Convert a DiscoverList object to Firestore data.
   * @param {DiscoverList} discoverList - The DiscoverList object to convert.
   * @return {FirebaseFirestore.DocumentData} Firestore document data.
   */
  toFirestore(discoverList: DiscoverList): FirebaseFirestore.DocumentData {
    return {
      orderType: discoverList.counterType,
      dataType: discoverList.dataType,
      timePeriod: discoverList.timePeriod,
      categoryKey: discoverList.categoryKey,
      data: discoverList.data,
    };
  },

  /**
   * Convert Firestore snapshot data to a DiscoverList object.
   * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot - Firestore snapshot.
   * @return {DiscoverList} DiscoverList object.
   */
  fromFirestore(snapshot: DocumentData): DiscoverList {
    const data = snapshot;
    if (!data) {
      throw new Error("No data found for DiscoverList");
    }
    return new DiscoverList(data.orderType, data.dataType, data.timePeriod, data.categoryKey, data.data);
  },
};
