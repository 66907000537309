import React, {
  useRef,
  forwardRef,
  ForwardedRef,
  ChangeEvent,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import { classNames } from "../../../utils/utils-react";
import { initial } from "lodash";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import { Logger } from "../../../utils/utils-logging";

const logger = new Logger("PriceTextFieldComponent");

type InputProps = {
  name?: string;
  error?: boolean;
  onChange?: (price: number | null) => void;
  initialValue?: string;
  value?: string;
  placeholder?: string;
  // Optional attributes
  autoComplete?: string;
  type?: string;
  defaultClassName?: string;
  additionalClassName?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
};

const PriceTextFieldComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    name,
    error,
    onChange,
    initialValue,
    value,
    placeholder,
    autoComplete,
    additionalClassName,
    id,
    required,
    disabled,
  },
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [initValue, setInitValue] = useState<string | undefined>(initialValue);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const assignRef = (ref: ForwardedRef<HTMLInputElement> | undefined) => {
    if (ref) {
      if (typeof ref === "function") {
        // If it's a function, call it with the inputRef as an argument
        ref(inputRef.current);
      } else {
        // Otherwise, assign the ref directly
        ref.current = inputRef.current;
      }
    }
  };

  assignRef(ref);

  const getInputValue = (value: String) => {
    const cleanValue = value.replace(/[^\d,.-]/g, "");
    const decimalParts = cleanValue.split(/[,.]/);
    let integerPart = "";
    try {
      integerPart = parseInt(decimalParts[0]).toString();
      if (integerPart === "NaN") {
        integerPart = "";
      }
    } catch (error) {
      integerPart = "";
    }
    let decimalPart = decimalParts[1] || "";
    console.log("integerPart", integerPart, "decimalPart", decimalPart);
    return { integerPart, decimalPart };
  };

  const onAddDecimals = () => {
    if (!initValue || !onChange) return;

    let { integerPart, decimalPart } = getInputValue(initValue);

    if (decimalPart.length > 2) {
      decimalPart = decimalPart.slice(0, 2);
    } else {
      while (decimalPart.length < 2) {
        decimalPart += "0";
      }
    }

    const fixedPriceReadable = `${integerPart}.${decimalPart}`;
    const fixedPrice = parseFloat(fixedPriceReadable);
    setInitValue(fixedPriceReadable);
    inputRef.current?.value && (inputRef.current.value = fixedPriceReadable);
    onChange(fixedPrice);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    logger.log("handleInputChange", event.target.value);

    const inputValue = event.target.value;
    logger.log("inputValue", inputValue);

    const regex = /[^0-9]/g;
    const filteredValue = inputValue.replace(regex, "");

    if (filteredValue) {
      let { integerPart, decimalPart } = getInputValue(inputValue);

      if (decimalPart && !integerPart) {
        integerPart = "0";
      }

      if (!decimalPart && !integerPart) {
        integerPart = "";
        decimalPart = "";
        setInitValue("");
        onChange(null);
        return;
      }

      if (decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      let fixedPriceReadable =
        integerPart && integerPart.length > 0 ? integerPart : "";
      if (decimalPart && decimalPart.length > 0) {
        fixedPriceReadable += `.${decimalPart}`;
      } else {
        if (inputValue.includes(".") || inputValue.includes(",")) {
          fixedPriceReadable += ".";
        }
      }

      const fixedPrice = parseFloat(fixedPriceReadable);
      setInitValue(fixedPriceReadable);
      inputRef.current?.value && (inputRef.current.value = fixedPriceReadable);
      onChange(fixedPrice);
    } else {
      setInitValue("");
      inputRef.current?.value && (inputRef.current.value = "");
      onChange(null);
    }
  };

  const defaultClassName = classNames(
    error
      ? "focus:ring-2 focus:ring-inset ring-red-300 focus:ring-red-600 text-red-500"
      : "focus:ring-2 focus:ring-inset focus:ring-indigo-600",
    "w-full block text-right rounded-md border-0 py-2 pl-12 pr-4 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6"
  );

  const baseClassName = classNames(
    defaultClassName,
    additionalClassName ? additionalClassName : ""
  );

  return (
    <div style={{ position: "relative" }}>
      <div className="flex items-center">
        <span className="absolute ml-3 text-gray-700">$</span>
        <input
          type="text"
          name={name}
          autoComplete={autoComplete}
          onChange={handleInputChange}
          value={initValue || value}
          ref={inputRef}
          className={baseClassName}
          id={id}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          onBlur={onAddDecimals}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default forwardRef(PriceTextFieldComponent);
