import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { userReducer } from './redux/UserReducer';
import scrollReducer from './redux/ScrollerReducer';


/**
 * Persisted reducers are stored in the local storage. 
 * The whitelist is used to specify which reducers to persist. 
 * The blacklist is used to specify which reducers to not persist.
 * The blacklist takes precedence over the whitelist.
 */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["user", "userProfile", "userLikes"],
};

/**
 * The rootReducer is used to combine all the reducers.
 */
const rootReducer = combineReducers({
  user: userReducer,
  scroll: scrollReducer,
});

/**
 * The persistedReducer is used to persist the state of the reducers.
 */
const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * The store is used to store the state of the reducers.
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

/**
 * The persistor is used to persist the state of the reducers.
 */
const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
