import { DocumentData, DocumentSnapshot, Timestamp } from 'firebase/firestore';

export class Profile {
    id: string;
    verified: boolean = false;
    notionCertified: boolean = false;
    creator: boolean = false;
    promotion: any | null = null;
    name: string | null = "";
    profileId: string | null = null;
    lastIdChange: Timestamp | null = Timestamp.now();
    bio: string = "";
    image: string = "";
    links: string[] = [];
    mail: string | null = "";
    created: Timestamp = Timestamp.now();
    templateLikes: Record<string, number> = {};
    templateViews: Record<string, number> = {};
    templateDownloads: Record<string, number> = {};
    views: Record<string, number> = {};
    lastUpdated: Timestamp | null = Timestamp.now();
    [key: string]: any;

    constructor(
        id: string,
        verified: boolean,
        notionCertified: boolean,
        creator: boolean,
        promotion: any | null,
        displayName: string | null,
        profileId: string | null,
        lastIdChange: Timestamp | null,
        bio: string,
        image: string,
        links: string[],
        mail: string | null,
        created: Timestamp,
        templateLikes: Record<string, number> | null,
        templateViews: Record<string, number> | null,
        templateDownloads: Record<string, number> | null,
        views: Record<string, number> | null,
        lastUpdated: Timestamp | null = Timestamp.now(),
    ) {
        this.id = id;
        this.verified = verified;
        this.notionCertified = notionCertified;
        this.creator = creator;
        this.promotion = promotion;
        this.name = displayName;
        this.profileId = profileId;
        this.lastIdChange = lastIdChange;
        this.bio = bio;
        this.image = image;
        this.links = links;
        this.mail = mail;
        this.created = created;
        this.templateLikes = templateLikes || {};
        this.templateViews = templateViews || {};
        this.templateDownloads = templateDownloads || {};
        this.views = views || {};
        this.lastUpdated = lastUpdated;
    }
}

export const profileConverter = {
    toFirestore(profile: Profile): DocumentData {
        return {
            verified: profile.verified,
            notionCertified: profile.notionCertified,
            creator: profile.creator,
            promotion: profile.promotion,
            displayName: profile.name,
            profileId: profile.profileId,
            lastIdChange: profile.lastIdChange,
            bio: profile.bio,
            image: profile.image,
            links: profile.links,
            mail: profile.mail,
            created: profile.created,
            templateLikes: profile.templateLikes,
            templateViews: profile.templateViews,
            templateDownloads: profile.templateDownloads,
            views: profile.views,
            lastUpdated: profile.lastUpdated,
            betaTester: false
        };
    },
    fromFirestore(snapshot: DocumentSnapshot): Profile {
        const data = snapshot.data();
        if (!data) {
            throw new Error("No data found for profile");
        }
        return new Profile(
            snapshot.id,
            data.verified,
            data.notionCertified,
            data.creator,
            data.promotion,
            data.displayName,
            data.profileId,
            data.lastIdChange,
            data.bio,
            data.image,
            data.links,
            data.mail,
            data.created,
            data.templateLikes,
            data.templateViews,
            data.templateDownloads,
            data.views,
            data.lastUpdated,
        );
    },
};

