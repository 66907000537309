import { AnyAction, Dispatch } from 'redux';
import { Profile } from '../models/Profile';
import { RootState } from '../store';
import { ThunkAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

export enum UserActionTypes {
  SET_SIGNED_IN = 'SET_SIGNED_IN',
  USER_PROFILE = 'USER_PROFILE',
  USER_LIKES = 'USER_LIKES',
}

interface SetSignedInAction {
  type: UserActionTypes.SET_SIGNED_IN;
  payload: boolean;
}

interface SetUserProfileAction {
  type: UserActionTypes.USER_PROFILE;
  payload: Profile | null;
}

interface SetUserLikesAction {
  type: UserActionTypes.USER_LIKES;
  payload: string[] | null;
}
interface SetUserUpdateLikeAction {
  type: UserActionTypes.USER_LIKES;
  payload: string | null;
}

export type UserAction = SetSignedInAction;
export const setSignedIn = (isSignedIn: boolean): SetSignedInAction => ({
  type: UserActionTypes.SET_SIGNED_IN,
  payload: isSignedIn,
});


export type UserProfileAction = SetUserProfileAction;
export const setUserProfile = (profile: Profile | null): UserProfileAction => ({
  type: UserActionTypes.USER_PROFILE,
  payload: profile,
});


export type UserLikesAction = SetUserLikesAction;
export const setUserLikes = (likes: string[] | null): UserLikesAction => ({
  type: UserActionTypes.USER_LIKES,
  payload: likes,
});

export function setUserSingleLikes(dispatch: Dispatch<AnyAction>, likes: string[], like: boolean, likeId: string | null) {
  let currentLikes = likes;
  if (!currentLikes) {
    currentLikes = [];
  }
  // If likeId is not null and not already present, add it to the likes array
  if (likeId !== null && like && !currentLikes.includes(likeId)) {
    const updatedLikes = [...currentLikes, likeId];
    dispatch({
      type: UserActionTypes.USER_LIKES,
      payload: updatedLikes,
    });
  }
  // If likeId is null or already present, remove it from the likes array
  if (likeId !== null && !like) {
    const updatedLikes = currentLikes.filter(id => id !== likeId);
    dispatch({
      type: UserActionTypes.USER_LIKES,
      payload: updatedLikes,
    });
  }
}