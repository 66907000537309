import { logEvent } from "@firebase/analytics";
import { analytics } from "../utils/firebase";

const TrackingEvent = {

    SIGNUP: "signup",
    SIGNUP_ERROR: "signup_error",

    LOGIN: "login",
    LOGIN_ERROR: "login_error",

    PROVIDER_TWITTER: "provider_twitter",
    PROVIDER_TWITTER_ERROR: "provider_twitter_error",

    PROVIDER_GOOGLE: "provider_google",
    PROVIDER_GOOGLE_ERROR: "provider_google_error",

    LOGOUT: "logout",
    LOGOUT_ERROR: "logout_error",

    CREATE_TEMPLATE: "create_template",
    CREATE_TEMPLATE_ERROR: "create_template_error",

    UPDATE_DRAFT_TEMPLATE: "update_draft_template",
    UPDATE_DRAFT_TEMPLATE_ERROR: "update_draft_template_error",

    UPDATE_TEMPLATE: "update_template",
    UPDATE_TEMPLATE_ERROR: "update_template_error",

    DELETE_DRAFT_TEMPLATE: "delete_draft_template",
    DELETE_DRAFT_TEMPLATE_ERROR: "delete_draft_template_error",

    DELETE_TEMPLATE: "delete_template",
    DELETE_TEMPLATE_ERROR: "delete_template_error",

    PUBLISH_TEMPLATE: "publish_template",
    PUBLISH_TEMPLATE_ERROR: "publish_template_error",

    PREVIEW_TEMPLATE: "preview_template",
    COPY_PRODUCT_LINK_TEMPLATE: "copy_product_link_template",

    UPDATE_PROFILE: "update_profile",
    UPDATE_PROFILE_ERROR: "update_profile_error",

}

export const trackSignup = () => {
    logEvent(analytics, TrackingEvent.SIGNUP);
}

export const trackSignupError = () => {
    logEvent(analytics, TrackingEvent.SIGNUP_ERROR);
}

export const trackLogin = () => {
    logEvent(analytics, TrackingEvent.LOGIN);
}

export const trackLoginError = () => {
    logEvent(analytics, TrackingEvent.LOGIN_ERROR);
}

export const trackProviderTwitter = () => {
    logEvent(analytics, TrackingEvent.PROVIDER_TWITTER);
}

export const trackProviderTwitterError = () => {
    logEvent(analytics, TrackingEvent.PROVIDER_TWITTER_ERROR);
}

export const trackProviderGoogle = () => {
    logEvent(analytics, TrackingEvent.PROVIDER_GOOGLE);
}

export const trackProviderGoogleError = () => {
    logEvent(analytics, TrackingEvent.PROVIDER_GOOGLE_ERROR);
}

export const trackLogout = () => {
    logEvent(analytics, TrackingEvent.LOGOUT);
}

export const trackLogoutError = () => {
    logEvent(analytics, TrackingEvent.LOGOUT_ERROR);
}

export const trackCreateTemplate = () => {
    logEvent(analytics, TrackingEvent.CREATE_TEMPLATE);
}

export const trackCreateTemplateError = () => {
    logEvent(analytics, TrackingEvent.CREATE_TEMPLATE_ERROR);
}

export const trackUpdateDraftTemplate = () => {
    logEvent(analytics, TrackingEvent.UPDATE_DRAFT_TEMPLATE);
}

export const trackUpdateDraftTemplateError = () => {
    logEvent(analytics, TrackingEvent.UPDATE_DRAFT_TEMPLATE_ERROR);
}

export const trackUpdateTemplate = () => {
    logEvent(analytics, TrackingEvent.UPDATE_TEMPLATE);
}

export const trackUpdateTemplateError = () => {
    logEvent(analytics, TrackingEvent.UPDATE_TEMPLATE_ERROR);
}

export const trackDeleteDraftTemplate = () => {
    logEvent(analytics, TrackingEvent.DELETE_DRAFT_TEMPLATE);
}

export const trackDeleteDraftTemplateError = () => {
    logEvent(analytics, TrackingEvent.DELETE_DRAFT_TEMPLATE_ERROR);
}

export const trackDeleteTemplate = () => {
    logEvent(analytics, TrackingEvent.DELETE_TEMPLATE);
}

export const trackDeleteTemplateError = () => {
    logEvent(analytics, TrackingEvent.DELETE_TEMPLATE_ERROR);
}

export const trackPublishTemplate = () => {
    logEvent(analytics, TrackingEvent.PUBLISH_TEMPLATE);
}

export const trackPublishTemplateError = () => {
    logEvent(analytics, TrackingEvent.PUBLISH_TEMPLATE_ERROR);
}

export const trackPreviewTemplate = () => {
    logEvent(analytics, TrackingEvent.PREVIEW_TEMPLATE);
}

export const trackCopyProductLinkTemplate = () => {
    logEvent(analytics, TrackingEvent.COPY_PRODUCT_LINK_TEMPLATE);
}

export const trackUpdateProfile = () => {
    logEvent(analytics, TrackingEvent.UPDATE_PROFILE);
}

export const trackUpdateProfileError = () => {
    logEvent(analytics, TrackingEvent.UPDATE_PROFILE_ERROR);
}

