import { TimeOption, SortOption } from '../../../../utils/utils-filter';
import { Logger } from '../../../../utils/utils-logging';
import { Filter } from './Types';

const logger = new Logger("urlUtils");

/**
 * Update filters from URL.
 * @param filters - The filters to update
 * @returns The updated filters
 */
export function updateFiltersFromUrl(filters: Filter[]): Filter[] {
    const urlParams = new URLSearchParams(window.location.search);
    const newFilters = filters.map((filter) => {
        const filterValues = urlParams.getAll(filter.id);
        logger.log("updateFiltersFromUrl", filter.id, filterValues);
        if (filterValues.length > 0) {
            filter.options = filter.options.map((option) => ({
                ...option,
                checked: filterValues.includes(option.label.toLowerCase()),
            }));
        }
        return filter;
    });
    logger.log("updateFiltersFromUrl", newFilters);
    return newFilters;
}

/**
 * Update time options from URL.
 * @param timeOptions - The time options to update
 * @returns The updated time options
 */
export function updateTimeOptionsFromUrl(timeOptions: TimeOption[]): TimeOption[] {
    const urlParams = new URLSearchParams(window.location.search);
    const timeValue = urlParams.get("time");
    logger.log("updateTimeOptionsFromUrl", timeValue);
    if (timeValue) {
        const updatedTimeOptions = timeOptions.map((option) => ({
            ...option,
            current: option.key === timeValue,
        }));
        logger.log("updateTimeOptionsFromUrl", updatedTimeOptions);
        return updatedTimeOptions;
    }
    return timeOptions;
}

/**
 * Update sort options from URL.
 * @param sortOptions - The sort options to update
 * @returns The updated sort options
 */
export function updateSortOptionsFromUrl(sortOptions: SortOption[]): SortOption[] {
    const urlParams = new URLSearchParams(window.location.search);
    const sortValue = urlParams.get("sort");
    logger.log("updateSortOptionsFromUrl", sortValue);
    if (sortValue) {
        const updatedSortOptions = sortOptions.map((option) => ({
            ...option,
            current: option.key === sortValue,
        }));
        logger.log("updateSortOptionsFromUrl", updatedSortOptions);
        return updatedSortOptions;
    }
    return sortOptions;
}

/**
 * Update search value from URL.
 * @param searchValue - The initial search value
 * @returns The updated search value
 */
export function updateSearchFromUrl(searchValue: string | null): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    const newSearchValue = urlParams.get("search");
    if (newSearchValue && newSearchValue.trim().length > 0) {
        return newSearchValue;
    }
    return searchValue;
}

/**
 * Update URL with new filters. This happens when a filter is changed.
 */
export function updateUrl(
    filters: Filter[],
    sortOptions: SortOption[],
    timeOptions: TimeOption[],
    searchValue: string | null
) {
    // Build new URL search params
    const params = new URLSearchParams();

    filters.forEach((filter) => {
        const checkedValues = filter.options
            .filter(
                (option) =>
                    option.checked && option.value !== "all" && option.value !== ""
            )
            .map((option) => encodeURIComponent(option.label.toLowerCase())); // Use encodeURIComponent for URI encoding
        if (checkedValues.length > 0) {
            params.append(filter.id, checkedValues.join(","));
        }
    });

    sortOptions.forEach((option) => {
        if (option.current && !option.default) {
            params.append("sort", option.key);
        }
    });

    timeOptions.forEach((option) => {
        if (option.current && !option.default) {
            params.append("time", option.key);
        }
    });

    if (searchValue && searchValue.length > 0) {
        params.append("search", encodeURIComponent(searchValue)); // Use encodeURIComponent for search value
    }

    // Update URL without reloading the page
    const queryString = params.toString();
    const newUrl = queryString
        ? `${window.location.pathname}?${queryString}`
        : window.location.pathname;

    window.history.replaceState({ path: newUrl }, "", newUrl);
}
