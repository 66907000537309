import {
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import { classNames } from "../../../utils/utils-react";

interface SearchButtonComponentProps {
  baseClassName?: string;
  value: string;
  placeholder: string;
  onInputChange: (value: string) => void;
}

const SearchButtonComponent: React.FC<SearchButtonComponentProps> = ({
  baseClassName,
  value,
  placeholder,
  onInputChange,
}) => {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      onInputChange(searchValue); // Execute the callback after the debounce timeout
    }, 300); // Adjust the debounce delay as needed (e.g., 300ms)

    // Clean up the previous timeout if the component re-renders before the timeout completes
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchValue, onInputChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value); // Update the searchValue state immediately
  };

  return (
    <div className={"relative " + baseClassName}>
      <MagnifyingGlassIcon className="ml-2 w-5 h-5 shrink-0 text-gray-900 absolute top-1/2 left-2 transform -translate-y-1/2" />
      <input
        type="text"
        placeholder={placeholder}
        value={searchValue}
        onChange={handleInputChange}
        className="pl-12 pr-4 py-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-200/50 rounded-full"
      />
      <div
        onClick={() => setSearchValue("")}
        className={classNames(
          searchValue ? "scale-100" : "scale-0",
          "transition-all duration-300 absolute top-1/2 right-2 transform -translate-y-1/2"
        )}
      >
        <XMarkIcon className="w-5 h-5 mr-1 shrink-0 text-gray-900" />
      </div>
    </div>
  );
};

export default SearchButtonComponent;
