import React from "react";

/**
 * Component for rendering HTML content with optional localization.
 * @param {string} html - The HTML content to render.
 */
function HtmlShortTextComponent({ html }: { html: string }) {
  // Create a temporary div to parse the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  // Select all paragraph elements and extract their text content
  const paragraphs = tempDiv.querySelectorAll(
    "*:not(" + ["h2´", "h1", "ul", "ol"].join(", ") + ")"
  );
  const textContent =
    Array.from(paragraphs)
      .map((paragraph) => paragraph.textContent)
      .join(" ") || html;

  return <div>{textContent}</div>;
}

export default HtmlShortTextComponent;
