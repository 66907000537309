type InputGroupComponentProps = {
  name?: string;
  title: string;
  description?: string;
  info?: string;
  content?: React.ReactNode;
};

const SettingsGroupComponent: React.FC<InputGroupComponentProps> = ({
  name,
  title,
  description,
  info,
  content,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-md font-semibold leading-6 text-gray-900"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {description && (
        <p
          className="mt-1 text-xs font-normal text-gray-600 leading-5"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {info && (
        <p className="mt-2 text-xs font-semibold text-gray-600">{info}</p>
      )}
      {content && <div className="mt-4">{content}</div>}
    </div>
  );
};

export default SettingsGroupComponent;
