import React from "react";
import { Destination } from "../../../utils/constants-navigation";
import { localizedValue } from "../../../utils/supported-locales";
import { ProfilePromotionItem } from "./HomeProfilePromotionsSection";
import {
  TemplatePromotion,
  TemplatePromotionItem,
} from "./HomeTemplatePromotionsSection";
import { Profile } from "../../../models/Profile";

interface ActivePromotionsItemProps {
  activeProfilePromotion: Profile | null;
  activeTemplatePromotions: TemplatePromotion[];
}

const HomeActivePromotionsItem: React.FC<ActivePromotionsItemProps> = ({
  activeProfilePromotion,
  activeTemplatePromotions,
}) => {
  return (
    <ul
      role="list"
      className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
    >
      {activeProfilePromotion != null && (
        <ProfilePromotionItem
          profilePromotion={{
            id: activeProfilePromotion.id,
            name: activeProfilePromotion.name,
            image: activeProfilePromotion.image,
            description: activeProfilePromotion.bio,
          }}
        />
      )}
      {activeTemplatePromotions.map((suggestion) => (
        <TemplatePromotionItem
          key={suggestion.template.id}
          suggestion={{
            template: {
              id: suggestion.template.id,
              thumbnailImage:
                localizedValue(suggestion.template.thumbnailImage) || "",
              title: localizedValue(suggestion.template.title) || "",
            },
            stats: suggestion.stats.map((stat) => ({
              name: stat.name,
              value: stat.value,
              changeType: stat.changeType,
              change: stat.change,
            })),
          }}
        />
      ))}
    </ul>
  );
};

export default HomeActivePromotionsItem;
