type HeaderItemProps = {
  title: string;
  desc?: string;
};

const HeaderH1Item: React.FC<HeaderItemProps> = ({ title, desc }) => {
  return (
    <div className="max-w-4xl">
      <h2
        id="header-title"
        className="text-3xl font-bold tracking-tight text-gray-900"
      >
        {title}
      </h2>
      {desc && (
        <p className="mt-1 font-normal text-lg text-gray-600 leading-7">
          {desc}
        </p>
      )}
    </div>
  );
};

export default HeaderH1Item;
