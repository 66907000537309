import { BanknotesIcon, SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";

import { Template } from "../../models/Template";
import { LanguageIcon } from "@heroicons/react/20/solid";
import { getFormattedPrice } from "../../utils/utils-formatting";

interface Props {
  template: Template;
}

const TemplateIndicatorsItemComponent: React.FC<Props> = ({ template }) => {
  const containerClassName = "";
  const indicatorClassName = "h-3 w-3 flex-shrink-0 m-2";
  return (
    <div className="flex gap-x-3">
      {template.locales.length > 1 && (
        <div className={`${containerClassName}`}>
          <LanguageIcon
            className={`${indicatorClassName} text-gray-900`}
            aria-hidden="true"
          />
        </div>
      )}

      {template.isPromoted ? (
        <>
          <div className={`${containerClassName}`}>
            <SparklesIcon
              className={`${indicatorClassName} text-purple-500`}
              aria-hidden="true"
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {template.isPaid ? (
        <>
          <div className={`${containerClassName} flex items-center`}>
            <div className="text-xs font-medium text-gray-900">
              {"$" + (template.price ? getFormattedPrice(template.price) : "")}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TemplateIndicatorsItemComponent;
