import { profile } from "console";
import React from "react";

import { Profile } from "../../../models/Profile";
import { IMAGE_COVER_PLACEHOLDER } from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";

interface ProfilePromotionItemProps {
  profilePromotion: {
    id: string;
    image: string;
    name: string | null;
    description?: string;
  };
}

export const ProfilePromotionItem: React.FC<ProfilePromotionItemProps> = ({
  profilePromotion,
}) => {
  /**
   * Initiates the profile promotion flow.
   */
  async function promoteProfile() {
    const path = Destination.ACCOUNT_PROFILE_PROMOTION;
    window.open(path, "_blank");
  }

  return (
    <div
      onClick={promoteProfile}
      key={profilePromotion.id}
      className="transition-all ease-in-out duration-300 bg-white rounded-lg border border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]"
    >
      <div className="bg-indigo-500/[0.025] w-full flex items-center gap-x-7 border-b border-gray-900/5 p-3">
        <img
          src={profilePromotion.image || IMAGE_COVER_PLACEHOLDER}
          className="h-16 w-16 flex-none rounded-md bg-white object-cover shadow"
        />
        <h4 className="text-md font-semibold leading-6 text-gray-900 line-clamp-2">
          {profilePromotion.name}
        </h4>
      </div>
      {profilePromotion.description && (
        <dl className="divide-y divide-gray-100 text-xs">
          <dt className="flex justify-between p-3 text-gray-500 leading-6">
            {profilePromotion.description}
          </dt>
        </dl>
      )}
    </div>
  );
};

interface ProfilePromotionsComponentProps {
  profile: Profile | null;
}

const HomeProfilePromotionsSection: React.FC<
  ProfilePromotionsComponentProps
> = ({ profile }) => {
  if (!profile) return null;
  return (
    <ul role="list" className="mt-6 max-w-xs">
      <ProfilePromotionItem
        profilePromotion={{
          id: profile.id,
          name: profile.name,
          image: profile.image,
          description: profile.bio,
        }}
      />
    </ul>
  );
};

export default HomeProfilePromotionsSection;
