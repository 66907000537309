import { User } from 'firebase/auth';
import { AnyAction, Dispatch } from 'redux';
import { setSignedIn, setUserLikes, setUserProfile, setUserSingleLikes } from '../redux/UserActions';
import { getUser, getUserLikes, setupNewUserIfNecessary } from './firestore-service';
import { updateTemplateLikes, updateUndoTemplateLikes } from './functions-service';
import { template } from 'lodash';
import { Logger } from '../utils/utils-logging';

const logger = new Logger("action-service.ts");

export function getMarketplaceFreeTemplateId(templateId: string) {
  return
}

/**
 * Adds a like to a template.
 * @param dispatch The dispatch function from the redux store.
 * @param likes The current likes array.
 * @param templateId The template id to add the like to.
 */
export function likeTemplate(dispatch: Dispatch<AnyAction>, likes: string[], templateId: string) {
  logger.log("likeTemplate() - likes:", likes, "templateId:", templateId);
  try {
    updateTemplateLikes(templateId);
    setUserSingleLikes(dispatch, likes, true, templateId);
  } catch (e) {
    logger.logError("likeTemplate() - Error liking template:", e);
    throw e;
  }
}

/**
 * Removes a like from a template.
 * @param dispatch The dispatch function from the redux store.
 * @param likes The current likes array.
 * @param templateId The template id to remove the like from.
 */
export function likeTemplateUndo(dispatch: Dispatch<AnyAction>, likes: string[], templateId: string) {
  logger.log("likeTemplateUndo() - likes:", likes, "templateId:", templateId);
  try {
    updateUndoTemplateLikes(templateId);
    setUserSingleLikes(dispatch, likes, false, templateId);
  } catch (e) {
    logger.logError("likeTemplateUndo() - Error unliking template:", e);
    throw e;
  }
}

/**
 * Logs the user out of the application
 * @param dispatch The dispatch function from the redux store.
 */
export async function logout(dispatch: Dispatch<AnyAction>) {
  logger.log("logout() - Logging out");
  try {
    dispatch(setUserLikes([]));
    dispatch(setUserProfile(null));
    dispatch(setSignedIn(false));
  } catch (e) {
    logger.logError("logout() - Error logging out:", e);
    throw e;
  }
}

/**
 * Logs the user into the application.
 * @param dispatch The dispatch function from the redux store.
 * @param user The user object from firebase auth.
 */
export async function login(dispatch: Dispatch<AnyAction>, user: User) {
  logger.log("login() - Logging in:", user);
  try {
    await setupNewUserIfNecessary(user);
    const profile = await getUser(user.uid);
    const likes = await getUserLikes(user.uid);
    dispatch(setUserLikes(likes));
    dispatch(setUserProfile(profile));
    dispatch(setSignedIn(true));
  } catch (e) {
    logger.logError("login() - Error logging in:", e);
    throw e;
  }
}


/**
 * Closes the current window.
 */
export function closeWindow() {
  logger.log("closeWindow() - Closing window");
  window.opener = null;
  window.open("", "_self");
  window.close();
}
