import { PROFILE_URL_PREFIX } from "./constants";

export class AvatarIconProvider {

    getRandomNotioly(): string {
        const allNotioly = this.getAllNotioly();
        return allNotioly[Math.floor(Math.random() * allNotioly.length)];
    }

    getAllNotioly(): string[] {
        const allNotiolyIconNames = this.getAllNotiolyIconNames();
        return allNotiolyIconNames.map((name: string) => {
            return `${PROFILE_URL_PREFIX}/o/tools%2Favatars_notioly%2F${name}?alt=media`;
        });
    }

    public getAllNotiolyIconNames() {
        return [
            "accomplishment.png",
            "account_protection.png",
            "accounting.png",
            "achievement.png",
            "add_to_cart.png",
            "adventure.png",
            "advertise.png",
            "affiliate_program.png",
            "after_work.png",
            "ai_research.png",
            "alert.png",
            "algorithm.png",
            "alphabet.png",
            "altruism.png",
            "analysing.png",
            "announcement.png",
            "anxiety.png",
            "apps.png",
            "architect.png",
            "archive.png",
            "archive_files.png",
            "art_gallery.png",
            "artist.png",
            "astronomy.png",
            "attachments.png",
            "audiobook.png",
            "autumn.png",
            "bad_day.png",
            "balance.png",
            "ballon.png",
            "bike_ride.png",
            "bird_care.png",
            "bitcoin_savings.png",
            "blowing_bubbles.png",
            "body_building.png",
            "bowling.png",
            "breakfast.png",
            "building.png",
            "building_blocks.png",
            "buildings.png",
            "business_plan.png",
            "business_recipe.png",
            "butterflies.png",
            "calendar.png",
            "call.png",
            "call_center.png",
            "camping.png",
            "camping_out.png",
            "candle.png",
            "car.png",
            "caring.png",
            "carpenter.png",
            "cash_withdrawal.png",
            "cat_lover.png",
            "chart.png",
            "chatting.png",
            "check.png",
            "checking_in.png",
            "checking_notifications.png",
            "checklist.png",
            "cheers.png",
            "chemistry_teacher.png",
            "chill_time.png",
            "chilling.png",
            "circle.png",
            "click_here.png",
            "clock_is_ticking.png",
            "co_workers.png",
            "coding.png",
            "coffee.png",
            "collaboration.png",
            "collecting.png",
            "community.png",
            "compass.png",
            "competition.png",
            "components.png",
            "connecting.png",
            "contactless_payment.png",
            "content_writer.png",
            "copycat.png",
            "crossword_puzzle.png",
            "cryptocurrency.png",
            "cubes.png",
            "cup_of_coffee.png",
            "curiosity.png",
            "currency_exchange.png",
            "cutting.png",
            "cycling.png",
            "dancer.png",
            "data_protection.png",
            "deal.png",
            "delivered.png",
            "delivery.png",
            "design_tools.png",
            "details.png",
            "detective.png",
            "disappointed.png",
            "discount.png",
            "diversity.png",
            "doctor.png",
            "domino_effect.png",
            "donation.png",
            "download.png",
            "drawing.png",
            "drawing_a_line.png",
            "dreaming.png",
            "drinking.png",
            "driving.png",
            "e_scooter.png",
            "ecommerce.png",
            "education.png",
            "effortless.png",
            "elegance.png",
            "email_delivery.png",
            "embracing_the_universe.png",
            "empty_box.png",
            "envelope.png",
            "equilibrium.png",
            "erasing.png",
            "examining.png",
            "expired.png",
            "exploring.png",
            "exploring_the_globe.png",
            "faqs.png",
            "farming.png",
            "fast_internet.png",
            "fast_worker.png",
            "feedback.png",
            "file.png",
            "fishing.png",
            "flag.png",
            "flowers.png",
            "focus.png",
            "food_ordering.png",
            "found_it.png",
            "freedom.png",
            "frienship.png",
            "gestures.png",
            "give_me_five.png",
            "global.png",
            "globalization.png",
            "going_to_school.png",
            "going_to_work.png",
            "googling.png",
            "gossip.png",
            "graduating.png",
            "group_chat.png",
            "growing_up.png",
            "growth.png",
            "handywoman.png",
            "happiness.png",
            "happy_shopping.png",
            "happy_vibes.png",
            "hard_worker.png",
            "having_fun.png",
            "headache.png",
            "high_five.png",
            "hop_on_a_call.png",
            "hug.png",
            "idea.png",
            "image_preview.png",
            "in_the_park.png",
            "inbox.png",
            "incorrect_password.png",
            "increase.png",
            "influencer.png",
            "inspection.png",
            "instant_picture.png",
            "instant_support.png",
            "interview.png",
            "intruder.png",
            "investing.png",
            "investment.png",
            "job_application.png",
            "journalling.png",
            "jumping.png",
            "knowledge_base.png",
            "landscape.png",
            "launch.png",
            "laziness.png",
            "leadership.png",
            "learning.png",
            "level_of_satisfaction.png",
            "library.png",
            "like.png",
            "link_sharing.png",
            "listening_to_music.png",
            "loading_time.png",
            "location.png",
            "login.png",
            "looking_down.png",
            "looking_for_friends.png",
            "looking_for_something.png",
            "looking_out_the_window.png",
            "lost_the_way.png",
            "love.png",
            "love_laugh_live.png",
            "magic.png",
            "magic_door.png",
            "magic_wand.png",
            "mailbox_full.png",
            "make_it_rain.png",
            "man_with_dog.png",
            "marketing_plan.png",
            "mask.png",
            "meditation.png",
            "meeting.png",
            "mention.png",
            "mobile_payments.png",
            "modern_socialization.png",
            "monday_feeling.png",
            "money.png",
            "mortgage.png",
            "movie_time.png",
            "moving.png",
            "moving_files.png",
            "multitasking.png",
            "music.png",
            "new_idea.png",
            "newspaper.png",
            "nice_dreams.png",
            "nodes.png",
            "nomad_life.png",
            "not_found.png",
            "notification.png",
            "nutritionist.png",
            "office.png",
            "office_break.png",
            "ok.png",
            "on_air.png",
            "overthinking.png",
            "package_tracking.png",
            "page_loading_speed.png",
            "painting.png",
            "paper_airplane.png",
            "paperboy.png",
            "parenting.png",
            "party.png",
            "passports.png",
            "password.png",
            "peace.png",
            "peace___love.png",
            "people_finder.png",
            "personal_finance.png",
            "pet.png",
            "photographer.png",
            "pictures.png",
            "piggy_bank.png",
            "pitching.png",
            "place_search.png",
            "planet.png",
            "planning_a_trip.png",
            "plant_seed.png",
            "plants.png",
            "playing.png",
            "playing_cards.png",
            "playing_golf.png",
            "playing_the_flute.png",
            "playing_the_guitar.png",
            "podcast.png",
            "poll.png",
            "pop_up_message.png",
            "positive_news.png",
            "postman.png",
            "project.png",
            "projecting.png",
            "pros_and_cons.png",
            "proud.png",
            "public_speaking.png",
            "puzzle.png",
            "qr_code.png",
            "queue.png",
            "raining.png",
            "rc_airplane.png",
            "reading.png",
            "reading_the_news.png",
            "real_estate_agent.png",
            "relaxing.png",
            "relaxing_time.png",
            "reminder.png",
            "remote_meeting.png",
            "report.png",
            "resume.png",
            "reward.png",
            "robot.png",
            "running.png",
            "safe.png",
            "sale_notification.png",
            "savings.png",
            "scalability.png",
            "schoolgirl.png",
            "scientist.png",
            "searching.png",
            "secretary.png",
            "seeds_of_love.png",
            "self_feed.png",
            "self_portrait.png",
            "selfie.png",
            "send_money.png",
            "sending.png",
            "settings.png",
            "shapes.png",
            "shared_coupon.png",
            "sharing.png",
            "shooting.png",
            "shopping.png",
            "shopping_with_my_dog.png",
            "signature.png",
            "skateboarding.png",
            "skydiving.png",
            "sleeping.png",
            "slot_machine.png",
            "smart_watch.png",
            "social_acceptance.png",
            "social_distancing.png",
            "social_networks.png",
            "space.png",
            "spring.png",
            "spying.png",
            "stairs.png",
            "stamp.png",
            "star_rating.png",
            "sticky_notes.png",
            "strategy.png",
            "study_group.png",
            "studying.png",
            "subscribe.png",
            "summertime.png",
            "support.png",
            "supporters.png",
            "surprise.png",
            "swing_set.png",
            "take_care.png",
            "take_it.png",
            "taking_notes.png",
            "taking_pictures.png",
            "target.png",
            "targeting.png",
            "tasks.png",
            "teacher.png",
            "teaming_up.png",
            "texting.png",
            "thinking.png",
            "tightrope_walker.png",
            "time_to_fly.png",
            "time_to_yoga.png",
            "tips.png",
            "tired.png",
            "to_do_list.png",
            "toast.png",
            "together.png",
            "toggle_switch.png",
            "tools.png",
            "tourist.png",
            "tower_of_cards.png",
            "travel.png",
            "true_fan.png",
            "unboxing.png",
            "up_trend.png",
            "upload.png",
            "valentine_s_day.png",
            "videomaker.png",
            "virtual_reality.png",
            "vocabulary.png",
            "waiter.png",
            "waiting_for_departure.png",
            "walking.png",
            "watching_a_movie.png",
            "website_builder.png",
            "welcome.png",
            "wifi.png",
            "wine_tasting.png",
            "winter.png",
            "wish_list.png",
            "working_late.png",
            "workspace.png",
            "world_wide_web.png",
            "writing.png",
            "yeah.png",
            "zip_file.png",
        ];
    }
}