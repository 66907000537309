import React, {
  useRef,
  forwardRef,
  ForwardedRef,
  ChangeEvent,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import { classNames } from "../../../utils/utils-react";
import { initial } from "lodash";

type InputProps = {
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  initialValue?: string;
  value?: string;
  placeholder?: string;
  // Optional attributes
  autoComplete?: string;
  type?: string;
  defaultClassName?: string;
  additionalClassName?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  // Common event props
  onMouseEnter?: (event: MouseEvent<HTMLInputElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  // Additional props
  count?: boolean;
  leadingChildren?: React.ReactNode; // Updated prop name
};

const CustomInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    name,
    onChange,
    initialValue,
    value,
    placeholder,
    autoComplete,
    type,
    defaultClassName = "w-full block rounded-md border-0 py-2 pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
    additionalClassName,
    id,
    required,
    disabled,
    maxLength,
    minLength,
    pattern,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    count,
    leadingChildren,
  },
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [initValue, setInitValue] = useState<string | undefined>(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(initialValue?.length || 0);
  }, [value]);

  useEffect(() => {
    setCharCount(value?.length || 0);
  }, [value]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInitValue(undefined);
    const inputValue = event.target.value;
    if (onChange) onChange(event);
    setCharCount(inputValue.length);
  };

  const baseClassName = classNames(
    defaultClassName,
    additionalClassName ? additionalClassName : ""
  );

  const isBelowMinLength = minLength !== undefined && charCount < minLength;
  const isAboveMaxLength = maxLength !== undefined && charCount > maxLength;

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div className="flex items-center">
        {leadingChildren}
        <input
          type={type}
          name={name}
          autoComplete={autoComplete}
          onChange={handleInputChange}
          value={initValue || value}
          ref={ref || inputRef}
          className={baseClassName}
          id={id}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          pattern={pattern}
          placeholder={placeholder}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onWheel={(event) => event.currentTarget.blur()} // Add onWheel event to blur the input on scroll
        />
      </div>
      {count && (
        <span
          className={classNames(
            isBelowMinLength
              ? "text-red-500"
              : isAboveMaxLength
              ? "text-red-500"
              : "text-gray-500",
            "absolute text-xs text-gray-500 right-0 bottom-0 p-2"
          )}
        >
          {isBelowMinLength ? (
            <>
              {charCount}/{minLength}
            </>
          ) : isAboveMaxLength ? (
            <>
              {charCount}/{maxLength}
            </>
          ) : (
            <>
              {charCount}/{maxLength}
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default forwardRef(CustomInput);
