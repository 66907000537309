export interface PinData {
    title: string; // Required, 100 characters maximum
    mediaUrl: string; // Required, must be a publicly available link
    pinterestBoard: string; // Required, title of the Pinterest board
    description?: string; // Optional, 500 characters maximum
    link?: string; // Optional, URL that the Pin points to
    publishDate?: string; // Optional, YYYY-MM-DD or YYYY-MM-DDTHH:MM:SS format
    keywords?: string; // Optional, comma-separated list of keywords
}

export function convertPinsToCsv(pins: PinData[]): string {
    const csvHeader = "Title,Media URL,Pinterest board,Thumbnail,Description,Link,Publish date,Keywords";
    const csvRows = pins.map(pin => {
        const title = pin.title.slice(0, 100);
        const description = pin.description?.slice(0, 500) ?? '';
        return [
            `"${title.replace(/"/g, '""')}"`, // Encapsulate and escape quotes
            `"${pin.mediaUrl.replace(/"/g, '""')}"`,
            `"${pin.pinterestBoard.replace(/"/g, '""')}"`,
            `""`, // Empty field, but properly quoted
            `"${description.replace(/"/g, '""')}"`,
            `"${pin.link ? pin.link.replace(/"/g, '""') : ''}"`,
            `"${pin.publishDate ? pin.publishDate.replace(/"/g, '""') : ''}"`,
            `"${pin.keywords ? pin.keywords.replace(/"/g, '""') : ''}"`, // Escape and encapsulate
        ].join(',');
    });
    return [csvHeader, ...csvRows].join('\n');
}

// Function to trigger browser to download CSV file
export function downloadCsv(csvString: string, fileName: string): void {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}