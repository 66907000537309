import React from "react";
import { connect, useSelector } from "react-redux";
import { Template } from "../../../models/Template";
import {
  getTemplates,
  updatePublicTemplate,
} from "../../../services/firestore-service";
import { RootState } from "../../../store";
import { FIREBASE_ADMIN_UID } from "../../../utils/constants";
import { Logger } from "../../../utils/utils-logging";
import TemplateMetaDataItem from "./TemplateMetaDataItem";

const logger = new Logger("MetaDataScreen");

const TemplatesMetaDataPage = () => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const [templates, setTemplates] = React.useState<Template[] | null>(null);

  const loadTemplatesWithMetaData = async () => {
    logger.log("loadTemplatesWithMetaData");
    try {
      const templates = await getTemplates(); // getTemplatesWithMetaData();
      const sortedTemplates = templates?.sort((a, b) => {
        if (a.created == null || b.created == null) return 0;
        return b.created.toMillis() - a.created.toMillis();
      });
      logger.log("sortedTemplates", sortedTemplates);
      setTemplates(sortedTemplates || null);
    } catch (error) {
      logger.logError(error);
    }
  };

  const markTemplateAsDone = async (template: Template) => {
    if (!template.metaData) return;
    try {
      template.metaData.shared = true;
      await updatePublicTemplate(template);
      setTemplates((templates) => {
        if (templates == null) return null;
        return templates.filter((t) => t.id != template.id);
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    logger.log("useEffect");
    if (isSignedIn && userProfile?.id == FIREBASE_ADMIN_UID) {
      if (templates == null) loadTemplatesWithMetaData();
    }
  }, []);

  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-6">
        {templates &&
          templates.map((template: Template) => (
            <TemplateMetaDataItem
              markTemplateAsDone={markTemplateAsDone}
              key={template.id}
              templateOrigin={template}
            />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: state.user.isSignedIn,
  userProfile: state.user.profile,
});

export default connect(mapStateToProps)(TemplatesMetaDataPage);
