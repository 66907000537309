import { DocumentData, Timestamp } from "firebase/firestore";

/**
 * Represents a customer of a creator profile.
 * @property {string} id The customer ID.
 * @property {string | null} name The customer's name.
 * @property {string | null} mail The customer's mail.
 * @property {number} price The total price of the customer.
 * @property {string[]} productIds The product IDs of the customer.
 * @property {Timestamp} timestamp The timestamp of when the customer was created.
 * @property {string | null} profileid The profile ID of the customer.
 *
 */
export class Customer {
  id: string | null = null;
  name: string | null = null;
  mail: string | null = null;
  price = 0;
  productIds: string[] = [];
  profileId: string | null = null;
  timestamp: Timestamp = Timestamp.now();

  /**
   * Creates a new Customer instance.
   * @param {string | null} name The customer's name.
   * @param {string | null} mail The customer's mail.
   * @param {number} price The total price of the customer.
   * @param {string[]} productIds The product IDs of the customer.
   * @param {string | null} profileId The profile ID of the customer.
   */
  constructor(name: string | null, mail: string | null, price: number, productIds: string[], profileId: string | null) {
    this.name = name;
    this.mail = mail;
    this.price = price;
    this.productIds = productIds;
    this.profileId = profileId;
    this.timestamp = Timestamp.now();
  }
}

/**
 * Converter for Profile class.
 */
export const customerConverter = {
  /**
   * Converts a Customer instance to Firestore data.
   * @param {Customer}  customer The Customer instance to convert.
   * @return {DocumentSnapshot} The converted Firestore data.
   */
  toFirestore(customer: Customer): DocumentData {
    const data: DocumentData = {
      name: customer.name,
      mail: customer.mail,
      price: customer.price,
      productIds: customer.productIds,
      profileId: customer.profileId,
      timestamp: customer.timestamp,
    };
    return data;
  },

  /**
   * Converts Firestore data to a Profile instance.
   * @param {any} data The Firestore snapshot containing the data.
   * @return {Customer} The converted Customer instance.
   */
  fromFirestore(data: any): Customer {
    const customer = new Customer(data.name || null, data.mail || null, data.price || 0, data.productIds || [], data.profileId || null);
    customer.id = data.id;
    customer.timestamp = data.timestamp;
    return customer;
  },
};
