import { DocumentData, DocumentSnapshot, Timestamp } from 'firebase/firestore';
import { LocaleKey } from "../utils/supported-locales";
import { TemplateFeature, templateFeatureConverter } from './TemplateFeature';
import { TemplatePromotion } from './TemplatePromotion';
import { TemplateMetaData, templateMetaDataConverter } from './metadata/TemplateMetaData';

/**
* Represents a Notion Template.
*/
export class Template {
    public title: Record<LocaleKey, string> = {};
    public desc: Record<LocaleKey, string> = {};
    public templateUrl: Record<LocaleKey, string> = {};
    public thumbnailImage: Record<LocaleKey, string> = {};
    public coverImages: Record<LocaleKey, string[]> = {};
    public userId: string;
    public id: string = "";
    public promotion: TemplatePromotion | null = null;
    public features: Record<LocaleKey, TemplateFeature[]>;
    public locales: string[] = [];
    public paidUrls: Record<LocaleKey, string[]> | null = null;
    public categories: string[] = [];
    public likes: Record<string, number> = {};
    public views: Record<string, number> = {};
    public downloads: Record<string, number> = {};
    public created: Timestamp | null = Timestamp.now();
    public published: Timestamp | null = null;
    public price: number | null = null;
    public priceSuggested: number | null = null;
    public metaData: TemplateMetaData | null = null;
    public lastUpdated: Timestamp | null = Timestamp.now();
    public externalUrls: Record<string, string> = {};
    [key: string]: any;

    constructor(
        userId: string,
        locales: string[] = [],
        id: string = "",
        title: Record<LocaleKey, string> = {},
        desc: Record<LocaleKey, string> = {},
        templateUrl: Record<LocaleKey, string> = {},
        thumbnailImage: Record<LocaleKey, string> = {},
        coverImages: Record<LocaleKey, string[]> = {},
        promotion: TemplatePromotion | null = null,
        features: Record<LocaleKey, TemplateFeature[]> = {},
        paidUrls: Record<LocaleKey, string[]> | null = null,
        categories: string[] = [],
        likes: Record<LocaleKey, number> = {},
        views: Record<LocaleKey, number> = {},
        downloads: Record<LocaleKey, number> = {},
        created: Timestamp = Timestamp.now(),
        published: Timestamp | null = null,
        price: number | null = null,
        priceSuggested: number | null = null,
        metaData: TemplateMetaData | null = null,
        lastUpdated: Timestamp | null = Timestamp.now(),
        externalUrls: Record<string, string> = {},
    ) {
        this.title = title;
        this.desc = desc;
        this.templateUrl = templateUrl;
        this.thumbnailImage = thumbnailImage;
        this.coverImages = coverImages;
        this.userId = userId;
        this.id = id;
        this.promotion = promotion;
        this.features = features;
        this.locales = locales;
        this.paidUrls = paidUrls;
        this.categories = categories;
        this.likes = likes;
        this.views = views;
        this.downloads = downloads;
        this.created = created;
        this.published = published;
        this.price = price;
        this.priceSuggested = priceSuggested;
        this.metaData = metaData;
        this.lastUpdated = lastUpdated;
        this.externalUrls = externalUrls;
    }

    get isPaid(): boolean {
        return this.price != null || this.paidUrls !== undefined && this.paidUrls && Object.keys(this.paidUrls).length > 0 || false;
    }

    get isPromoted(): boolean {
        return this.promotion !== undefined && this.promotion !== null;
    }

    get hasData(): boolean {
        const hasTitle = Object.keys(this.title).some(key => this.title[key].length > 0);
        const hasDesc = Object.keys(this.desc).some(key => this.desc[key].length > 0);
        const hasTemplateUrl = Object.keys(this.templateUrl).some(key => this.templateUrl[key].length > 0);

        return hasTitle || hasDesc || hasTemplateUrl;
    }

    get isPublished(): boolean {
        return this.published !== undefined && this.published !== null;
    }

    get isDraft(): boolean {
        return !this.isPublished;
    }
}

export const templateConverter = {
    toFirestore(template: Template): DocumentData {

        const featuresData: Record<LocaleKey, object[]> = {};
        for (const localeKey of Object.keys(template.features)) {
            featuresData[localeKey] = template.features[localeKey].map((feature: TemplateFeature) => (
                templateFeatureConverter.toFirestore(feature)
            ));
        }

        return {
            userId: template.userId,
            locales: template.locales,
            title: template.title,
            desc: template.desc,
            templateUrl: template.templateUrl,
            thumbnailImage: template.thumbnailImage,
            coverImages: template.coverImages,
            promotion: template.promotion,
            features: featuresData,
            paidUrls: template.paidUrls,
            categories: template.categories,
            likes: template.likes,
            views: template.views,
            downloads: template.downloads,
            created: template.created,
            published: template.published,
            price: template.price,
            priceSuggested: template.priceSuggested,
            metaData: template.metaData ? templateMetaDataConverter.toFirestore(template.metaData) : null,
            lastUpdated: template.lastUpdated,
            externalUrls: template.externalUrls,
        };
    },
    fromFirestore(snapshot: DocumentSnapshot): Template {
        const data = snapshot.data();
        if (!data) {
            throw new Error("No data found for template");
        }

        const features: Record<LocaleKey, TemplateFeature[]> = {};
        for (const localeKey of Object.keys(data.features)) {
            features[localeKey] = data.features[localeKey].map((featureData: DocumentData) => (
                templateFeatureConverter.fromFirestore(featureData)
            ));
        }

        return new Template(
            data.userId,
            data.locales,
            snapshot.id,
            data.title,
            data.desc,
            data.templateUrl,
            data.thumbnailImage,
            data.coverImages,
            data.promotion,
            features,
            data.paidUrls,
            data.categories,
            data.likes,
            data.views,
            data.downloads,
            data.created,
            data.published,
            data.price,
            data.priceSuggested,
            data.metaData ? templateMetaDataConverter.fromFirestore(data.metaData) : null,
            data.lastUpdated,
            data.externalUrls,
        );
    },
};
