type HeaderItemProps = {
  title: string;
  desc?: string;
};

const HeaderH1Item: React.FC<HeaderItemProps> = ({ title, desc }) => {
  return (
    <div className="max-w-4xl">
      <h1
        id="header-title"
        className="text-5xl font-bold tracking-tight text-gray-900"
      >
        {title}
      </h1>
      {desc && (
        <p className="mt-3 font-normal text-md text-gray-600 leading-7">
          {desc}
        </p>
      )}
    </div>
  );
};

export default HeaderH1Item;
