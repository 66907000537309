import React from "react";

export type SocialLinkType = {
  url: string;
  icon: string;
  label: string;
};

interface FooterSocialComponentProps {
  link: SocialLinkType;
}

const FooterSocialComponent: React.FC<FooterSocialComponentProps> = ({
  link,
}) => {
  return (
    <div className="rounded-full hover:bg-gray-200/75">
      <a
        href={link.url}
        className="text-gray-900 transition hover:opacity-75"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="h-7 w-7 m-3"
          src={link.icon}
          alt={link.label}
          aria-label={link.label}
        />
      </a>
    </div>
  );
};

export default FooterSocialComponent;
