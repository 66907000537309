import React from "react";
import {
  getDomainFromUrl,
  getSocialNetworkName,
  getSocialNetworkSvgIcon,
} from "../../../utils/utils-formatting";
import { classNames } from "../../../utils/utils-react";

interface Props {
  onClick?: () => void;
  imgRef?: string;
  href: string;
  target?: string;
  rel?: string;
  parentClassNames?: string;
  text: string;
}

const SimpleSocialButtonComponent: React.FC<Props> = ({
  onClick,
  imgRef,
  href,
  target,
  rel,
  parentClassNames,
  text,
}) => {
  const domain = getDomainFromUrl(href);
  const name = getSocialNetworkName(domain);
  const icon = getSocialNetworkSvgIcon(domain);

  console.log(icon, name, domain);
  const baseClassNames = classNames(
    "text-gray-800 hover:bg-gray-100 bg-white border border-gray-300 items-center",
    "flex transition-all ease-in-out duration-300 rounded shadow-sm py-2.5 px-4 text-sm font-medium outline-none"
  );

  if (onClick)
    return (
      <div className={parentClassNames}>
        <a onClick={onClick} target={target || ""} rel={rel || ""}>
          <button type="button" className={baseClassNames}>
            {imgRef && <img className="h-5 w-5" src={imgRef} alt={name} />}
            {text && <span className="ml-2">{text}</span>}
          </button>
        </a>
      </div>
    );

  return (
    <div className={parentClassNames}>
      <a href={href || "#"} target={target || ""} rel={rel || ""}>
        <button type="button" className={baseClassNames}>
          {imgRef && <img className="h-5 w-5" src={imgRef} alt={name} />}
          {text && <span className="ml-2">{text}</span>}
        </button>
      </a>
    </div>
  );
};

export default SimpleSocialButtonComponent;
