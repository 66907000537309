import React from "react";

const LoadingComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="w-10 h-10 border-t-4 border-b-0 border-black border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default LoadingComponent;
