import { classNames } from "../../../utils/utils-react";

interface Props {
  text: string;
  baseClassName?: string;
}

export const FocusLineComponent: React.FC<Props> = ({
  text,
  baseClassName,
}) => {
  return (
    <h5
      className={classNames(
        baseClassName ? baseClassName : "",
        "text-sm font-normal tracking-tight text-white"
      )}
    >
      <span className="px-3 py-1.5 rounded-md border border-indigo-300 bg-indigo-500 relative inline-block">
        <span className="relative">{text}</span>
      </span>
    </h5>
  );
};

export default FocusLineComponent;
