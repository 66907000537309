/**
 * OrderType model.
 */
export enum OrderType {
    TemplatePromotion = "promotion-template",
    ProfilePromotion = "promotion-profile",
    MarketplaceTemplate = "marketplace-template",
}

/**
 * Gets the label of an order type.
 * @param orderType the order type.
 * @returns the label of the order type.
 */
export function getLabelOfOrderType(orderType: OrderType): string {
    switch (orderType) {
        case OrderType.ProfilePromotion:
            return "Profile Promotion";
        case OrderType.TemplatePromotion:
            return "Template Promotion";
        case OrderType.MarketplaceTemplate:
            return "Template";
        default:
            return "";
    }
}