import React from "react";

type HeaderComponentProps = {
  children?: React.ReactNode;
  visible?: boolean;
  title?: string;
  subtitle?: string;
};

const HeaderComponent: React.FC<
  HeaderComponentProps & { level: "h1" | "h2" | "h3" }
> = ({ children, visible = true, title, subtitle, level }) => {
  if (!visible) return null;

  const HeadingTag = level;

  const containerClasses = {
    h1: "mb-12 max-w-3xl",
    h2: "mb-8 max-w-3xl",
    h3: "mb-6 max-w-3xl",
  };
  const headingClasses = {
    h1: "text-4xl font-bold tracking-tight text-gray-900",
    h2: "text-xl font-bold tracking-tight text-gray-900",
    h3: "text-lg font-semibold text-gray-900 leading-6",
  };

  const subtitleClasses = {
    h1: "mt-3 font-normal text-lg text-gray-600 leading-7",
    h2: "mt-1 font-normal text-md text-gray-600 leading-7",
    h3: "mt-1 text-xs font-normal text-gray-600 leading-5",
  };

  return (
    <div>
      <div className={containerClasses[level]}>
        <HeadingTag className={headingClasses[level]}>{title}</HeadingTag>
        <p className={subtitleClasses[level]}>{subtitle}</p>
      </div>
      {children}
    </div>
  );
};

export const DashboardH1: React.FC<HeaderComponentProps> = (props) => (
  <HeaderComponent {...props} level="h1" />
);

export const DashboardH2: React.FC<HeaderComponentProps> = (props) => (
  <HeaderComponent {...props} level="h2" />
);

export const DashboardH3: React.FC<HeaderComponentProps> = (props) => (
  <HeaderComponent {...props} level="h3" />
);
