import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe';

const stripeKey = "pk_test_51I0mr2CLycP8uqQZtzd0uDsw4XcOx3n42EJXqaAi9RtQKSKuKgTauxw4c6iulPu1fw3Iq2GuUKWIOGNJ4MxreyCN00XK88jMzc"

export async function handleStripeResponse(sessionJson: string, stripeAccount?: string) {
    try {
        const session = JSON.parse(sessionJson) as Stripe.Checkout.Session;
        console.log("handleStripeResponse: ", JSON.stringify(session), "stripeAccount: ", stripeAccount);
        // const options: StripeConstructorOptions = { stripeAccount: stripeAccount }
        const stripe = await loadStripe(stripeKey);
        if (!stripe) {
            console.log("handleStripeResponse: ", JSON.stringify(sessionJson), "stripe: ", stripe);
            throw new Error("Failed to load Stripe");
        }

        const sessionId = session.id;
        if (!sessionId) {
            console.log("handleStripeResponse: ", JSON.stringify(session));
            throw new Error("Failed to create Stripe session");
        }

        stripe.redirectToCheckout({
            sessionId: sessionId,
        }).then(function (result) {
            console.log("handleStripeResponse: ", JSON.stringify(result));
            throw new Error("Failed to redirect to Stripe checkout. " + JSON.stringify(result));
        });
    } catch (e) {
        console.log("handleStripeResponse: ", e);
        throw e;
    }
}

