import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React from "react";
import { FAQ } from "./FAQ";

export const FAQItem = ({ question, answer }: FAQ) => (
  <Disclosure as="div" key={question}>
    {({ open }) => (
      <>
        <dt>
          <Disclosure.Button className="pt-6 flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-lg font-semibold leading-7">{question}</span>
            <span className="ml-6 flex h-7 items-center">
              {open ? (
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </dt>
        <Disclosure.Panel as="dd" className="mt-2 pr-12">
          <p
            className="text-md font-normal leading-7 text-gray-600"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
