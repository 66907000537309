import React from "react";
import { ReactComponent as CheckBadgeIcon } from "./CheckBadgeIcon.svg"; // Replace with your actual icon import

interface BadgeProps {
  icon: React.ReactNode; // Accept any valid React Node as an icon
  text: string;
  isActive?: boolean;
}

const BadgeComponent: React.FC<BadgeProps> = ({ icon, text, isActive }) => {
  const badgeClasses = `max-w-fit rounded-full flex flex-inline items-center gap-x-2 px-4 py-2 ${
    isActive ? "bg-indigo-50 text-indigo-600" : "bg-gray-100 text-gray-600"
  }`;

  return (
    <div className={badgeClasses}>
      <span className="h-5 w-5">{icon}</span>
      <p
        className={`text-xs ${isActive ? "text-indigo-600" : "text-gray-600"}`}
      >
        {text}
      </p>
    </div>
  );
};

export default BadgeComponent;
