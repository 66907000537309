import { FirestoreDataConverter, Timestamp } from "firebase-admin/firestore";
import { LocaleKey } from "../../utils/supported-locales";

/**
 * Class representing the meta data of a template.
 */
export class TemplateMetaData {
  public timestamp: Timestamp;
  public shared: boolean = false;
  public title: Record<LocaleKey, string[]> = {};
  public desc: Record<LocaleKey, string[]> = {};
  public keywords: Record<LocaleKey, string[]> = {};
  public images: Record<LocaleKey, Record<string, string[]>> = {};

  /**
   * Creates an instance of `TemplateMetaData`.
   * @param {Timestamp} timestamp The timestamp of the template meta data.
   */
  constructor(timestamp: Timestamp) {
    this.timestamp = timestamp;
  }
}

export const templateMetaDataConverter: FirestoreDataConverter<TemplateMetaData> = {
  toFirestore: (templateMetaData: TemplateMetaData) => {
    return {
      timestamp: templateMetaData.timestamp,
      shared: templateMetaData.shared,
      title: templateMetaData.title,
      desc: templateMetaData.desc,
      keywords: templateMetaData.keywords,
      images: templateMetaData.images,
    };
  },

  fromFirestore: (data: any) => {
    const templateMetaData = new TemplateMetaData(data.timestamp);
    templateMetaData.shared = data.shared;
    templateMetaData.title = data.title;
    templateMetaData.desc = data.desc;
    templateMetaData.keywords = data.keywords;
    templateMetaData.images = data.images;
    return templateMetaData;
  },
};
