import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

export class ProfileId {
    id: string;
    uid: string;
    timestamp: Timestamp = Timestamp.now();

    constructor(id: string, uid: string, timestamp: Timestamp = Timestamp.now()) {
        this.id = id;
        this.uid = uid;
        this.timestamp = timestamp;
    }
}

export const profileIdConverter = {
    toFirestore(profile: ProfileId): DocumentData {
        return {
            uid: profile.uid,
            timestamp: profile.timestamp,
        };
    },
    fromFirestore(snapshot: DocumentSnapshot): ProfileId {
        const data = snapshot.data();
        if (!data) {
            throw new Error("No data found for ProfileId");
        }
        return new ProfileId(
            snapshot.id,
            data.uid,
            data.timestamp
        );
    },
};

