import { Logger } from "./utils-logging";

export const LocalStorageKey = {
  banner: 'banner',
  isUsageAuthorized: 'isUsageAuthorized',
  signedInUserAccount: 'signedInUserAccount',
  userProfile: 'userProfile',
  templatesTabIndex: 'templatesTabIndex',
  notionAffiliateTypRead: 'notionAffiliateTypRead',
  discover: 'discover',
} as const;

type LocalStorageKey = keyof typeof LocalStorageKey;

const logger = new Logger('utils-local-storage');

/**
 * Retrieves the value for the given key from local storage
 * @param key The key to retrieve from local storage
 * @returns The value for the given key, or null if not found
 */
export const getLocalStorage = <T>(key: LocalStorageKey, postfix?: string | undefined): T | null => {
  const actualKey = postfix ? `${LocalStorageKey[key]}_${postfix}` : LocalStorageKey[key];
  const value = localStorage.getItem(actualKey);
  if (value) {
    try {
      return JSON.parse(value) as T;
    } catch {
      logger.logError(`Failed to parse ${key} from local storage.`);
      return null;
    }
  }
  return null;
};

/**
 * Retrieves the value for the given key from local storage
 * @param key The key to retrieve from local storage
 * @param suffix The suffix to add to the key
 * @returns The value for the given key, or null if not found
 */
export const getLocalStorageWithSuffix = <T>(key: LocalStorageKey, suffix: any): T | null => {
  const value = localStorage.getItem(LocalStorageKey[key] + "_" + suffix);
  if (value) {
    try {
      return JSON.parse(value) as T;
    } catch {
      logger.logError(`Failed to parse ${key} from local storage.`);
      return null;
    }
  }
  return null;
};

/**
 * Stores the value for the given key in local storage
 * @param key The key to store the value under
 * @param value The value to store
 */
export function setLocalStorage<T>(key: LocalStorageKey, value: T): void {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(LocalStorageKey[key], serializedValue);
  } catch {
    logger.logError(`Failed to set ${key} to ${value} in local storage.`);
  }
}

/**
 * Stores the value for the given key in local storage
 * @param key The key to store the value under
 * @param value The value to store
 */
export function setLocalStorageWithPostfix<T>(key: LocalStorageKey, postfix: string, value: T): void {
  try {
    const actualKey = `${LocalStorageKey[key]}_${postfix}`;
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(actualKey, serializedValue);
  } catch {
    logger.logError(`Failed to set ${key} to ${value} in local storage.`);
  }
}

/**
 * Stores the value for the given key in local storage
 * @param key The key to store the value under
 * @param suffix The suffix to add to the key
 * @param value The value to store
 */
export const setLocalStorageWithSuffix = <T>(key: LocalStorageKey, suffix: any, value: T): void => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(LocalStorageKey[key] + "_" + suffix, serializedValue);
  } catch {
    logger.logError(`Failed to set ${key} to ${value} in local storage.`);
  }
};

/**
 * Removes the value for the given key from local storage
 * @param key The key to remove from local storage
 */
export const removeLocalStorage = (key: LocalStorageKey): void => {
  localStorage.removeItem(key);
};
