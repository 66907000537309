import emailjs from 'emailjs-com';

/**
 * Sends an email using the SendGrid service
 * @param senderName the name of the sender. 
 * @param senderEmail the email of the sender.
 * @param subject the subject of the email.
 * @param message the message of the email.
 */
export async function sendEmail(senderName: string, senderEmail: string, subject: string, message: string): Promise<void> {
    const serviceId = 'service_4hdaclj';
    const templateId = 'template-website-contact';
    const userId = 'FtsWx2SmpLNVlAADu';

    try {
        await emailjs.send(serviceId, templateId, {
            from_name: senderName,
            from_email: senderEmail,
            to_name: 'Elcovia',
            subject: subject,
            message: message
        }, userId);

        console.log('Email sent successfully');
    } catch (error) {
        console.error('Error sending email: ', error);
        throw new Error('Failed to send email: ' + error);
    }
}
