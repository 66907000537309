import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { AspectRatio } from "../../../utils/constants";
import { classNames } from "../../../utils/utils-react";

export interface ImageItemOption {
  name: string;
  active?: boolean;
  icon: React.FC | null;
  onClick?: () => void;
}

interface ImageItemProps {
  ratio?: AspectRatio;
  url: string;
  interactive?: boolean;
  addClassName?: string;
  options?: ImageItemOption[];
  onClick?: () => void;
}

const ImageItemComponent: React.FC<ImageItemProps> = ({
  ratio,
  url,
  interactive,
  addClassName,
  options,
  onClick,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const dropdownOptions = () => {
    return (
      <Menu
        onMouseLeave={() => {
          setMenuOpen(false);
        }}
        as="div"
        className="relative text-left"
      >
        <Transition
          show={menuOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-3 top-3 z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options &&
                options.map((option) => (
                  <Menu.Item>
                    <a
                      href="#"
                      onClick={() => {
                        option.onClick && option.onClick();
                      }}
                      className={classNames(
                        option.active
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm"
                      )}
                    >
                      {option.icon && (
                        <div className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500">
                          <option.icon />
                        </div>
                      )}
                      {option.name}
                    </a>
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const className = `bg-indigo-500/${ratio} w-full aspect-[${ratio}] ${addClassName}`;

  const interactionClassName =
    interactive == true
      ? `transition-all ease-in-out duration-300 hover:drop-shadow-lg hover:scale-105`
      : ``;

  return (
    <>
      <div
        onClick={() => (options ? setMenuOpen(true) : onClick && onClick())}
        onMouseLeave={() => {
          setMenuOpen(false);
        }}
      >
        {options && dropdownOptions()}
        <div className="group relative bg-white divide-y divide-solid rounded-lg drop-shadow ">
          <div className={classNames(className, interactionClassName)}>
            <img
              src={url}
              alt={url}
              className="h-full w-full object-cover object-center rounded-lg"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageItemComponent;
