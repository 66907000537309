import { FooterSocialLinks } from "../../../utils/constants";
import FooterSocialComponent from "./FooterSocialComponent";

const FooterSocialsComponent = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex gap-x-4">
        {FooterSocialLinks.map((link) => (
          <FooterSocialComponent key={link.label} link={link} />
        ))}
      </div>
    </div>
  );
};

export default FooterSocialsComponent;
