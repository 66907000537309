import exp from "constants";
import { ExternalLinks } from "./constants";
import { Logger } from "./utils-logging";

/**
 * Argument keys used in path parameters.
 */
export const Args = {
    ID: 'id',
    CATEGORY: 'category',
    SORT: 'sort',
    TYPE: 'type',
    TIME: 'time',
    LANG: 'lang',
    LAYOUT: 'layout',
} as const;



export const URL_PARAM_DISCOVER_VALUE = 'elcovia';
export const URL_PARAM_DISCOVER = `${[Args.LAYOUT]}=${URL_PARAM_DISCOVER_VALUE}`;
export const ARGS_PARAM_DISCOVER: Record<string, string> = { [Args.LAYOUT]: URL_PARAM_DISCOVER_VALUE };

/**
 * Route paths used in the application.
 */
export const Destination = {

    // App routes

    APP_PUBLIC: '/*',
    APP_ACCOUNT: '/account/*',
    APP_ADMIN: '/admin/*',
    PURCHASE: '/purchase/*',

    // Page Routes

    INDEX: '',
    DISCOVER: '/',
    LOGIN: '/login',

    BETA: '/beta',
    BETA_TESTERS: '/beta-testers',

    TEMPLATES_LATEST: '/notion-templates-new',

    TEMPLATES: '/notion-templates',

    TEMPLATE_ID: `/notion-templates/:${Args.ID}`,
    TEMPLATE_ID_SHORT: `/t/:${Args.ID}`,
    TEMPLATE_PREVIEW_ID: `/notion-templates/preview/:${Args.ID}`,

    TEMPLATES_CATEGORIES: '/notion-templates-categories',

    PURCHASE_MARKETPLACE_TEMPLATE_REFERENCE_ID: `/marketplace/template/:${Args.ID}`,

    CREATORS_ALTERNATIVE: '/notion-creators',
    CREATORS: '/creators',
    USERS_ALTERNATIVE: '/notion-users',
    USERS: '/users',
    USER_ID: `/users/:${Args.ID}`,
    USER_ID_SHORT: `/u/:${Args.ID}`,
    USER_ID_SHORT_DEPRECIATED: `/:${Args.ID}`,

    FAQS_ELCOVIA: '/faqs-elcovia',
    FAQS_NOTION: '/faqs-notion',
    APPS: '/mobile-apps',
    ABOUT: '/about',
    CONTACT: '/contact',
    COOKIES: '/cookies',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',

    AFFILIATE_GUMROAD_GUIDE: '/affiliate-gumroad-guide',

    REL_ACCOUNT_DASHBOARD_START: '/',
    REL_ACCOUNT_DASHBOARD: '/dashboard',
    REL_ACCOUNT_PROFILE: '/profile',
    REL_ACCOUNT_TEMPLATES: '/templates',
    REL_ACCOUNT_TEMPLATES_ID: `/templates/:${Args.ID}`,
    REL_ACCOUNT_AUDIENCE: '/audience',
    REL_ACCOUNT_ANALYTICS: '/analytics',
    REL_ACCOUNT_TEMPLATE_PROMOTION_ID: `/promotion-template/:${Args.ID}`,
    REL_ACCOUNT_PROFILE_PROMOTION: `/promotion-profile`,
    REL_ACCOUNT_WISHLIST: '/wishlist',
    REL_ACCOUNT_LIBRARY: '/library',
    REL_ACCOUNT_SETTINGS: '/settings',

    ACCOUNT_DASHBOARD: '/account/dashboard',
    ACCOUNT_PROFILE: '/account/profile',
    ACCOUNT_TEMPLATES: '/account/templates',
    ACCOUNT_TEMPLATES_ID: `/account/templates/:${Args.ID}`,
    ACCOUNT_AUDIENCE: '/account/audience',
    ACCOUNT_ANALYTICS: '/account/analytics',
    ACCOUNT_TEMPLATE_PROMOTION_ID: `/account/promotion-template/:${Args.ID}`,
    ACCOUNT_PROFILE_PROMOTION: `/account/promotion-profile`,
    ACCOUNT_WISHLIST: '/account/wishlist',
    ACCOUNT_LIBRARY: '/account/library',
    ACCOUNT_SETTINGS: '/account/settings',

    REL_ADMIN_DASHBOARD: '/dashboard',
    REL_ADMIN_DASHBOARD_START: '/',
    REL_ADMIN_META_DATA: '/meta-data',
    REL_ADMIN_DATA_EXPORT: '/data-export',

    ADMIN_META_DATA: '/admin/meta-data',
    ADMIN_DATA_EXPORT: '/admin/data-export',

    // error routes

    UNKNOWN: '/*',

} as const;

/**
 * Replaces the path parameter with the specified ID.
 * @param path The original path containing the path parameter.
 * @param id The ID to be set in the path.
 * @returns The updated path with the ID.
 */
export function setPathId(path: string, id: string): string {
    const url = path.replace(`:${Args.ID}`, id);
    return url;
}

/**
 * Replaces the path parameter with the specified ID and appends the specified query parameters.
 * @param path The original path containing the path parameter.
 * @param id The ID to be set in the path.
 * @param params An array of key-value pairs representing the query parameters.
 * @returns The updated path with the ID and appended query parameters.
 */
export function setPathIdAndAppend(path: string, id: string, params: Record<string, string> | undefined): string {
    const paramsString = params ? '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
    const url = path.replace(`:${Args.ID}`, id) + paramsString;
    return url;
}

/**
 * Sets the path and appends the specified query parameters.
 * @param path The original path.
 * @param params An array of key-value pairs representing the query parameters.
 * @returns The updated path with the appended query parameters.
 */
export function setPathAndAppend(path: string, params: Record<string, string> | undefined): string {
    const paramsString = params ? '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : '';
    const url = path + paramsString;
    return url;
}

/**
 * Replaces the path parameter with the specified ID. Used for public website links.
 * @param path The original path containing the path parameter.
 * @param id The ID to be set in the path.
 * @returns The updated path with the ID.
 */
export function setWebsitePathId(path: string, id: string): string {
    const url = ExternalLinks.ELCOVIA.concat(path.replace(`:${Args.ID}`, id));
    return url;
}

/**
 * Appends the specified string to the end of the path.
 * @param path The original path.
 * @param append The string to be appended.
 * @returns The updated path with the appended string.
 */
export function appendToPath(path: string, append: string): string {
    return path + '?' + append;
}

/**
 * Appends the specified query parameters to the path.
 * @param path The original path.
 * @param params An array of key-value pairs representing the query parameters.
 * @returns The updated path with the appended query parameters.
 */
export function appendParamsToPath(path: string, params: Record<string, string>[]): string {
    const url = path + '?' + params.map(param => `${param.key}=${param.value}`).join('&');
    return url;
}
