class PaymentPlatform {
    /**
     * Constructs a PaymentPlatform instance.
     * @param name The name of the platform.
     * @param pattern The regular expression pattern that validates the URL entered.
     * @param patternAffiliate The regular expression pattern that validates the affiliate URL entered.
     */
    constructor(public name: string,
        public pattern: RegExp[],
        public patternAffiliate?: RegExp[]) { }

    /**
     * Gumroad platform.
     * URL format: https://<company-name>.gumroad.com/l/<product-id>
     */
    static GUMROAD = new PaymentPlatform(
        "Gumroad",
        [/https?:\/\/[a-zA-Z0-9-]+\.gumroad\.com\/l\/\w+/],
        [/https?:\/\/gumroad\.com\/a\/[a-zA-Z0-9-]+\/\w+/],
    );

    /**
     * Payhip platform.
     * URL format: https://payhip.com/<product-id>
     */
    static PAYHIP = new PaymentPlatform(
        "Payhip",
        [/https?:\/\/payhip\.com\/\w+/]
    );

    /**
     * SendOwl platform.
     * URL format: https://<company-name>.sendowl.com/products/<product-id>
     */
    static SENDOWL = new PaymentPlatform(
        "SendOwl",
        [/https?:\/\/[a-zA-Z0-9-]+\.sendowl\.com\/products\/\w+/]
    );

    /**
     * Podia platform.
     * URL format: https://<company-name>.podia.com/<product-id>
     */
    static PODIA = new PaymentPlatform(
        "Podia",
        [/https?:\/\/[a-zA-Z0-9-]+\.podia\.com\/\w+/]
    );

    static LEMON_SQUEEZY = new PaymentPlatform(
        "Lemon Squeezy",
        [/https?:\/\/[a-zA-Z0-9-]+\.lemonsqueezy\.com\/\w+(\/checkout\?cart=[\w-]+)?/],
    );

    static ETSY = new PaymentPlatform(
        "Etsy",
        [/https?:\/\/[a-zA-Z0-9-]+\.etsy\.com\/\w+/]
    );
}

/**
 * The list of platforms.
 */
export const supportedPaymentPlatforms = [
    PaymentPlatform.GUMROAD,
    PaymentPlatform.PAYHIP,
    PaymentPlatform.LEMON_SQUEEZY,
    PaymentPlatform.ETSY
    // PaymentPlatform.SENDOWL,
    // PaymentPlatform.PODIA,
];

/**
 * Returns the names of the platforms.
 */
export function getSupportedPaymentPlatformNames(): string {
    return supportedPaymentPlatforms.map((platform) => platform.name).join(", ");
}
