import React from "react";

interface Props {
  baseClassName?: string;
  defaultClassName?: string;
  title: string;
  desc?: string;
}

const HeaderSectionsItemComponent: React.FC<Props> = ({
  baseClassName,
  defaultClassName,
  title,
  desc,
}) => {
  return (
    <h5
      className={
        baseClassName +
        " border-b border-gray-100 pb-6" +
        (!defaultClassName ? " text-xl font-bold dark:text-white" : "")
      }
    >
      {title}
    </h5>
  );
};

export default HeaderSectionsItemComponent;
