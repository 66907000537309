import { DocumentData, Timestamp } from "firebase/firestore";

/**
 * Represents a customer of a creator profile.
 * @property {string} customerId The customer ID.
 * @property {string} productId The product ID.
 * @property {number} productPrice The product price.
 * @property {Record<string, string>} productName The product name.
 * @property {Timestamp} timestamp The timestamp of when the customer was created.
 *
 */
export class CustomerConsumption {
  customerId = "";
  productId = "";
  productPrice = 0;
  productName: Record<string, string> = {};
  timestamp: Timestamp = Timestamp.now();

  /**
   * Creates a new Customer instance.
 * @property {string} customerId The customer ID.
 * @property {string} productId The product ID.
 * @property {number} productPrice The product price.
 * @property {Record<string, string>} productName The product name.
 * @property {Timestamp} timestamp The timestamp of when the customer was created.
   */
  constructor(customerId: string, productId: string, productPrice: number, productName: Record<string, string>, timestamp: Timestamp = Timestamp.now()) {
    this.customerId = customerId;
    this.productId = productId;
    this.productPrice = productPrice;
    this.productName = productName;
    this.timestamp = timestamp;
  }
}

/**
 * Converter for Profile class.
 */
export const customerConsumptionConverter = {
  /**
   * Converts a Customer instance to Firestore data.
   * @param {Customer}  customer The Customer instance to convert.
   * @return {DocumentSnapshot} The converted Firestore data.
   */
  toFirestore(customer: CustomerConsumption): DocumentData {
    return {
      customerId: customer.customerId,
      productId: customer.productId,
      productPrice: customer.productPrice,
      productName: customer.productName,
      timestamp: customer.timestamp,
    };
  },

  /**
   * Converts Firestore data to a Profile instance.
   * @param {any} data The Firestore snapshot containing the data.
   * @return {Customer} The converted Customer instance.
   */
  fromFirestore(data: any): CustomerConsumption {
    const customer = new CustomerConsumption(data.customerId, data.productId, data.productPrice, data.productName, data.timestamp);
    customer.customerId = data.customerId;
    return customer;
  },
};
