import { XMarkIcon } from "@heroicons/react/20/solid";
import { random } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";

import { AffiliateLinks } from "../../../utils/constants";
import { Destination } from "../../../utils/constants-navigation";
import {
  getLocalStorageWithSuffix,
  LocalStorageKey,
  setLocalStorageWithSuffix,
} from "../../../utils/utils-local-storage";
import { Logger } from "../../../utils/utils-logging";

const logger = new Logger("NotionAffiliateBanner");

type NotionAffiliateBanner = {
  id: number;
  color: string;
  icon: string;
  title: string;
  message: string;
  href: string;
  chance: number;
};

const notionProductMessages = [
  "The connected workspace for your docs, projects, and knowledge.",
  "Your docs, projects, and knowledge. Together.",
];

const notionAiProductMessages = [
  "Access the limitless power of AI, right inside Notion. Work faster. Write better. Think bigger.",
  "AI-powered tools to help you think and write better.",
  "Think bigger. Conquer writer's block. Let Notion AI handle the first draft.",
  "Focus on what‘s important. Leave the rest to AI.",
];

const notionProjectsProductMessages = [
  "Notion Projects is the easiest way to plan, collaborate, and track projects.",
  "Powerful project management, without the chaos.",
  "The easiest way to plan, collaborate, and track projects.",
];

const notionAffiliates: NotionAffiliateBanner[] = [
  {
    id: 10,
    color: "bg-gray-900",
    icon: `${process.env.PUBLIC_URL}/notion-wordmark-mono-white.png`,
    title: "Notion",
    message: notionProductMessages[random(0, notionProductMessages.length - 1)],
    href: AffiliateLinks.NOTION,
    chance: 0.7,
  },
  {
    id: 20,
    color: "bg-[#8f38d1]",
    icon: `${process.env.PUBLIC_URL}/notion-wordmark-mono-white.png`,
    title: "Notion AI",
    message:
      notionAiProductMessages[random(0, notionAiProductMessages.length - 1)],
    href: AffiliateLinks.NOTION_AI,
    chance: 0.3,
  },
  {
    id: 30,
    color: "bg-[#3b83cb]",
    icon: `${process.env.PUBLIC_URL}/notion-wordmark-mono-white.png`,
    title: "Notion Projects",
    message:
      notionProjectsProductMessages[
        random(0, notionProjectsProductMessages.length - 1)
      ],
    href: AffiliateLinks.NOTION_PROJECTS,
    chance: 0.1,
  },
];

export default function NotionAffiliateBanner() {
  const location = useLocation();
  const [notionAffiliateBanner, setNotionAffiliateBanner] =
    React.useState<NotionAffiliateBanner | null>(getUnreadRandomBanner());

  const bannerRef = React.useRef<HTMLDivElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!notionAffiliateBanner) return;
  }, []);

  React.useEffect(() => {
    setNotionAffiliateBanner(getUnreadRandomBanner());

    if (!notionAffiliateBanner) return;

    if (!document.addEventListener) return;
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location, location.pathname]);

  /**
   * Check if the banner should be visible.
   * @returns True if the banner should be visible.
   */
  function isBannerInLocationVisible() {
    logger.log("location.pathname", location.pathname);
    if (
      location.pathname == Destination.INDEX ||
      location.pathname == Destination.DISCOVER ||
      location.pathname == Destination.TEMPLATES ||
      location.pathname == Destination.USERS
    ) {
      logger.log("isBannerInLocationVisible", true);
      return true;
    } else {
      logger.log("isBannerInLocationVisible", false);
      return false;
    }
  }

  /**
   * Get a random banner that has not been read.
   * @returns {NotionAffiliateBanner} - A random banner that has not been read.
   */
  function getUnreadRandomBanner(): NotionAffiliateBanner | null {
    if (!isBannerInLocationVisible()) return null;
    const totalRandomValue = Math.random();
    if (totalRandomValue > 0.1) return null;

    // Reset all banners to unread.
    // notionAffiliates.forEach((banner) => {
    //   setLocalStorageWithSuffix(
    //     LocalStorageKey.notionAffiliateTypRead,
    //     banner.id,
    //     false
    //   );
    // });

    const unreadBanners = notionAffiliates.filter(
      (banner) =>
        !getLocalStorageWithSuffix(
          LocalStorageKey.notionAffiliateTypRead,
          banner.id
        )
    );
    console.log(unreadBanners);
    if (unreadBanners.length === 0) return null;

    const totalChance = unreadBanners.reduce(
      (sum, banner) => sum + banner.chance,
      0
    );
    let randomValue = Math.random() * totalChance;
    for (const banner of unreadBanners) {
      randomValue -= banner.chance;
      if (randomValue <= 0) {
        return banner;
      }
    }
    return unreadBanners[0];
  }

  /**
   * Mark the banner as read. This will prevent the banner from showing again.
   */
  function onMarkAsRead(read: boolean) {
    if (!notionAffiliateBanner) return;
    logger.log("onMarkAsRead", notionAffiliateBanner.id);
    setLocalStorageWithSuffix(
      LocalStorageKey.notionAffiliateTypRead,
      notionAffiliateBanner.id,
      read
    );
    setNotionAffiliateBanner(null);
  }

  /**
   * Handle the click outside of the banner.
   * @param event - The click event.
   * @returns {void}
   */
  function handleClickOutside(event: { target: any }): void {
    logger.log("handleClickOutside", event.target);
    if (!notionAffiliateBanner) return;
    if (!bannerRef.current) return;
    if (!divRef.current) return;
    if (!bannerRef.current.contains(event.target)) return;

    const targetElement = event.target;
    const divElement = divRef.current;

    // If the user clicks on the banner navigate to the link.
    if (!divElement.contains(targetElement)) {
      navigateToLink(notionAffiliateBanner);
    }
    // Mark the banner as read. This will prevent the banner from showing again.
    onMarkAsRead(true);
  }

  /**
   * Navigate to the link of the banner.
   * @param banner - The banner to navigate to.
   */
  function navigateToLink(banner: NotionAffiliateBanner) {
    window.open(banner.href, "_blank");
  }

  if (!notionAffiliateBanner) return null;
  return (
    <>
      <div
        ref={bannerRef}
        className={`cursor-pointer flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 ${notionAffiliateBanner.color}`}
      >
        <p className="flex items-center text-xs lg:text-sm leading-6 text-white">
          <img
            src={notionAffiliateBanner.icon}
            alt={notionAffiliateBanner.title}
            className="mr-3 lg:mr-12 h-5"
          />
          <strong className="font-semibold hidden lg:block">
            {notionAffiliateBanner.title}
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-3 inline h-0.5 w-0.5 fill-current hidden lg:block"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          <span className="line-clamp-1">{notionAffiliateBanner.message}</span>
          <span className="ml-4" aria-hidden="true">
            &rarr;
          </span>
        </p>
        <div ref={divRef} className="flex flex-1 justify-end z-10">
          <button
            onClick={() => onMarkAsRead(true)}
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
