
/**
 * A convenience function for joining class names together.
 * @param classes The class names to join.
 */
export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

/**
 * Checks if a record (an object with string keys) is empty.
 * @param obj - The record to check.
 * @returns `true` if the record is empty, `false` otherwise.
 */
export function isRecordEmpty(obj: Record<string, any>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}