/**
 * Copy text to clipboard
 * @param text Text to copy
 * @returns Promise<void>
 */
export const copyToClipboard = async (text: string): Promise<void> => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
}