import { AnyAction, Reducer } from 'redux';

import { Profile } from '../models/Profile';
import { UserActionTypes } from "./UserActions";

export interface UserState {
  isSignedIn: boolean;
  profile: Profile | null;
  likes: string[] | null;
}

const initialState: UserState = {
  isSignedIn: false,
  profile: null,
  likes: null,
};

export const userReducer: Reducer<UserState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.SET_SIGNED_IN:
      return { ...state, isSignedIn: action.payload };
    case UserActionTypes.USER_PROFILE:
      return { ...state, profile: action.payload };
    case UserActionTypes.USER_LIKES:
      return { ...state, likes: action.payload };
    default:
      return state;
  }
};