import { doc, collection, getDoc, Firestore, DocumentData } from "firebase/firestore";

/**
 * Represents the secret data associated with a user.
 * @property {string} stripeAccountId The user's Stripe account ID.
 * @property {boolean} payoutsEnabled Whether the user's Stripe account has payouts enabled.
 * @property {boolean} detailsSubmitted Whether the user's Stripe account has submitted details.
 * @property {boolean} chargesEnabled Whether the user's Stripe account has charges enabled.
 * @property {boolean} deauthorized Whether the user's Stripe account has been deauthorized.
 */
export class UserSecret {
    public stripeAccountId: string;
    public payoutsEnabled: boolean;
    public detailsSubmitted: boolean;
    public chargesEnabled: boolean;
    public deauthorized: boolean;
    public valid: boolean;

    /**
     * Represents the secret data associated with a user.
     * @param {string} stripeAccountId The user's Stripe account ID.
     * @param {boolean} payoutsEnabled Whether the user's Stripe account has payouts enabled.
     * @param {boolean} detailsSubmitted Whether the user's Stripe account has submitted details.
     * @param {boolean} chargesEnabled Whether the user's Stripe account has charges enabled.
     * @param {boolean} deauthorized Whether the user's Stripe account has been deauthorized.
     */
    constructor(
        stripeAccountId: string,
        payoutsEnabled: boolean,
        detailsSubmitted: boolean,
        chargesEnabled: boolean,
        deauthorized: boolean,
    ) {
        this.stripeAccountId = stripeAccountId;
        this.payoutsEnabled = payoutsEnabled;
        this.detailsSubmitted = detailsSubmitted;
        this.chargesEnabled = chargesEnabled;
        this.deauthorized = deauthorized;
        this.valid = payoutsEnabled && detailsSubmitted && chargesEnabled && !deauthorized;
    }
}

/**
 * Converts a UserSecret to a Firestore document.
 * @param {UserSecret} userSecret The UserSecret to convert.
 * @return {DocumentData} The Firestore document.
 */
export const userSecretConverter = {
    toFirestore(userSecret: UserSecret): DocumentData {
        return {
            stripeAccountId: userSecret.stripeAccountId,
            payoutsEnabled: userSecret.payoutsEnabled,
            detailsSubmitted: userSecret.detailsSubmitted,
            chargesEnabled: userSecret.chargesEnabled,
            deauthorized: userSecret.deauthorized,
            valid: userSecret.valid,
        };
    },
    fromFirestore(data: DocumentData): UserSecret {
        return new UserSecret(
            data.stripeAccountId,
            data.payoutsEnabled,
            data.detailsSubmitted,
            data.chargesEnabled,
            data.deauthorized,
        );
    },
};