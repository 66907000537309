import React from "react";

export const TermsAndConditionsPage = () => {
  return (
    <>
      <div className="mx-auto max-w-4xl">
        <h1 className="text-5xl font-bold">Terms of Service</h1>
        <p className="font-normal text-sm text-gray-900 leading-6 mt-8 mb-4 uppercase">
          Please read this Terms of Service agreement (the “Terms of Service”)
          carefully. This Terms of Service, applies to (a) the website made
          available by Elcovia, Inc. (“Elcovia,” “we,” or “us”) available at
          www.elcovia.com (the “Website”), (b) Elcovia’s mobile application(s)
          (each, an “Application” and collectively, with the Website, the
          “Platform”), and (c) the products, services, features, technologies,
          and/or functionalities provided by Elcovia via the Platform
          (collectively, with the Platform, the “Services”).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4 uppercase">
          By accessing or using our services in any way, by clicking on the "I
          accept" button, completing the registration process, making inventory
          available on the services and/or browsing the website or downloading
          our application, you represent that: (1) You have read, understand,
          and agree to be bound by the terms of service, (2) You are of legal
          age to form a binding contract with Elcovia, and (3) You have the
          authority to enter into the terms of service personally or on behalf
          of the entity you have named as the user and to bind that entity to
          the terms of service. The term "you" refers to you as an individual
          and any legal entity that you have named as a user at the time of
          registration for the services. If you do not agree to be bound by
          these terms of service, you may not access or use any of the services.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4 uppercase">
          The terms of service include: (1) Your agreement that Elcovia has no
          liability regarding the services (Section 1.3 - Elcovia Only Provides
          a Venue); (2) Your agreement that the services are provided "as is"
          and without warranty (Section 17 - Disclaimer of Warranties); (3) Your
          consent to release Elcovia from liability (Section 15 - Release); and
          (4) Your agreement to indemnify Elcovia for your use of, or inability
          to use, the services (Section 16 - Indemnification).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4 uppercase">
          Be aware that Section 22 (Arbitration Agreement) contains provisions
          governing how to resolve disputes between you and Elcovia. Among other
          things, Section 22 (Arbitration Agreement) includes an agreement to
          arbitrate which requires, with limited exceptions, that all disputes
          between you and us shall be resolved by binding and final arbitration.
          Section 22 (Arbitration Agreement) also contains a class action and
          jury trial waiver. Please read Section 22 (Arbitration Agreement)
          carefully.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4 uppercase">
          Unless you opt out of the agreement to arbitrate within 30 days: (1)
          you will only be permitted to pursue disputes or claims and seek
          relief against us on an individual basis, not as a plaintiff or class
          member in any class or representative action or proceeding, and you
          waive your right to participate in a class action lawsuit or
          class-wide arbitration; and (2) you are waiving your right to pursue
          disputes or claims and seek relief in a court of law and to have a
          jury trial. Any dispute, claim, or request for relief relating in any
          way to your use of the site will be governed and interpreted by and
          under the laws of the state of California, consistent with the Federal
          Arbitration Act, without giving effect to any principles that provide
          for the application of the law of any other jurisdiction. The United
          Nations Convention on Contracts for the International Sale of Goods is
          expressly excluded from this agreement.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4 uppercase">
          Be aware that Section 4.4 (Elcovia Communications) of this agreement,
          below, contains your consent to receive communications from us,
          including via e-mail, text message, calls and push notification.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Your use of, and participation in, certain Service may be subject to
          additional terms (“Supplemental Terms”) and such Supplemental Terms
          will either be listed in the Terms of Service or will be presented to
          you for your acceptance when you sign up to use the supplemental
          Services. If the Terms of Service are inconsistent with the
          Supplemental Terms, the Supplemental Terms shall control with respect
          to such Services. The Terms of Service and any applicable Supplemental
          Terms are collectively referred to herein as the “Agreement.”
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          1. Overview of Our Services
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">1.1 Elcovia Supplier:</span> The
          Elcovia Supplier service provides tools that are designed to process a
          third-party purchaser’s (“Buyer”) payment for certain digital products
          (“Digital Products”) and/or physical products (“Physical Products”,
          and together with Digital Products, “Products”) on a third-party
          seller’s (“Seller”) owned or controlled website(s) (“Seller Property”
          or “Seller Properties”) and facilitate the delivery of Digital
          Products to Buyers.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <span className="font-bold mr-1">1.2 Elcovia Discover:</span>Elcovia
          Discover is a sub-domain of the Platform that provides a searchable
          marketplace of all Products being offered for sale by sellers. The
          Elcovia Discover marketplace allows users that have an account with
          Elcovia to sell and/or buy Products that Elcovia deems eligible for
          sale through Elcovia Discover. To qualify for inclusion in Elcovia
          Discover, Products must meet product eligibility requirements, and
          Elcovia has the sole discretion to determine and change from time to
          time the product categories and products that are eligible for sale
          through Elcovia Discover. As a marketplace, we do not own or sell the
          Products listed on Elcovia Discover and Elcovia is not a party to any
          transaction or other interaction between users through Elcovia
          Discover. Accordingly, any contract for the sale of Products through
          Elcovia Discover is entered into directly between the Seller and the
          Buyer. Elcovia facilitates these transactions through hosting the
          Services, displaying pricing, listing Products, and processing
          payments as described in Section 8 (Additional Terms for Sellers). For
          clarity, Elcovia will have no responsibility or obligation with
          respect to any transactions that are not conducted through Elcovia
          Discover.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <span className="font-bold mr-1">1.3 Elcovia Templates:</span> Elcovia
          Templates is a dedicated section within the Platform designed to
          showcase a diverse collection of template content. It is part of
          Elcovia Discover. Serving as a creative hub, users can explore and
          access various templates for different purposes. Elcovia Templates
          offers users the opportunity to discover and utilize pre-designed
          Notion Templates to enhance their creative projects. The availability
          and selection of templates may vary, and Elcovia reserves the right to
          curate and update the template offerings. Please note that Elcovia's
          involvement is limited to providing the platform for template access
          and does not involve ownership or sale of the templates themselves.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <span className="font-bold mr-1">1.4 Elcovia Creators:</span> Elcovia
          Creators is a dedicated space within the Platform designed to
          celebrate and promote the talents of content creators. It is part of
          Elcovia Discover. This platform offers creators an avenue to showcase
          their profile. While Elcovia facilitates the exposure of creators and
          their work, it's important to note that interactions and transactions
          between creators and their audience are conducted independently.
          Elcovia does not hold a direct role in these interactions, focusing
          solely on empowering creators to shine.
        </p>

        {/* <p className="font-normal text-sm text-gray-900 leading-6">
          <span className="font-bold mr-1">1.3 Elcovia Affiliate Program:</span>
          A Seller may voluntarily participate in the Elcovia Affiliate Program,
          which allows the Seller to designate one (1) or more eligible users
          who is bound by the terms and conditions of the Elcovia Affiliate
          Program as an affiliate (each, an “Affiliate”). An Affiliate is
          permitted to promote the applicable Seller’s Digital Product(s) by
          publishing Links (as defined below) on such Affiliate’s website(s) or
          otherwise sharing the Links with prospective Buyers, and such
          Affiliate will earn financial compensation from the applicable Seller
          (each, a “Payout”) upon the completion of a sale of the applicable
          Seller’s Digital Product(s) that originated from the Links (each such
          sale, an "Affiliate Transaction"), subject to the terms and conditions
          of the Elcovia Affiliate Program. “Links” means embedded graphics,
          icons, text, or HTML code provided by Elcovia that contains a unique
          hypertext pointed to a Universal Resource Locator (URL) address to the
          Seller’s Digital Product(s) that allows Elcovia to identify the
          Affiliate as the source of an Affiliate Transaction. A "Sourced
          Webpage" means the Website, the Affiliate's website(s), or third-party
          websites on which the Links are published. For each completed
          Affiliate Transaction, the Affiliate will receive a fee equal to the
          Retail Price (defined below) of the applicable Digital Product
          multiplied by an affiliate percentage (the "Payout Rate") determined
          by the Seller for such Digital Product at the moment the Affiliate
          Transaction is completed (such resulting amount, the "Affiliate Fee").
          By way of example, if a Digital Product is sold at a Retail Price of
          $10 through a Transaction and the Payout Rate is set to ten percent
          (10%), the Affiliate Fee would be equal to $10 multiplied by 10%, or
          $1.
        </p> */}
        <h2 className="text-xl font-bold mt-8 mb-4">
          2. Elcovia only provides a venue
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          While we may, in our discretion, help facilitate the resolution of
          disputes, Elcovia has no control over and does not guarantee the
          existence, quality, timing, condition, safety or legality of Products
          offered through the Platform; the truth or accuracy of listings, posts
          or any other content or information provided by any Seller or other
          user through the Platform; any aspect whatsoever of any ratings
          provided by users; the integrity, responsibility or any action of any
          Seller or Buyer; the ability of Sellers to sell Products; the ability
          of Buyers to pay for Products; that a Buyer and Seller will actually
          complete a transaction or return a Product; or that a Buyer will
          return a Product if a return has been authorized through Elcovia
          Discover or a Seller Property. We do not take part in the interaction
          between Buyers and Sellers.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          3. Interaction with other users
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          When interacting with other users you should exercise caution and
          common sense to protect your personal safety and property, just as you
          would when interacting with other persons whom you don’t know. You are
          solely responsible for your interactions with other users (including
          Buyers and/or Sellers) and any other parties with whom you interact;
          provided, however, that Elcovia reserves the right, but has no
          obligation, to provide support in the event of disputes between users.
          YOU AGREE THAT NEITHER ELCOVIA NOR ITS AFFILIATES OR LICENSORS ARE
          RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF
          THE SERVICES, AND THAT ELCOVIA MAKES NO REPRESENTATION WITH RESPECT TO
          INTERACTIONS BETWEEN USERS. ELCOVIA AND ITS AFFILIATES AND LICENSORS
          WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN
          CONNECTION WITH YOUR USE OF THE SERVICES.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">4. Use of the services</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          The Services, and the information and content available on the
          Services are protected by copyright and other intellectual property
          rights laws throughout the world. Unless otherwise specified by
          Elcovia in a separate license, your right to use any and all of the
          Services is subject to the Agreement.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">4.1 Website License:</span>Subject to
          your compliance with the Agreement, Elcovia grants you a limited,
          non-exclusive, non-transferable, non-sublicensable, revocable license
          to access and use the features and functionality of the Platform
          available through the Website for your own personal or lawful business
          purposes.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6  mb-4">
          <span className="font-bold mr-1">4.2 Application License:</span>
          Subject to your compliance with the Agreement, Elcovia grants you a
          limited non-exclusive, non-transferable, non-sublicensable, revocable
          license to download, install and use a copy of the Platform on a
          mobile device or computer that you own or control and to run such copy
          of the Platform solely for your own personal or lawful business
          purposes and solely in accordance with the End User License Agreement
          for Applications.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">4.3 Updates:</span>You understand
          that the Services are evolving. You acknowledge and agree that Elcovia
          may update the Services with or without notifying you. You may need to
          update third-party software from time to time in order to use the
          Services.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">4.4 Elcovia Communications:</span>By
          entering into this Agreement, you agree to receive communications from
          Elcovia, including via e-mail, text message, calls, and push
          notifications. You agree that texts, calls or prerecorded messages may
          be generated by automatic telephone dialing systems. Communications
          from Elcovia may include operational communications concerning your
          User Account or use of the Services, updates concerning new and
          existing features on the Services, communications concerning
          promotions run by us or our third-party partners, and news concerning
          Elcovia and industry developments. Standard text messaging charges
          applied by your cell phone carrier will apply to text messages we
          send.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">5. Registration</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1"> 5.1 Registering Your Account:</span>
          In order to access certain features of the Services you may be
          required to become a Registered User and specifically open a Buyer or
          Seller account. For purposes of the Agreement, a “Registered User” is
          a user who has registered an account on the Services (“Account”), has
          a valid account on the social networking service (“SNS”) through which
          the user has connected to the Services (each such account, a
          “Third-Party Account”), or has an account with the provider of the
          Application for the user’s mobile device.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">5.2 Access Through a SNS:</span>If
          you access the Services through a SNS as part of the functionality of
          the Services, you may link your Account with Third-Party Accounts, by
          allowing Elcovia to access your Third-Party Account, as is permitted
          under the applicable terms and conditions that govern your use of each
          Third-Party Account. You represent that you are entitled to disclose
          your Third-Party Account login information to Elcovia and/or grant
          Elcovia access to your Third-Party Account (including, but not limited
          to, for use for the purposes described herein) without breach by you
          of any of the terms and conditions that govern your use of the
          applicable Third-Party Account and without obligating Elcovia to pay
          any fees or making Elcovia subject to any usage limitations imposed by
          such third-party service providers. By granting Elcovia access to any
          Third-Party Accounts, you understand that Elcovia may access, make
          available and store (if applicable) any information, data, text,
          software, music, sound, photographs, graphics, video, messages, tags
          and/or other materials accessible through the Services (collectively,
          “Content”) that you have provided to and stored in your Third-Party
          Account (“SNS Content”) so that it is available on and through the
          Services via your Account. Unless otherwise specified in the
          Agreement, all SNS Content shall be considered to be Your Content (as
          defined in Section 9.1 (Types of Content)) for all purposes of the
          Agreement. Depending on the Third-Party Accounts you choose and
          subject to the privacy settings that you have set in such Third-Party
          Accounts, personally identifiable information that you post to your
          Third-Party Accounts may be available on and through your Account on
          the Services. Please note that if a Third-Party Account or associated
          service becomes unavailable, or Elcovia’s access to such Third-Party
          Account is terminated by the third-party service provider, then SNS
          Content will no longer be available on and through the Services. You
          have the ability to disable the connection between your Account and
          your Third-Party Accounts at any time by accessing the “Settings”
          section of the Services. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
          THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
          ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY
          SERVICE PROVIDERS, AND ELCOVIA DISCLAIMS ANY LIABILITY FOR PERSONALLY
          IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH
          THIRD-PARTY SERVICE PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS
          THAT YOU HAVE SET IN SUCH THIRD-PARTY ACCOUNTS. Elcovia makes no
          effort to review any SNS Content for any purpose, including but not
          limited to, for accuracy, legality or noninfringement, and Elcovia is
          not responsible for any SNS Content.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">5.3 Registration Data:</span>
          In registering an account, you agree to (a) provide true, accurate,
          current and complete information about yourself as prompted by the
          registration form (the “Registration Data”); and (b) maintain and
          promptly update the Registration Data to keep it true, accurate,
          current and complete. You represent that you are (i) at least thirteen
          (13) years old; (ii) of legal age to form a binding contract; and
          (iii) not a person barred from using the Services under the laws of
          the United States, your place of residence or any other applicable
          jurisdiction. You are responsible for all activities that occur under
          your Account. You agree that you shall monitor your Account to
          restrict use by minors, and you will accept full responsibility for
          any unauthorized use of the Services by minors. You may not share your
          Account or password with anyone, and you agree to (y) notify Elcovia
          immediately of any unauthorized use of your password or any other
          breach of security; and (z) exit from your Account at the end of each
          session. If you provide any information that is untrue, inaccurate,
          not current or incomplete, or Elcovia has reasonable grounds to
          suspect that any information you provide is untrue, inaccurate, not
          current or incomplete, Elcovia has the right to suspend or terminate
          your Account and refuse any and all current or future use of the
          Services (or any portion thereof). You agree not to create an Account
          using a false identity or information, or on behalf of someone other
          than yourself. You agree that you shall not have more than one Account
          per platform or SNS at any given time. Elcovia reserves the right to
          remove or reclaim any usernames at any time and for any reason,
          including but not limited to, claims by a third party that a username
          violates the third party’s rights. You agree not to create an Account
          or use the Services if you have been previously removed by Elcovia, or
          if you have been previously banned from any of the Services.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            5.4 Necessary Equipment and Software:
          </span>
          You must provide all equipment and software necessary to connect to
          the Services, including but not limited to, a mobile device that is
          suitable to connect with and use the Services, in cases where the
          Services offer a mobile component. You are solely responsible for any
          fees, including Internet connection or mobile fees, that you incur
          when accessing the Services.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          6. Listing and purchasing products
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">6.1 Risks; No Warranty:</span>You
          assume certain risks in using a marketplace service such as Elcovia
          Discover. Buyers contract directly with Sellers for the purchase of
          Products through Elcovia Discover and Elcovia Supplier. Elcovia does
          not make any representations or warranties regarding Products offered
          or sold through Elcovia Discover or any Seller Property. Elcovia is
          not an auctioneer, a Seller, or a carrier. You as a Buyer and/or
          Seller acknowledge and agree that you bear all of the risks in selling
          or buying Products through Elcovia Discover.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            6.2 Listing and Delivery of Digital Products :
          </span>
          With respect to a Digital Product, Seller may upload a digital file in
          a format approved by Elcovia (including, but not limited to, .mp3,
          .pdf, .png, .jpeg files) to Elcovia Discover and sell the Digital
          Product at a retail price determined by Seller in its sole and
          absolute discretion (the “Retail Price”). Upon a Buyer’s purchase of a
          Digital Product on Elcovia Discover, as described in Section 6.4
          (Purchasing Products), or through a Seller Property which leverages
          the Elcovia Supplier services, Elcovia will promptly facilitate the
          payment from Buyer to Seller and facilitate the delivery of the
          Digital Product to Buyer.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4"></h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            6.3 Listing and Delivery of Physical Products :
          </span>
          With respect to Physical Products, Seller may upload a listing of a
          Physical Product to Elcovia Discover and sell the Physical Product at
          a Retail Price determined by Seller in its sole and absolute
          discretion. Upon a Buyer’s purchase of a Physical Product as described
          in Section 6.4 (Purchasing Products), Elcovia will promptly facilitate
          the payment from Buyer to Seller and collect the applicable Elcovia
          Fee (as defined below). Seller is solely responsible for the
          fulfillment and delivery of Physical Products to the Buyer in a timely
          manner. Elcovia does not ship, handle or take custody of Physical
          Products at any point.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">6.4 Purchasing Products</span>
          Buyers contract directly with Sellers for the purchase of Products
          through the Platform. A Buyer may purchase Products through the
          Platform with or without an Account. Having an Account allows a Buyer
          to access the Buyer’s purchase history and the purchased Digital
          Products through the Buyer’s Elcovia Library anytime. To purchase a
          Product, a Buyer must complete the checkout process and provide an
          authorized payment method. Except as set forth below, all purchases
          through the Platform are final. All payments by Buyers for purchases
          through the Platform must be made through the Platform using a payment
          method that Elcovia in its sole discretion makes available through the
          Platform as further described in Section 8.3 (Third-Party Service
          Provider).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">6.5 Currency Conversion</span>
          If the Seller lists the Retail Price of a Product in a currency other
          than United States Dollars (USD), Elcovia will calculate a USD price
          based upon an exchange rate determined by Elcovia. Elcovia uses
          exchange rates obtained from http://openexchangerates.org/api. Elcovia
          cannot and does not guarantee that the exchange rate displayed
          reflects the most up to date rate due to the fluctuating nature of
          exchange rates. Accordingly, Elcovia recommends that you confirm
          current rates before engaging in any transactions on the Platform.
          Regardless of listed currency, all transactions, including for Digital
          Product, Physical Product, Seller Property, or Elcovia Discover will
          settle in USD.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1"> 6.6 Refund Policy</span>
          (a) Elcovia has no obligation to provide refunds or credits, but may
          grant them in circumstances where the Seller has committed fraud, or
          to correct any errors made by Elcovia or a Seller, or where the
          Product violates this Terms of Service, in each case in Elcovia’s sole
          discretion, or at the direction of a Seller in accordance with
          Seller’s refund policy published through the Platform. (b) It is the
          Buyer’s sole responsibility to appropriately handle, remove or destroy
          any Digital Product or Physical Product that may cause Buyer to: (i)
          incur additional liability, including, but not limited to, criminal
          and/or civil liability; or (ii) experience additional adverse effects,
          including, but not limited to, potential (c) Upon the approval of a
          refund pursuant to Section 6.6(a) or 6.6(a), the full amount of the
          authorized refund will be offset against funds pending settlement to
          Seller, including in connection with future sale proceeds. To the
          extent applicable, Elcovia will process the refund payment to Buyer on
          Seller’s behalf. (d) Please note that if you as a Buyer request a
          refund and also pursue a dispute resolution process for the same
          transaction with your payment method provider for the applicable
          purchase, we will decline your refund request. This will not affect
          the dispute resolution process with your payment method provider. You
          agree not to submit a refund request for any Product if you have
          already chosen to pursue a dispute resolution process with your
          payment method provider.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">7. Taxes</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            7.1 Important Tax Considerations :
          </span>
          This Section 7.1 highlights important taxation issues related to the
          Platform and Services.
          {/* It outlines the intended Indirect Tax treatment
          and refers to the Articles accessible at{" "}
          <a
            href="https://help.elcovia.com/article/121-sales-tax-on-elcovia"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.elcovia.com/article/121-sales-tax-on-elcovia
          </a>{" "} */}
          for further information on Elcovia's approach to administering and
          collecting Indirect Tax. Please note that this information is a
          general guide and not legal or tax advice. Tax treatment varies
          between jurisdictions, and you are responsible for tax administration,
          collection, reporting, and remittance. (a) Meaning of Indirect Tax:
          "Indirect Tax" includes sales, use, value added, goods and services
          tax, as well as import, customs, and similar taxes, in any
          jurisdiction, along with related penalties and interest. (b) Indirect
          Tax on Elcovia Supplier – General Position : Generally, the Seller is
          treated as the supplier for Indirect Tax purposes on Products sold via
          Elcovia Supplier. The Seller is responsible for tax administration,
          collection, reporting, and remittance. (c) Indirect Tax on Elcovia
          Supplier – Tax Facilitated Sales: In certain cases, Elcovia may
          provide tax collection services on behalf of Sellers, potentially
          being treated as the supplier for Indirect Tax purposes. Specific
          terms apply. (d) Indirect Tax on Elcovia Discover: Elcovia is
          generally treated as the supplier for Indirect Tax purposes on
          Products sold via Elcovia Discover. Elcovia is responsible for tax
          administration, collection, reporting, and remittance. (e) Indirect
          Tax on Elcovia Fees: Elcovia Fees may be subject to Indirect Tax.
          Elcovia or the Buyer may be responsible for tax administration,
          collection, reporting, and remittance, depending on circumstances. (f)
          Indirect Tax on Affiliate Fees Affiliate Fees may be subject to
          Indirect Tax, and Affiliates are responsible for tax administration,
          collection, reporting, and remittance. (g) Indirect Tax for Buyers:
          Buyers might be responsible for Indirect Taxes, especially in
          cross-border or specific circumstances. Buyer responsibilities vary.
          (h) Direct Tax: Sellers must disclose earnings to relevant tax
          authorities and ensure proper tax payment. Elcovia might disclose
          information to tax authorities as needed.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            7.2 Certain Terms Relating to Tax
          </span>
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          (a) Amounts Generally Exclusive of Indirect Tax: Prices and amounts
          payable to Elcovia are generally exclusive of Indirect Tax. Additional
          payment is required to cover such tax. (b) Payment of Tax by Elcovia:
          Elcovia may deduct Indirect Tax from Seller's payments and account for
          it to the tax authority. (c) Compliance with Tax Law: Sellers must
          comply with all relevant tax laws, ensuring accurate tax
          administration and display. (d) Reimbursement and Indemnification:
          Sellers must reimburse and indemnify Elcovia for Indirect Tax
          liabilities.(e) Tax Information: Sellers agree to provide necessary
          tax information to Elcovia and assist in compliance.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          8. Additional Payment Terms
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            {" "}
            8.1 Seller’s Payment of Elcovia Fees:
          </span>
          Elcovia does not assess or collect “listing” or “insertion” fees, but
          will charge and collect from Seller a Platform fee and/or a per
          transaction fee (“Elcovia Fee(s)”) for each sale made through Elcovia
          Discover or a Seller Property that uses Elcovia Supplier Services. The
          current Elcovia Fees can be viewed in Elcovia’s FAQ section and
          creator dasbhboard. We may change the Elcovia Fees from time to time
          by posting the changes on the Website. Any new Elcovia Fee(s) will
          apply to transactions only after the changes are posted to the
          Website. The Elcovia Fee(s) owed for each sale through Elcovia
          Discover or a Seller Property is automatically deducted from the
          purchase price paid by the Buyer, with the remainder (less any amounts
          in respect of taxes) settled to Seller.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.2 Buyer’s Payment for Products:
          </span>
          Buyer is obligated to make payment upon purchase of a Product by
          providing an eligible payment method at checkout. Buyer is responsible
          for all approved charges. Elcovia does not assess any fees of Buyers.
          Upon final purchase, Elcovia, through its third-party service
          providers, will charge the payment method specified by Buyer at
          checkout. All purchases made by Buyer are processed by Elcovia and its
          third-party services providers on behalf of Seller to facilitate the
          settlement of proceeds to Seller (less applicable fees and taxes).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.3 Third-Party Service Providers:
          </span>
          Elcovia currently uses Stripe, Inc. and its affiliates as third-party
          service providers for payment processing services (e.g., card
          acceptance, merchant settlement, and related services) (each, a
          “Third-Party Service Provider”). By buying or selling through Elcovia
          Discover or on any other portion of the Service, you (a) agree to be
          bound by, in the case of Stripe, Stripe’s Privacy Policy (currently
          accessible at https://stripe.com/us/privacy) and its Stripe Connected
          Account Agreement (currently accessible at
          https://stripe.com/legal/connect-account) (b) agree to provide only
          true, accurate, current and complete information about you and to
          update such information as necessary to maintain its truth and
          accuracy; and (c) and you hereby consent and authorize Elcovia, Stripe
          and PayPal to share any information and payment instructions you
          provide with one or more Third-Party Service Provider(s) to the
          minimum extent required to complete your transactions.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.4 Additional Identity Verification:
          </span>
          Elcovia reserves the right, but has no obligation, to request
          additional information from Buyers or Sellers to verify identity in
          order to safeguard the integrity of the Platform and reduce the risk
          of fraud, money laundering, terrorist financing, and the violation of
          trade sanctions. Information that Elcovia may request, or seek to
          confirm, may include full legal name, mailing address, phone number,
          date of birth, taxpayer identification number (e.g. Social Security
          Number), bank account information, and a form of government-issued
          identification.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.5 Seller’s Appointment of Elcovia as Limited Payments Agent:
          </span>
          By accessing the Platform and Services, each Seller hereby appoints
          Elcovia as its limited payments agent for the limited purpose of
          receiving, holding and facilitating settlement of payments to Seller
          for sales of Products through Elcovia Discover or a Seller Property,
          to the extent applicable. Elcovia, through its Third-Party Service
          Provider(s), will settle payments that are actually or constructively
          received by Elcovia to Seller, less any amounts owed to Elcovia,
          including the Elcovia Fees, other fees and obligations and amounts in
          respect of taxes, as set forth in these Terms of Service. Seller
          agrees that a payment actually or constructively received by Elcovia
          on behalf of Seller satisfies Buyer’s obligation to make payment to
          Seller, regardless of whether Elcovia or its Third-Party Service
          Provider actually settles such payment to Seller. If Elcovia does not
          settle any such payments as described in this Section 8 (Additional
          Payment Terms) or otherwise in these Terms of Service, Seller will
          have recourse against only Elcovia (or its Third-Party Service
          Provider, as applicable) and not Buyer. In accepting this appointment
          as Seller’s limited payments agent, Elcovia assumes no liability for
          any acts or omission by Seller, and Seller understands that the
          obligation of Elcovia and its Third-Party Service Provider(s) to
          settle funds to Seller is subject to and conditioned upon the Buyer’s
          actual payment and these Terms of Service.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.6 Seller’s Funds Pending Settlement:
          </span>
          For convenience, Sellers are able to obtain information regarding the
          proceeds of sales pending settlement through the dashboard made
          available in Seller’s Account. This information merely reflects the
          sales proceeds processed on Seller’s behalf and does not constitute a
          deposit or other obligation of Elcovia or its Third-Party Service
          Providers to Seller. Funds pending settlement to Seller will be held
          in an account with Elcovia’s Third-Party Service Provider(s) pending
          disbursement to Seller. Disbursement of funds to Seller may be delayed
          as described in Section 8.8 below. Seller agrees that it (i) is not
          entitled to any interest or other compensation associated with any
          funds pending settlement to Seller, (ii) has no right to direct any
          account holding such funds, and (iii) may not assign any interest in
          the account(s) held with or through Elcovia or its Third-Party Service
          Providers. Elcovia, through its Third-Party Service Provider(s),
          facilitates disbursement of funds to Seller to the account associated
          with the payout information designated by the Seller. If payment is
          made to Seller in error, or if Seller receives funds that Seller is
          not otherwise entitled to receive at the time of disbursement, Elcovia
          has the right to recoup such amounts from the Seller, including
          without limitation by initiating a debit or charge to any account
          provided by Seller in connection with the Services. Elcovia may also
          offset against funds pending settlement to Seller any sums due, or
          reasonably likely to become due, to Elcovia pursuant to these Terms of
          Service.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.7 Seller’s Obligations and Restrictions on Seller’s Activities
            through the Services:
          </span>{" "}
          In addition to other terms applicable to Sellers in this Agreement,
          when you as a Seller use the Services (including Elcovia Discover, or
          Elcovia Supplier), you acknowledge and agree that:
        </p>
        {/* List of Seller's obligations and restrictions */}
        <h2 className="text-xl font-bold mb-4"></h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            8.8 Suspension of Seller’s Account:
          </span>
          (a) Elcovia, in its sole discretion, may suspend or terminate Seller’s
          Account(s), or retain any or all funds pending settlement to Seller,
          if Elcovia suspects or has reason to believe and/or if a person
          otherwise claims that Seller has violated the law or breached any term
          of this Agreement. In addition to the foregoing, and all other rights
          and remedies available to Elcovia at law or in equity and
          notwithstanding anything in the Agreement to the contrary, in the
          event Seller breaches any term of this Agreement, or Seller’s Account
          becomes dormant and/or has a negative balance, Elcovia will have the
          right to immediately suspend or terminate Seller’s Account and
          Seller’s rights to access, use and/or otherwise participate in the
          Platform. Upon such termination, Seller agrees to immediately cease
          all use of the Services. Without limiting the foregoing, Elcovia shall
          have the right to immediately terminate Seller’s access and use of the
          Platform, or any portion thereof, in the event of any conduct that
          Elcovia, in its sole discretion, considers unacceptable. Following
          suspension or termination of Seller’s Account or retaining of funds
          pursuant to this Section, Elcovia will review Seller’s Account in a
          manner determined by Elcovia in its sole discretion. Seller agrees to
          cooperate with this review if asked. If the review concludes that
          there is a reasonable basis to believe misconduct has occurred, Seller
          agrees that Elcovia may retain funds in Seller’s Account as liquidated
          damages and/or for the benefit of Elcovia or third parties affected by
          the misconduct. Seller acknowledges and agrees that such liquidated
          damages: (i) are not a penalty, and (ii) are reasonable and not
          disproportionate to such presumed damages to Elcovia. (b) Elcovia may
          temporarily withhold any portion of funds pending settlement to Seller
          (as reflected in the Seller’s Account) if Elcovia, in its sole
          discretion, determines such action is necessary to secure payment for,
          performance of, and/or assurances regarding any liabilities,
          obligations, or indebtedness Seller may have incurred with Elcovia or
          any Buyer. If Seller experiences a refund rate in excess of 15%,
          Seller hereby authorizes us to hold in reserve an amount equal to 25%
          of Seller’s funds pending settlement for 90 days on a rolling basis to
          offset the potential cost of future refunds. If Seller experiences a
          refund rate in excess of 25%, Seller’s Account may be suspended,
          terminated, or otherwise subject to additional conditions or fees.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          9. Responsibility for content
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">9.1 Types of Content:</span> You
          acknowledge that all Content, including the Services, is the sole
          responsibility of the party from whom such Content originated. This
          means that you, and not Elcovia, are entirely responsible for all
          Content that you upload, post, e-mail, transmit or otherwise make
          available (“Make Available”) through the Services (“Your Content”),
          and that you and other users of the Services, and not Elcovia, are
          similarly responsible for all Content that you and they Make Available
          through the Services (“User Content”).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <span className="font-bold mr-1">9.2 Storage:</span> Unless expressly
          agreed to by Elcovia in writing elsewhere, Elcovia has no obligation
          to store any of Your Content that you Make Available on the Services.
          Elcovia has no responsibility or liability for the timeliness,
          deletion, mis-delivery or accuracy of any Content, including Your
          Content; the failure to store, transmit or receive transmission of
          Content; or the security, privacy, storage, or transmission of other
          communications originating with or involving use of the Services.
          Certain Services may enable you to specify the level at which such
          Services restrict access to Your Content. You are solely responsible
          for applying the appropriate level of access to Your Content. If you
          do not choose, the system may default to its most permissive setting.
          You agree that Elcovia retains the right to create reasonable limits
          on Elcovia’s use and storage of the Content, including Your Content,
          such as limits on file size, storage space, processing capacity, and
          similar limits described on the Services and as otherwise determined
          by Elcovia in its sole discretion.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">10. Owenership</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            10.1 Ownership of the Services:
          </span>
          Except with respect to Your Content and User Content, you agree that
          Elcovia and its suppliers own all rights, title and interest in the
          Services (including but not limited to, any computer code, themes,
          objects, characters, character names, stories, dialogue, concepts,
          artwork, animations, sounds, musical compositions, audiovisual
          effects, methods of operation, moral rights, documentation, and
          Elcovia software). You will not remove, alter or obscure any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying any the Services.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">10.2 Trademarks</span>
          Elcovia, the Elcovia logo, and all related graphics, logos, service
          marks and trade names used on or in connection with the Services or in
          connection with the Services are the trademarks of Elcovia and may not
          be used without permission in connection with your, or any
          third-party, products or services. Other trademarks, service marks and
          trade names that may appear on or in the Services are the property of
          their respective owners.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">10.3 Your Content:</span>
          Elcovia does not claim ownership of Your Content. However, when you as
          a Registered User post or publish Your Content on or in the Services,
          you represent that you own and/or have a royalty-free, perpetual,
          irrevocable, worldwide, non-exclusive right (including any moral
          rights) and license to use, license, reproduce, modify, adapt,
          publish, translate, create derivative works from, distribute, derive
          revenue or other remuneration from, and communicate to the public,
          perform and display Your Content (in whole or in part) worldwide
          and/or to incorporate it in other works in any form, media or
          technology now known or later developed, for the full term of any
          worldwide intellectual property right that may exist in Your Content.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">10.4 License to Your Content:</span>
          Subject to any applicable account settings that you select, you grant
          Elcovia a fully paid, royalty-free, perpetual, irrevocable, worldwide,
          royalty-free, non-exclusive and fully sublicensable right (including
          any moral rights) and license to use, license, distribute, reproduce,
          modify, adapt, publicly perform, and publicly display Your Content (in
          whole or in part) for the purposes of operating and providing the
          Services to you and to our other users. Please remember that other
          users may search for, see, use, modify and reproduce any of Your
          Content that you submit to any “public” area of the Services. You
          warrant that the holder of any worldwide intellectual property right,
          including moral rights, in Your Content, has completely and
          effectively waived all such rights and validly and irrevocably granted
          to you the right to grant the license stated above. You agree that
          you, not Elcovia, are responsible for all of Your Content that you
          Make Available on or in the Services. Any Content posted by you in
          your profile may not contain nudity, violence, sexually explicit, or
          offensive subject matter as determined by Elcovia in its sole
          discretion. You may not post or submit for print services a photograph
          of another person without that person’s permission.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">10.5 Username:</span>
          Notwithstanding anything contained herein to the contrary, by
          submitting Your Content to any comments, or any other area on the
          Services, you hereby expressly permit Elcovia to identify you by your
          username (which may be a pseudonym) as the contributor of Your Content
          in any publication in any form, media or technology now known or later
          developed in connection with Your Content.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">10.6 Feedback:</span>
          You agree that submission of any ideas, suggestions, documents, and/or
          proposals to Elcovia (“Feedback”) is at your own risk and that Elcovia
          has no obligations (including without limitation obligations of
          confidentiality) with respect to such Feedback. You represent and
          warrant that you have all rights necessary to submit the Feedback. You
          hereby grant to Elcovia a fully paid, royalty-free, perpetual,
          irrevocable, worldwide, non-exclusive, and fully sublicensable right
          and license to use, reproduce, perform, display, distribute, adapt,
          modify, re-format, create derivative works of, and otherwise
          commercially or non-commercially exploit in any manner, any and all
          Feedback, and to sublicense the foregoing rights, in connection with
          the operation and maintenance of the Services and/or Elcovia’s
          business.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          11. User conduct and certain restrictations
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            11.1 User Conduct and Certain Restrictions:
          </span>
          As a condition of use, you agree not to use the Services for any
          purpose that is prohibited by this Agreement or by applicable law. You
          shall not (and shall not permit any third party) to: (a) license,
          sell, rent, lease, transfer, assign, reproduce, distribute, host or
          otherwise commercially exploit the Services or any portion of the
          Services; (b) frame or utilize framing techniques to enclose any
          trademark, logo, or other Services (including images, text, page
          layout or form) of Elcovia; (c) use any metatags or other “hidden
          text” using Elcovia’s name or trademarks; (d) modify, translate,
          adapt, merge, make derivative works of, disassemble, decompile,
          reverse compile or reverse engineer any part of the Services except to
          the extent the foregoing restrictions are expressly prohibited by
          applicable law; (e) use any manual or automated software, devices or
          other processes (including but not limited to spiders, robots,
          scrapers, crawlers, avatars, data mining tools or the like) to
          “scrape” or download data from any web pages contained in the Services
          (except that we grant the operators of public search engines revocable
          permission to use spiders to copy materials from the Services for the
          sole purpose of and solely to the extent necessary for creating
          publicly available searchable indices of the materials, but not caches
          or archives of such materials); (f) remove or destroy any copyright
          notices or other proprietary markings contained on or in the Services.
          Further, you shall not (and shall not permit any third party) to
          either (a) take any action or (b) Make Available any Content on or
          through the Services that: (i) infringes any patent, trademark, trade
          secret, copyright, right of publicity or other right of any person or
          entity; (ii) is unlawful, threatening, abusive, harassing, misleading,
          false, defamatory, libelous, pornographic, deceptive, fraudulent,
          invasive of another’s privacy, tortious, obscene, offensive, profane
          or racially, ethnically, or otherwise discriminatory; (iii)
          constitutes unauthorized or unsolicited advertising, junk or bulk
          e-mail; (iv) involves commercial activities and/or sales, such as
          contests, sweepstakes, barter, advertising, or pyramid schemes without
          Elcovia’s prior written consent; (v) impersonates any person or
          entity, including any employee or representative of Elcovia; (vi)
          interferes with or attempt to interfere with the proper functioning of
          the Services or uses the Services in any way not expressly permitted
          by this Agreement; (vii) manipulates the price of any listed Product
          interferes with a user profile or Seller listings; (viii) transfers
          your account and username to another party without our consent; (ix)
          bypasses our robot exclusion hardware, interferes with the working of
          the Platform, or imposes an unreasonable or disproportionately large
          load on our infrastructure; (x) uses the Platform to collect, harvest,
          transmit, distribute or submit any information concerning any other
          person or entity, including without limitation photographs of others,
          personal contact information or credit card, debit or calling card or
          account numbers without their permission; (xi) takes any action that
          may undermine our feedback or ratings systems; (xii) breaches or
          circumvents any laws, third party rights or our systems, policies, or
          determinations of your account status; or (xiii) attempts to engage in
          or engages in, any potentially harmful acts that are directed against
          the Services, including but not limited to violating or attempting to
          violate any security features of the Services, using manual or
          automated software or other means to access, “scrape,” “crawl” or
          “spider” any pages contained in the Services, introducing viruses,
          worms, or similar harmful code into the Services, or interfering or
          attempting to interfere with use of the Services by any other user,
          host or network, including by means of overloading, “flooding,”
          “spamming,” “mail bombing,” or “crashing” the Services.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">12. No solicitation</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">12.1 No Solicitation:</span>
          The Platform may not be used to solicit for any other business,
          website or services. You may not solicit, advertise for, or contact in
          any form users for employment, contracting, or any other purpose not
          related to the Services facilitated through the Services. You may not
          use the Platform to collect usernames and/or email addresses of users
          by electronic or other means without the express prior written consent
          of Elcovia.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          13. Investigations, monitoring, & no obligation to pre-screen content
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">13.1 Monitoring; Content:</span>
          Elcovia may, but is not obligated to, investigate, monitor,
          pre-screen, remove, refuse, or review the Services and/or Content,
          including Your Content and User Content, at any time. By entering into
          the Agreement, you hereby provide your irrevocable consent to such
          monitoring. You acknowledge and agree that you have no expectation of
          privacy concerning the transmission of Your Content, including without
          limitation chat, text, or voice communications. In the event that
          Elcovia pre-screens, refuses or removes any Content, you acknowledge
          that Elcovia will do so for Elcovia’s benefit, not yours. Without
          limiting the foregoing, Elcovia reserves the right to: (a) remove or
          refuse to post any of your Content for any or no reason in our sole
          discretion; (b) take any action with respect to any of Your Content
          that we deem necessary or appropriate in our sole discretion,
          including if we believe that such Content violates this Agreement,
          infringes any intellectual property right or other right of any person
          or entity, threatens the personal safety of users of the Services or
          the public, or could create liability for Elcovia; (c) disclose your
          identity or other information about you to any third party who claims
          that material posted by you violates their rights, including their
          intellectual property rights or their right to privacy; (d) take
          appropriate legal action, including without limitation, referral to
          and cooperation with law enforcement and/or other applicable legal
          authorities, for any illegal or unauthorized use of the Services or if
          Elcovia otherwise believes that criminal activity has occurred; and/or
          (e) terminate or suspend your access to all or part of the Services
          for any or no reason, including without limitation, any violation of
          this Agreement. Upon determination of any possible violations by you
          of any provision of the Agreement, Elcovia, may, at its sole
          discretion immediately terminate your license to use the Services, or
          change, alter or remove Your Content, in whole or in part, without
          prior notice to you.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">13.2 Criminal Activity:</span>
          If Elcovia believes that criminal activity has occurred, Elcovia
          reserves the right to, except to the extent prohibited by applicable
          law, disclose any information or materials on or in the Services,
          including Your Content, in Elcovia’s possession in connection with
          your use of the Services, to (i) comply with applicable laws, legal
          process or governmental request, (ii) enforce the Agreement, (iii)
          respond to any claims that Your Content violates the rights of third
          parties, (iv) respond to your requests for customer service, or (v)
          protect the rights, property or personal safety of Elcovia, its
          Registered Users or the public, and all enforcement or other
          government officials, as Elcovia in its sole discretion believes to be
          necessary or appropriate.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          14. Interactions with other users.
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">14.1 User Responsibility:</span>
          You are solely responsible for your interactions with other users and
          any other parties with whom you interact; provided, however, that
          Elcovia reserves the right, but has no obligation, to intercede in
          such disputes. You agree that Elcovia will not be responsible for any
          liability incurred as the result of such interactions.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            14.2 Content Provided by Other Users:
          </span>
          The Services may contain User Content provided by other users. Elcovia
          is not responsible for and does not control User Content. Elcovia does
          not approve, endorse or make any representations or warranties with
          respect to User Content. You use all User Content and interact with
          other users at your own risk.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">14.3 Subverting the Platform:</span>
          It is a material breach of this Agreement to arrange for the sale of
          listed Products from, or the payment of fees to, Sellers outside the
          context of the Platform for the purposes of circumventing the
          obligation to pay the Elcovia fee for Products purchased through the
          Platform.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">15. Release</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">15.1 Release:</span>
          Elcovia expressly disclaims any liability that may arise between users
          of its Platform. The Platform is only a venue for connecting Buyers
          with Sellers. Because Elcovia is not a party to the actual contracts
          between Buyers and Sellers, in the event that you have a dispute with
          one or more users, you release Elcovia, its parents, subsidiaries,
          affiliates, officers, employees, investors, agents, partners and
          licensors, but excluding any users (each a “Elcovia Party” and
          collectively, the “Elcovia Parties”) from any and all claims, demands,
          or damages (actual or consequential) of every kind and nature, known
          and unknown, suspected and unsuspected, disclosed and undisclosed,
          arising out of or in any way connected with such disputes.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            15.2 Waiver of California Civil Code Section 1542:
          </span>
          IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL
          CODE SECTION 1542, WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND TO
          CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT
          TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND
          THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR
          HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.” THE FOREGOING
          RELEASE DOES NOT APPLY TO ANY CLAIMS, DEMANDS, OR ANY LOSSES, DAMAGES,
          RIGHTS AND ACTIONS OF ANY KIND, INCLUDING PERSONAL INJURIES, DEATH OR
          PROPERTY DAMAGE FOR ANY UNCONSCIONABLE COMMERCIAL PRACTICE BY A
          ELCOVIA PARTY OR FOR SUCH PARTY’S FRAUD, DECEPTION, FALSE PROMISE,
          MISREPRESENTATION OR CONCEALMENT, SUPPRESSION OR OMISSION OF ANY
          MATERIAL FACT IN CONNECTION WITH THE PLATFORM PROVIDED HEREUNDER.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">16. Indemnification</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">16.1 Indemnification:</span>
          You agree to indemnify and hold the Elcovia Parties harmless from any
          losses, costs, liabilities and expenses (including reasonable
          attorneys’ fees) relating to or arising out of any and all of the
          following: (a) Your Content; (b) your inability to use any Service;
          (c) your violation of the Agreement; (d) your violation of any rights
          of another party, including any users; (e) disputes with other users
          of the Services; or (f) your violation of any applicable laws, rules
          or regulations. Further, each Seller agrees to indemnify and hold the
          Elcovia Parties harmless from any losses, costs, liabilities, and
          expenses (including reasonable attorneys’ fees) relating to or arising
          out of related to Seller’s Products and Seller Properties. Elcovia
          reserves the right, at its own cost, to assume the exclusive defense
          and control of any matter otherwise subject to indemnification by you,
          in which event you will fully cooperate with Elcovia in asserting any
          available defenses. This provision does not require you to indemnify
          any of the Elcovia Parties for any unconscionable commercial practice
          by such party or for such party’s fraud, deception, false promise,
          misrepresentation or concealment, or suppression or omission of any
          material fact in connection with any Services provided hereunder. You
          agree that the provisions in this section will survive any termination
          of your Account, the Agreement and/or your access to the Services.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          17. Disclaimer of warranties and conditions
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">17.1 As Is:</span>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
          APPLICABLE LAW, YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, AND
          THAT THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
          WITH ALL FAULTS. ELCOVIA PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES,
          REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT ARISING FROM USE OF THE SERVICE. This Section 17
          (Disclaimer of Warranties and Conditions) does not affect in any way
          our return policy or limited warranty for goods purchased on the
          Service.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          (a) ELCOVIA PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION
          THAT: (i) THE SERVICES WILL MEET YOUR REQUIREMENTS; (ii) YOUR USE OF
          THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR
          (iii) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES WILL
          BE ACCURATE OR RELIABLE. WE CANNOT GUARANTEE CONTINUOUS OR SECURE
          ACCESS TO THE PLATFORM, AND OPERATION OF THE PLATFORM MAY BE
          INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR CONTROL.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          (b) ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH THE
          SERVICES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT
          LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS THE
          SERVICES, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          (c) THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
          DISRUPTIONS. ELCOVIA MAKES NO WARRANTY, REPRESENTATION OR CONDITION
          WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY,
          EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          (d) NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
          ELCOVIA OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY
          MADE HEREIN.
        </p>
        {/* (e) FROM TIME TO TIME, ELCOVIA MAY OFFER NEW "BETA" FEATURES OR TOOLS...
     Commenting this section as it seems to be optional content */}
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            17.2 No Liability for Conduct of Other Users or Third Parties:
          </span>
          YOU ACKNOWLEDGE AND AGREE THAT ELCOVIA PARTIES ARE NOT LIABLE, AND YOU
          AGREE NOT TO SEEK TO HOLD ELCOVIA PARTIES LIABLE, FOR THE CONDUCT OF
          OTHER USERS INCLUDING THIRD PARTIES, AND THAT THE RISK OF INJURY FROM
          SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          (a) Elcovia makes no warranty that the goods provided by third parties
          or other users will meet your requirements or be available on an
          uninterrupted, secure, or error-free basis. Elcovia makes no warranty
          regarding the quality of any such goods, or the accuracy, timeliness,
          truthfulness, completeness or reliability of any User content obtained
          through the Services.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          (b) We are not involved in the actual transaction between Buyers and
          Sellers. While we may help facilitate the resolution of disputes
          through various programs, we have no control over and do not guarantee
          the quality, safety or legality of Products advertised, the truth or
          accuracy of users’ content or listings, the ability of Sellers to sell
          items, the ability of Buyers to pay for Products, or that Buyer or
          Seller will actually complete a transaction or return all Products.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          (c) We do not transfer legal ownership of Products from the Seller to
          the Buyer. [California Commercial Code § 2401(2)] and Uniform
          Commercial Code § 2-401(2) apply to the transfer of ownership between
          the Buyer and the Seller, unless the Buyer and the Seller agree
          otherwise. Further, we cannot guarantee continuous or secure access to
          the Services and operation of the Services may be interfered with by
          numerous factors outside of our control. Accordingly, to the extent
          legally permitted, we exclude all implied warranties, terms and
          conditions.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          (d) As a part of the Services, you may have access to materials that
          are hosted by another party. You agree that it is impossible for
          Elcovia to monitor such materials and that you access these materials
          at your own risk.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          18. Limitation of liability
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            18.1 Disclaimer of Certain Damages:
          </span>
          YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED BY LAW,
          IN NO EVENT SHALL ELCOVIA PARTIES BE LIABLE FOR ANY LOSS OF PROFITS,
          REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
          DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE,
          BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
          IN EACH CASE WHETHER OR NOT ELCOVIA HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE
          AGREEMENT OR ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER
          USERS OF THE SERVICES, ON ANY THEORY OF LIABILITY, RESULTING FROM: (a)
          THE USE OR INABILITY TO USE THE SERVICES; (b) THE COST OF PROCUREMENT
          OF SUBSTITUTE GOODS OR SERVICES RESULTING FROM ANY GOODS, DATA,
          INFORMATION OR SERVICES PURCHASED OR OBTAINED; OR MESSAGES RECEIVED
          FOR TRANSACTIONS ENTERED INTO THROUGH THE SERVICES; (c) UNAUTHORIZED
          ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (d) STATEMENTS
          OR CONDUCT OF ANY THIRD-PARTY ON THE SERVICES; OR (e) ANY OTHER MATTER
          RELATED TO THE SERVICES, WHETHER BASED ON WARRANTY, COPYRIGHT,
          CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER
          LEGAL THEORY. THE FOREGOING LIMITATION OF LIABILITY SHALL NOT APPLY TO
          LIABILITY OF A ELCOVIA PARTY FOR (i) DEATH OR PERSONAL INJURY CAUSED
          BY A ELCOVIA PARTY’S NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A
          ELCOVIA PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">18.2 Cap on Liability:</span>
          TO THE FULLEST EXTENT PROVIDED BY LAW, ELCOVIA PARTIES WILL NOT BE
          LIABLE TO YOU FOR MORE THAN THE GREATER OF (a) THE TOTAL AMOUNT PAID
          TO ELCOVIA BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE ACT,
          OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY; OR (b) $100. THE
          FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A ELCOVIA
          PARTY FOR (i) DEATH OR PERSONAL INJURY CAUSED BY A ELCOVIA PARTY’S
          NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A ELCOVIA PARTY’S FRAUD
          OR FRAUDULENT MISREPRESENTATION.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">18.3 Exclusion of Damages:</span>
          CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
          EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
          ADDITIONAL RIGHTS.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">18.4 Basis of the Bargain:</span>
          THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
          THE BASIS OF THE BARGAIN BETWEEN ELCOVIA AND YOU.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4 mb-4">
          19. Procedure for making claims of copyright infringement
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          It is Elcovia’s policy to terminate membership privileges of any
          Registered User who repeatedly infringes copyright upon prompt
          notification to Elcovia by the copyright owner or the copyright
          owner’s legal agent. Without limiting the foregoing, if you believe
          that your work has been copied and posted on the Services in a way
          that constitutes copyright infringement, please provide our Copyright
          Agent with the following information: (a) an electronic or physical
          signature of the person authorized to act on behalf of the owner of
          the copyright interest; (b) a description of the copyrighted work that
          you claim has been infringed; (c) a description of the location on the
          Services of the material that you claim is infringing; (d) your
          address, telephone number and e-mail address; (e) a written statement
          by you that you have a good faith belief that the disputed use is not
          authorized by the copyright owner, its agent or the law; and (f) a
          statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner’s behalf. Contact
          information for Elcovia’s Copyright Agent for notice of claims of
          copyright infringement is as follows: [Include name or title, and
          physical address of Copyright Agent].
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          20. Term and termination
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">20.1 Term:</span>
          The Agreement commences on the date when you accept them (as described
          in the preamble above) and remain in full force and effect while you
          use the Services, unless terminated earlier in accordance with the
          Agreement.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">20.2 Prior Use:</span>
          Notwithstanding the foregoing, you hereby acknowledge and agree that
          the Agreement commenced on the earlier to occur of (a) the date you
          first used the Services or (b) the date you accepted the Agreement,
          and that the Agreement will remain in full force and effect while you
          use any the Services, unless earlier terminated in accordance with the
          Agreement.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            20.3 No Subsequent Registration:
          </span>
          If your registration(s) with, or ability to access, the Services or
          any other Elcovia community, is discontinued by Elcovia due to your
          violation of any portion of the Agreement or for conduct otherwise
          inappropriate for the community, then you agree that you shall not
          attempt to re-register with or access the Services or any Elcovia
          community through use of a different member name or otherwise, and you
          acknowledge that you will not be entitled to receive a refund for fees
          related to those the Services to which your access has been
          terminated. In the event that you violate the immediately preceding
          sentence, Elcovia reserves the right, in its sole discretion, to
          immediately take any or all of the actions set forth herein without
          any notice or warning to you.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">20.4 Suspension of Services:</span>
          Elcovia may decline, remove or halt sales of any Product, suspend or
          terminate an Account, and/or suspend or terminate the Services at any
          time, in its sole discretion, without cause or notice to you or any
          penalty or liability for doing so.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          21. Territorial restrictions
        </h2>

        <p className="font-normal text-sm text-gray-900 leading-6">
          The Services can be accessed from countries around the world and may
          contain references to Services and Content that are not available in
          your country. These references do not imply that Elcovia intends to
          announce such Services or Content in your country. The Services are
          controlled and offered by Elcovia from its facilities in the United
          States of America. Elcovia makes no representations that the Services
          are appropriate or available for use in other locations. Those who
          access or use the Services from other countries do so at their own
          volition and are responsible for compliance with local law.
        </p>

        <h2 className="text-xl font-bold mt-8 mb-4">
          22. Arbitration Agreement
        </h2>

        <p className="font-normal text-sm text-gray-900 leading-6">
          Please read the following arbitration agreement in this section
          (“Arbitration Agreement”) carefully. It requires Registered to
          arbitrate disputes with Elcovia and limits the manner in which you can
          seek relief from us. For the avoidance of doubt, this Arbitration
          Agreement does not govern disputes between you and another user.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            22.1 Applicability of Arbitration Agreement:
          </span>
          Subject to the terms of this Arbitration Agreement, you and Elcovia
          agree that any dispute, claim, disagreements arising out of or
          relating in any way to your access to or use of the Services, any
          communications you receive, any products sold or distributed by other
          users through the Services, or this Agreement, including claims and
          disputes that arose between us before the effective date of the
          Agreement (each, a “Dispute”) will be resolved by binding arbitration,
          rather than in court, except that: (1) you and Elcovia may assert
          claims or seek relief in small claims court if such claims qualify and
          remain in small claims court; and (2) you or Elcovia may seek
          equitable relief in court for infringement or other misuse of
          intellectual property rights (such as trademarks, trade dress, domain
          names, trade secrets, copyrights, and patents). For purposes of this
          Arbitration Agreement, “Dispute” will also include disputes that arose
          or involve facts occurring before the existence of this or any prior
          versions of the Agreement as well as claims that may arise after the
          termination of the Agreement. This Arbitration Agreement does not
          apply to disputes between you and third parties.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            2.2 Informal Dispute Resolution:
          </span>
          There might be instances when a Dispute arises between you and
          Elcovia. If that occurs, Elcovia is committed to working with you to
          reach a reasonable resolution. You and Elcovia agree that good faith
          informal efforts to resolve Disputes can result in a prompt, low‐cost
          and mutually beneficial outcome. You and Elcovia therefore agree that
          before either party commences arbitration against the other (or
          initiates an action in small claims court if a party so elects), we
          will personally meet and confer telephonically or via videoconference,
          in a good faith effort to resolve informally any Dispute covered by
          this Arbitration Agreement (“Informal Dispute Resolution Conference”).
          If you are represented by counsel, your counsel may participate in the
          conference, but you will also participate in the conference.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          The party initiating a Dispute must give notice to the other party in
          writing of its intent to initiate an Informal Dispute Resolution
          Conference (“Notice”), which shall occur within 45 days after the
          other party receives such Notice, unless an extension is mutually
          agreed upon by the parties. Notice to Elcovia that you intend to
          initiate an Informal Dispute Resolution Conference should be sent by
          email to support@elcovia.com or regular mail to our offices located at
          548 Market St, PMB 41309, San Francisco, California 94104-5401 US. The
          Notice must include: (1) your name, telephone number, mailing address,
          e‐mail address associated with your account (if you have one); (2) the
          name, telephone number, mailing address and e‐mail address of your
          counsel, if any; and (3) a description of your Dispute.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          The Informal Dispute Resolution Conference shall be individualized
          such that a separate conference must be held each time either party
          initiates a Dispute, even if the same law firm or group of law firms
          represents multiple users in similar cases, unless all parties agree;
          multiple individuals initiating a Dispute cannot participate in the
          same Informal Dispute Resolution Conference unless all parties agree.
          In the time between a party receiving the Notice and the Informal
          Dispute Resolution Conference, nothing in this Arbitration Agreement
          shall prohibit the parties from engaging in informal communications to
          resolve the initiating party’s Dispute. Engaging in the Informal
          Dispute Resolution Conference is a condition precedent and requirement
          that must be fulfilled before commencing arbitration. The statute of
          limitations and any filing fee deadlines shall be tolled while the
          parties engage in the Informal Dispute Resolution Conference process
          required by this section.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.3 Waiver of Jury Trial:</span>
          YOU AND ELCOVIA HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
          TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You
          and Elcovia are instead electing that all Disputes shall be resolved
          by arbitration under this Arbitration Agreement, except as specified
          in the subsection entitled “Applicability of Arbitration Agreement”
          above. There is no judge or jury in arbitration, and court review of
          an arbitration award is subject to very limited review.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            22.4 Waiver of Class or Other Non-Individualized Relief:
          </span>
          YOU AND ELCOVIA AGREE THAT, EXCEPT AS SPECIFIED IN SUBSECTION 22.9,
          EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
          BASIS AND NOT ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS, AND THE
          PARTIES HEREBY WAIVE ALL RIGHTS TO HAVE ANY DISPUTE BE BROUGHT, HEARD,
          ADMINISTERED, RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
          REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL RELIEF IS
          AVAILABLE, AND DISPUTES OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
          Subject to this Arbitration Agreement, the arbitrator may award
          declaratory or injunctive relief only in favor of the individual party
          seeking relief and only to the extent necessary to provide relief
          warranted by the party’s individual claim. Nothing in this paragraph
          is intended to, nor shall it, affect the terms and conditions under
          the Section 22.9 entitled “Batch Arbitration.” Notwithstanding
          anything to the contrary in this Arbitration Agreement, if a court
          decides by means of a final decision, not subject to any further
          appeal or recourse, that the limitations of this subsection, “Waiver
          of Class and Other Non-Individualized Relief,” are invalid or
          unenforceable as to a particular claim or request for relief (such as
          a request for public injunctive relief), you and Elcovia agree that
          that particular claim or request for relief (and only that particular
          claim or request for relief) shall be severed from the arbitration and
          may be litigated in the state or federal courts located in the State
          of California. All other Disputes shall be arbitrated or litigated in
          small claims court. This subsection does not prevent you or Elcovia
          from participating in a class-wide settlement of claims.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.5 Rules and Forum:</span>
          The Agreement evidences a transaction involving interstate commerce;
          and notwithstanding any other provision herein with respect to the
          applicable substantive law, the Federal Arbitration Act, 9 U.S.C. § 1
          et seq., will govern the interpretation and enforcement of this
          Arbitration Agreement and any arbitration proceedings. If the Informal
          Dispute Resolution Process described above does not resolve
          satisfactorily within sixty (60) days after receipt of your Notice,
          you and Elcovia agree that either party shall have the right to
          finally resolve the Dispute through binding arbitration. The
          arbitration will be administered by the American Arbitration
          Association (“AAA”), in accordance with the Consumer Arbitration Rules
          (the “AAA Rules”) then in effect, except as modified by this section
          of this Arbitration Agreement. The AAA Rules are currently available
          at https://www.adr.org/sites/default/files/Consumer%20Rules.pdf.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.6 Arbitrator:</span>
          The arbitrator will be either a retired judge or an attorney licensed
          to practice law in the state of California and will be selected by the
          parties from the AAA’s roster of consumer dispute arbitrators. If the
          parties are unable to agree upon an arbitrator within thirty-five (35)
          days of delivery of the Request, then the AAA will appoint the
          arbitrator in accordance with the AAA Rules, provided that if the
          Batch Arbitration process under subsection 22.9 is triggered, the AAA
          will appoint the arbitrator for each batch.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.7 Authority of Arbitrator:</span>
          The arbitrator shall have exclusive authority to resolve any Dispute,
          including, without limitation, disputes arising out of or related to
          the interpretation or application of the Arbitration Agreement,
          including the enforceability, revocability, scope, or validity of the
          Arbitration Agreement or any portion of the Arbitration Agreement,
          except for the following: (1) all Disputes arising out of or relating
          to the subsection entitled “Waiver of Class or Other
          Non-Individualized Relief,” including any claim that all or part of
          the subsection entitled “Waiver of Class and Other Non-Individualized
          Relief” is unenforceable, illegal, void or voidable, or that such
          subsection entitled “Waiver of Class and Other Non-Individualized
          Relief” has been breached, shall be decided by a court of competent
          jurisdiction and not by an arbitrator; (2) except as expressly
          contemplated in the subsection entitled “Batch Arbitration,” all
          Disputes about the payment of arbitration fees shall be decided only
          by a court of competent jurisdiction and not by an arbitrator; (3) all
          Disputes about whether either party has satisfied any condition
          precedent to arbitration shall be decided only by a court of competent
          jurisdiction and not by an arbitrator; and (4) all Disputes about
          which version of the Arbitration Agreement applies shall be decided
          only by a court of competent jurisdiction and not by an arbitrator.
          The arbitration proceeding will not be consolidated with any other
          matters or joined with any other cases or parties, except as expressly
          provided in the subsection entitled “Batch Arbitration.” The
          arbitrator shall have the authority to grant motions dispositive of
          all or part of any Dispute. The arbitrator shall issue a written award
          and statement of decision describing the essential findings and
          conclusions on which the award is based, including the calculation of
          any damages awarded. The award of the arbitrator is final and binding
          upon you and us. Judgment on the arbitration award may be entered in
          any court having jurisdiction.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            22.8 Attorneys’ Fees and Costs:
          </span>
          The parties shall bear their own attorneys’ fees and costs in
          arbitration unless the arbitrator finds that either the substance of
          the Dispute or the relief sought in the Request was frivolous or was
          brought for an improper purpose (as measured by the standards set
          forth in Federal Rule of Civil Procedure 11(b)). If you or Elcovia
          need to invoke the authority of a court of competent jurisdiction to
          compel arbitration, then the party that obtains an order compelling
          arbitration in such action shall have the right to collect from the
          other party its reasonable costs, necessary disbursements, and
          reasonable attorneys’ fees incurred in securing an order compelling
          arbitration. The prevailing party in any court action relating to
          whether either party has satisfied any condition precedent to
          arbitration, including the Informal Dispute Resolution Process, is
          entitled to recover their reasonable costs, necessary disbursements,
          and reasonable attorneys’ fees and costs.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.9 Batch Arbitration:</span>
          To increase the efficiency of administration and resolution of
          arbitrations, you and Elcovia agree that in the event that there are
          one hundred (100) or more individual Requests of a substantially
          similar nature filed against Elcovia by or with the assistance of the
          same law firm, group of law firms, or organizations, within a thirty
          (30) day period (or as soon as possible thereafter), the AAA shall (1)
          administer the arbitration demands in batches of 100 Requests per
          batch (plus, to the extent there are less than 100 Requests left over
          after the batching described above, a final batch consisting of the
          remaining Requests); (2) appoint one arbitrator for each batch; and
          (3) provide for the resolution of each batch as a single consolidated
          arbitration with one set of filing and administrative fees due per
          side per batch, one procedural calendar, one hearing (if any) in a
          place to be determined by the arbitrator, and one final award (“Batch
          Arbitration”).
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          All parties agree that Requests are of a “substantially similar
          nature” if they arise out of or relate to the same event or factual
          scenario and raise the same or similar legal issues and seek the same
          or similar relief. To the extent the parties disagree on the
          application of the Batch Arbitration process, the disagreeing party
          shall advise the AAA, and the AAA shall appoint a sole standing
          arbitrator to determine the applicability of the Batch Arbitration
          process (“Administrative Arbitrator”). In an effort to expedite
          resolution of any such dispute by the Administrative Arbitrator, the
          parties agree the Administrative Arbitrator may set forth such
          procedures as are necessary to resolve any disputes promptly. The
          Administrative Arbitrator’s fees shall be paid by Elcovia.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          You and Elcovia agree to cooperate in good faith with the AAA to
          implement the Batch Arbitration process including the payment of
          single filing and administrative fees for batches of Requests, as well
          as any steps to minimize the time and costs of arbitration, which may
          include: (1) the appointment of a discovery special master to assist
          the arbitrator in the resolution of discovery disputes; and (2) the
          adoption of an expedited calendar of the arbitration proceedings.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          This Batch Arbitration provision shall in no way be interpreted as
          authorizing a class, collective and/or mass arbitration or action of
          any kind, or arbitration involving joint or consolidated claims under
          any circumstances, except as expressly set forth in this provision.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.10 30-Day Right to Opt Out:</span>
          You have the right to opt out of the provisions of this Arbitration
          Agreement by sending written notice of your decision to opt out to:
          [insert address], within thirty (30) days after first becoming subject
          to this Arbitration Agreement. Your notice must include your name and
          address, the email address you used to set up your Account (if you
          have one), and an unequivocal statement that you want to opt out of
          this Arbitration Agreement. If you opt out of this Arbitration
          Agreement, all other parts of the Agreement will continue to apply to
          you. Opting out of this Arbitration Agreement has no effect on any
          other arbitration agreements that you may currently have, or may enter
          in the future, with us.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.11 Invalidity, Expiration:</span>
          Except as provided in the subsection entitled “Waiver of Class or
          Other Non-Individualized Relief”, if any part or parts of this
          Arbitration Agreement are found under the law to be invalid or
          unenforceable, then such specific part or parts shall be of no force
          and effect and shall be severed and the remainder of the Arbitration
          Agreement shall continue in full force and effect. You further agree
          that any Dispute that you have with Elcovia as detailed in this
          Arbitration Agreement must be initiated via arbitration within the
          applicable statute of limitation for that claim or controversy, or it
          will be forever time barred. Likewise, you agree that all applicable
          statutes of limitation will apply to such arbitration in the same
          manner as those statutes of limitation would apply in the applicable
          court of competent jurisdiction.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">22.12 Modification:</span>
          Notwithstanding any provision in the Agreement to the contrary, we
          agree that if Elcovia makes any future material change to this
          Arbitration Agreement, it will notify you. Unless you reject the
          change within thirty (30) days of such change become effective by
          writing to Elcovia at [insert address], your continued use of the
          Services, including the acceptance of products and services offered
          through the Services following the posting of changes to this
          Arbitration Agreement constitutes your acceptance of any such changes.
          Changes to this Arbitration Agreement do not provide you with a new
          opportunity to opt out of the Arbitration Agreement if you have
          previously agreed to a version of the Agreement and did not validly
          opt out of arbitration. If you reject any change or update to this
          Arbitration Agreement, and you were bound by an existing agreement to
          arbitrate Disputes arising out of or relating in any way to your
          access to or use of the Services, any communications you receive, any
          products sold or distributed through the Services, or the Agreement,
          the provisions of this Arbitration Agreement as of the date you first
          accepted the Agreement (or accepted any subsequent changes to the
          Agreement) remain in full force and effect. Elcovia will continue to
          honor any valid opt outs of the Arbitration Agreement that you made to
          a prior version of the Agreement.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          23. Third-Party Services
        </h2>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            23.1 Third-Party Websites, Applications and Ads:
          </span>
          The Services may contain links to third-party websites (“Third-Party
          Websites”), applications (“Third-Party Applications”) and
          advertisements for third parties (“Third-Party Ads”). When you click
          on a link to a Third-Party Website, Third-Party Application or
          Third-Party Ad, we will not warn you that you have left the Services
          and are subject to the terms and conditions (including privacy
          policies) of another website or destination. Such Third-Party
          Websites, Third-Party Applications and Third-Party Ads are not under
          the control of Elcovia. Elcovia is not responsible for any Third-Party
          Websites, Third-Party Applications or Third-Party Ads. Elcovia
          provides these Third-Party Websites, Third-Party Applications or
          Third-Party Ads only as a convenience and does not review, approve,
          monitor, endorse, warrant, or make any representations with respect to
          Third-Party Websites, Third-Party Applications or Third-Party Ads, or
          any product or service provided in connection therewith. You use all
          links in Third-Party Websites, Third-Party Applications or Third-Party
          Ads at your own risk. When you leave our Website, this Agreement and
          our policies no longer govern. You should review applicable terms and
          policies, including privacy and data gathering practices, of any
          Third-Party Websites, Third-Party Applications or Third-Party Ads, and
          make whatever investigation you feel necessary or appropriate before
          proceeding with any transaction with any third party.
        </p>

        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            23.2 Accessing and Downloading the Application from the Apple App
            Store:
          </span>
          The following applies to any App Store Sourced Application accessed
          through or downloaded from the Apple App Store:
        </p>

        <ol className="list-decimal pl-6">
          <li className="font-normal text-sm text-gray-900 leading-6">
            You acknowledge and agree that (i) the Agreement is concluded
            between you and Elcovia only, and not Apple, and (ii) Elcovia, not
            Apple, is solely responsible for the App Store Sourced Application
            and content thereof. Your use of the App Store Sourced Application
            must comply with the Apple Media Services Terms of Service.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to the App Store
            Sourced Application.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            In the event of any failure of the App Store Sourced Application to
            conform to any applicable warranty, you may notify Apple, and Apple
            will refund the purchase price for the App Store Sourced Application
            to you and to the maximum extent permitted by applicable law, Apple
            will have no other warranty obligation whatsoever with respect to
            the App Store Sourced Application. As between Elcovia and Apple, any
            other claims, losses, liabilities, damages, costs or expenses
            attributable to any failure to conform to any warranty will be the
            sole responsibility of Elcovia.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            You and Elcovia acknowledge that, as between Elcovia and Apple,
            Apple is not responsible for addressing any claims you have or any
            claims of any third party relating to the App Store Sourced
            Application or your possession and use of the App Store Sourced
            Application, including, but not limited to: (i) product liability
            claims; (ii) any claim that the App Store Sourced Application fails
            to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar
            legislation.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            You and Elcovia acknowledge that, in the event of any third-party
            claim that the App Store Sourced Application or your possession and
            use of that App Store Sourced Application infringes that third
            party’s intellectual property rights, as between Elcovia and Apple,
            Elcovia, not Apple, will be solely responsible for the
            investigation, defense, settlement and discharge of any such
            intellectual property infringement claim to the extent required by
            the Agreement.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            You and Elcovia acknowledge and agree that Apple, and Apple’s
            subsidiaries, are third-party beneficiaries of the Agreement as
            related to your license of the App Store Sourced Application, and
            that, upon your acceptance of the terms and conditions of the
            Agreement, Apple will have the right (and will be deemed to have
            accepted the right) to enforce the Agreement as related to your
            license of the App Store Sourced Application against you as a
            third-party beneficiary thereof.
          </li>

          <li className="font-normal text-sm text-gray-900 leading-6">
            Without limiting any other terms of the Agreement, you must comply
            with all applicable third-party terms of agreement when using the
            App Store Sourced Application.
          </li>
        </ol>

        <h2 className="text-xl font-bold mt-8 mb-4">24. General Provisions</h2>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            24.1 Electronic Communications:
          </span>
          The communications between you and Elcovia may take place via
          electronic means, whether you visit the Services or send Elcovia
          e-mails, or whether Elcovia posts notices on the Services or
          communicates with you via e-mail. For contractual purposes, you (a)
          consent to receive communications from Elcovia in an electronic form;
          and (b) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that Elcovia provides to you
          electronically satisfy any legal requirement that such communications
          would satisfy if it were to be in writing. The foregoing does not
          affect your statutory rights, including but not limited to the
          Electronic Signatures in Global and National Commerce Act at 15 U.S.C.
          §7001 et seq. (“E-Sign”).
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.2 Assignment:</span>
          The Agreement, and your rights and obligations hereunder, may not be
          assigned, subcontracted, delegated or otherwise transferred by you
          without Elcovia’s prior written consent, and any attempted assignment,
          subcontract, delegation, or transfer in violation of the foregoing
          will be null and void.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">4.3 Force Majeure:</span>
          Elcovia shall not be liable for any delay or failure to perform
          resulting from causes outside its reasonable control, including, but
          not limited to, acts of God, war, terrorism, riots, embargos, acts of
          civil or military authorities, fire, floods, accidents, strikes or
          shortages of transportation facilities, fuel, energy, labor or
          materials.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">
            24.4 Questions, Complaints, Claims:
          </span>
          If you have any questions, complaints or claims with respect to the
          Services, please contact us by mail at Kostheimerstr. 12, 60326
          Frankfurt am Main, Germany or email at support@elcovia.com. We will do
          our best to address your concerns. If you feel that your concerns have
          been addressed incompletely, we invite you to let us know for further
          investigation.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.5 Consumer Complaints:</span>
          If you have any consumer complaints or concerns, you can reach out to
          the appropriate regulatory authority in Germany. For assistance and
          information, you can contact the Consumer Protection Office in
          Frankfurt at [Contact Information].
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.6 Agreement Updates:</span>
          THIS AGREEMENT IS SUBJECT TO CHANGE BY ELCOVIA IN ITS SOLE DISCRETION
          AT ANY TIME. When changes are made, Elcovia will make a new copy of
          the Terms of Service available at the Website and within the
          Application and any new Supplemental Terms will be made available from
          within, or through, the affected Service on the Website or within the
          Application. We will also update the “Last Updated” date at the top of
          the Terms of Service. If we make any material changes, and you have
          registered with us to create an Account (as defined in Section 5.1
          (Registering Your Account) below) we will also send an e-mail to you
          at the last e-mail address you provided to us pursuant to the
          Agreement. Any changes to the Agreement will be effective immediately
          for new users of the Service and will be effective thirty (30) days
          after posting notice of such changes on the Website for existing
          Registered Users, provided that any material changes shall be
          effective for Registered Users who have an Account with us upon the
          earlier of thirty (30) days after posting notice of such changes on
          the Website or thirty (30) days after dispatch of an e-mail notice of
          such changes to Registered Users (as defined in Section 5.1
          (Registering Your Account) below). Elcovia may require you to provide
          consent to the updated Agreement in a specified manner before further
          use of the Service is permitted. If you do not agree to any change(s)
          after receiving a notice of such change(s), you shall stop using the
          Service. Otherwise, your continued use of the Service constitutes your
          acceptance of such change(s). PLEASE REGULARLY CHECK THE SERVICE TO
          VIEW THE THEN-CURRENT TERMS.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.7 Exclusive Venue:</span>
          To the extent the parties are permitted under this Agreement to
          initiate litigation in a court, both you and Elcovia agree that all
          claims and disputes arising out of or relating to the Agreement will
          be litigated exclusively in the state or federal courts located in San
          Francisco County of California.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.8 Governing Law:</span>
          The terms and any action related thereto will be governed by and
          interpreted under the laws of Germany, without giving effect to any
          principles that provide for the application of the law of another
          jurisdiction. Any disputes, claims, or requests for relief relating to
          these terms will be subject to the exclusive jurisdiction of the
          courts in Frankfurt, Germany.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.9 Choice of Language:</span>
          The parties confirm that it is their express wish that this Agreement,
          and all documents and notices related hereto, be in English. Les
          parties confirment leur volonté expresse à ce que la présente
          convention, ainsi que tout document ou avis s'y rapportant, soit en
          anglais.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.10 Notice:</span>
          Where Elcovia requires that you provide an e-mail address, you are
          responsible for providing Elcovia with your most current e-mail
          address. In the event that the last e-mail address you provided to
          Elcovia is not valid, or for any reason is not capable of delivering
          to you any notices required/ permitted by the Agreement, Elcovia’s
          dispatch of the e-mail containing such notice will nonetheless
          constitute effective notice. You may give notice to Elcovia at the
          following address Kostheimerstr. 12, 60326 Frankfurt am Main, Germany
          or via email at support@elcovia.com. Such notice shall be deemed given
          when received by Elcovia by letter delivered by nationally recognized
          overnight delivery service or first class postage prepaid mail at the
          above address.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.11 Waiver:</span>
          Any waiver or failure to enforce any provision of the Agreement on one
          occasion will not be deemed a waiver of any other provision or of such
          provision on any other occasion.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.12 Severability:</span>
          If any portion of this Agreement is held invalid or unenforceable,
          that portion shall be construed in a manner to reflect, as nearly as
          possible, the original intention of the parties, and the remaining
          portions shall remain in full force and effect.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.13 Export Control:</span>
          You may not use, export, import, or transfer the Services except as
          authorized by U.S. law, the laws of the jurisdiction in which you
          obtained the Services, and any other applicable laws. In particular,
          but without limitation, the Services may not be exported or
          re-exported (a) into any United States embargoed countries, or (b) to
          anyone on the U.S. Treasury Department’s list of Specially Designated
          Nationals or the U.S. Department of Commerce’s Denied Person’s List or
          Entity List. By using the Services, you represent and warrant that (y)
          you are not located in a country that is subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          “terrorist supporting” country and (z) you are not listed on any U.S.
          Government list of prohibited or restricted parties. You also will not
          use the Services for any purpose prohibited by U.S. law, including the
          development, design, manufacture or production of missiles, nuclear,
          chemical or biological weapons. You acknowledge and agree that
          products, services or technology provided by Elcovia are subject to
          the export control laws and regulations of the United States. You
          shall comply with these laws and regulations and shall not, without
          prior U.S. government authorization, export, re-export, or transfer
          Elcovia products, services or technology, either directly or
          indirectly, to any country in violation of such laws and regulations.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6 mb-4">
          <span className="font-bold mr-1">24.14 Entire Agreement:</span>
          The Agreement is the final, complete and exclusive agreement of the
          parties with respect to the subject matter hereof and supersedes and
          merges all prior discussions between the parties with respect to such
          subject matter.
        </p>
      </div>
    </>
  );
};
