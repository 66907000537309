import React from "react";
import { IMAGE_COVER_PLACEHOLDER } from "../../../utils/constants";
import HtmlShortTextComponent from "../../../components/common/html/preview_short/HtmlShortTextComponent";
import { Template } from "../../../models/Template";
import { localizedValue } from "../../../utils/supported-locales";
import { useNavigate } from "react-router-dom";
import { Destination, setPathId } from "../../../utils/constants-navigation";

export type DraftTemplate = {
  template: Template;
};

interface DraftTemplateSectonProps {
  id: string;
  image: string | null;
  title: string | null;
  description?: string;
  onClick: () => void;
}

const HomeDraftTemplateSecton: React.FC<DraftTemplateSectonProps> = ({
  id,
  image,
  title,
  description,
  onClick,
}) => {
  return (
    <li
      onClick={onClick}
      key={id}
      className="overflow-hidden transition-all ease-in-out duration-300 bg-white rounded-lg border border-gray-200/50 hover:shadow-md hover:md:scale-[1.02]"
    >
      <div className="bg-indigo-500/[0.025] w-full flex items-center gap-x-7 border-b border-gray-900/5 p-3">
        <img
          src={image || IMAGE_COVER_PLACEHOLDER}
          className="h-16 w-16 flex-none rounded-md bg-white object-cover shadow"
        />
        <h4 className="text-md font-semibold leading-6 text-gray-900 line-clamp-2">
          {title}
        </h4>
      </div>
      <div className="divide-y divide-gray-200/50 p-3">
        <div className="text-xs text-gray-500 leading-5 line-clamp-3">
          <HtmlShortTextComponent html={description || ""} />
        </div>
      </div>
    </li>
  );
};

interface DraftTemplatesComponentProps {
  draftTemplates: DraftTemplate[];
}

const DraftTemplatesComponent: React.FC<DraftTemplatesComponentProps> = ({
  draftTemplates,
}) => {
  const navigate = useNavigate();
  if (draftTemplates.length === 0) return null;
  return (
    <div>
      <ul
        role="list"
        className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
      >
        {draftTemplates.map((draft) => (
          <HomeDraftTemplateSecton
            key={draft.template.id}
            id={draft.template.id}
            title={localizedValue(draft.template.title) || ""}
            image={localizedValue(draft.template.thumbnailImage) || null}
            description={localizedValue(draft.template.desc) || ""}
            onClick={() =>
              navigate(
                setPathId(Destination.ACCOUNT_TEMPLATES_ID, draft.template.id)
              )
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default DraftTemplatesComponent;
