type InputGroupComponentProps = {
  title: string;
  description: string;
  info?: string;
  content?: React.ReactNode;
  withoutBottomPadding?: boolean;
};

const SettingsHeaderComponent: React.FC<InputGroupComponentProps> = ({
  title,
  description,
  info,
  content,
  withoutBottomPadding,
}) => {
  const bottomPadding = withoutBottomPadding ? "" : "pb-8";
  return (
    <div>
      <label
        className="block text-xl font-bold leading-6 text-gray-900"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className="mt-2 text-sm font-normal text-gray-600 leading-6"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {info && (
        <p className="mt-2 text-xs font-semibold text-gray-600">{info}</p>
      )}
      {content && (
        <div className={`mt-4 pt-3 ${bottomPadding} space-y-6`}>{content}</div>
      )}
    </div>
  );
};

export default SettingsHeaderComponent;
