import { Profile } from "../models/Profile";

export function getProfileId(profile: Profile | null): string {
    if (profile && (profile.profileId || profile.id)) {
        const profileId = profile.profileId || profile.id;
        if (!profileId.includes("-")) {
            return profileId;
        }
        return profile.id;
    }
    return profile?.id || "";
}
