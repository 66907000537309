import { fetchAndActivate, getRemoteConfig, getString } from 'firebase/remote-config';

import { TemplatePromotionConfig } from '../models/data/payment/TemplatePromotionConfig';
import { ProfilePromotionConfig } from '../models/data/payment/ProfilePromotionConfig';

// Name of parameters
const PARAM_TEMPLATE_PROMOTION_CONFIG = "template_promotion_config"
const PARAM_PROFILE_PROMOTION_CONFIG = "profile_promotion_config"

// Initialize Firebase remote config
const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 7200000;

/**
 * Get Template Promotion Options Config
 * @returns TemplatePromotionConfig
 */
export async function getTemplatePromotionConfig(): Promise<TemplatePromotionConfig[]> {
  await fetchAndActivate(remoteConfig);
  const data = getString(remoteConfig, PARAM_TEMPLATE_PROMOTION_CONFIG);
  const json = JSON.parse(data);
  const config = TemplatePromotionConfig.fromRemoteConfigArray(json);
  return config;
}

/**
 * Get Profile Promotion Options Config
 * @returns TemplatePromotionConfig
 */
export async function getProfilePromotionConfig(): Promise<ProfilePromotionConfig[]> {
  await fetchAndActivate(remoteConfig);
  const data = getString(remoteConfig, PARAM_PROFILE_PROMOTION_CONFIG);
  const json = JSON.parse(data);
  const config = ProfilePromotionConfig.fromRemoteConfigArray(json);
  console.log(config);
  return config;
}
