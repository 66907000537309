import React from "react";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <div className="mx-auto max-w-4xl">
        <h1 className="text-5xl font-bold">Privacy Policy</h1>
        <h2 className="text-xl font-bold mt-8 mb-4">1. Scope</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          This Privacy Policy describes how we handle your personal information
          for our Service on the Elcovia websites, tools, and mobile
          applications. It applies generally to information collected on the
          Elcovia.com website, mobile application and content (collectively, the
          "Site" or “Sites”) or through the use of our Service. Capitalized
          terms used in this Privacy Policy shall have the meaning set forth
          herein or in the User Agreement posted on the Site.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          This Privacy Notice is effective upon acceptance for new users and is
          effect immediately for all users.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          2. Types of Data We Collect
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Information You Provide to Us.</strong>
          <br />
          We may collect and store the following Personal Data:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="font-normal text-sm text-gray-900 leading-6">
            Contact information, such as name, user ID, e-mail address, and
            phone number
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Information necessary for us to remit payments to sellers, such as
            financial account transactional information based on your activities
            on the Site as a seller (such as item and content you generate or
            that relates to your account); community discussions, chats, dispute
            resolution, correspondence through our Site, and correspondence sent
            to us; information we ask you to submit to authenticate yourself or
            if we believe you are violating site policies (for example, we may
            ask you to send us an ID or bill to verify your address, or to
            answer additional questions online to help verify your identity or
            ownership of an item you list);
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Marketing information, such as your preferences for receiving
            marketing communications and details about how you engage with
            marketing communications; and
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Other personal information you choose to submit to us
          </li>
        </ul>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>
            Information We Collect About Buyers From or on Behalf of Our Sellers
          </strong>
          <br />
          We may collect information about buyers from or on behalf of our
          sellers. The sellers determine the scope of the information we collect
          on their behalf, that they transfer to us, or that we otherwise
          collect, and the information we receive may vary by seller. Typically,
          the information we collect about individuals from or on behalf of our
          sellers includes:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="font-normal text-sm text-gray-900 leading-6">
            Transactional information based on your activities on the Site as a
            buyer (such as item and content you purchase)
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Payment information, such as credit card number
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Your contact information (such as your name, user ID, email-address,
            billing and other information you provide to purchase an item or
            receive delivery of an item
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Other personal information that our sellers may provide about you
          </li>
        </ul>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Information Automatically Collected.</strong>
          <br />
          When you access the Site, we, our service providers, and our business
          partners may automatically log information about you, your computer or
          mobile device, and activity on our Site. The information that may be
          collected automatically includes your computer or mobile device
          operating system type and version number, manufacturer and model,
          device identifier (such as the Google Advertising ID or Apple ID for
          Advertising), browser type, screen resolution, IP address, the website
          you visited before browsing to our Site, general location information
          such as city, state or geographic area; and information about your use
          of and actions on our Sites, such as pages you viewed, how long you
          spent on a page, navigation paths between pages, information about
          your activity on a page, access times, and length of access. Our
          service providers and business partners may collect this type of
          information over time and across third-party websites. This
          information is collected using cookies and similar technologies.
          Please refer to the Cookies and Similar Technologies section for more
          details.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>
            Social Media Networks and other Third Party Platforms.
          </strong>
          <br />
          We may offer single sign-on services that allow you to use third party
          login credentials to sign into Service. With your permission, Elcovia
          may also collect profile information contained in your third-party
          profile. Elcovia may also, for your use, enable you to import
          information about who you are connected to, as well as enable you to
          share information with those third-party sites. If you wish to
          discontinue such sharing, please refer to the settings on the
          third-party service or your preference page on Elcovia.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          We may also maintain pages for our company and our products on a
          variety of third-party platforms, such as Twitter, Instagram, YouTube,
          LinkedIn, and other social networking services. When you interact with
          our pages on those third-party platforms, the third-party’s privacy
          policy will govern your interactions on the relevant platform. If the
          third party platform provides us with information about our pages on
          those platforms or your interactions with them, we will treat that
          information in accordance with this Privacy Policy.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Sensitive Personal Information.</strong>
          <br />
          We ask that you not send us, and you not disclose, any sensitive
          personal information (e.g., social security numbers, information
          related to racial or ethnic origin, political opinions, religion or
          other beliefs, health, biometrics or genetic characteristics, criminal
          background or trade union membership) on the Sites, or otherwise to
          us.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          If you send or disclose any sensitive personal information to us when
          you use the Services, you must consent to our processing and use of
          such sensitive personal information in accordance with this Privacy
          Policy. If you do not consent to our processing and use of such
          sensitive personal information, you must not submit such sensitive
          personal information through our Sites.
        </p>
        <h2 className="text-xl font-bold mt-12 mb-4">
          3. Cookies and Similar Technologies
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>What are cookies?</strong>
          <br />
          Cookies are small data files stored on your computer or mobile device
          by a website. Our Sites may use both session cookies (which expire
          once you close your web browser) and persistent cookies (which stay on
          your computer or mobile device until you delete them) to provide you
          with a more personal and interactive experience on our Site.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          We use two broad categories of cookies:
        </p>
        <ol className="mt-4 list-decimal list-inside ml-4 text-gray-700 leading-6">
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>Essential Cookies:</strong> These cookies are essential to
            provide you with services available through our Site and to enable
            you to use some of its features. Without these cookies, the services
            that you have asked for cannot be provided, and we only use these
            cookies to provide you with those services.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>Functionality Cookies:</strong> These cookies allow our
            Sites to remember choices you make when you use our Sites. The
            purpose of these cookies is to provide you with a more personal
            experience and to avoid you having to re-select your preferences
            every time you visit our Site.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>Analytics and Performance Cookies:</strong> These cookies
            collect information about traffic to our Sites and about how
            individuals use our Sites. The information gathered may include the
            types of information described above in the section titled
            “Information automatically collected.” We use this information to
            help operate our Site more efficiently, to gather broad demographic
            information, monitor the level of activity on our Site, and improve
            the Site. We use Google Analytics for this purpose. These parties
            use their own cookies. You can find out more information about
            Google Analytics cookies{" "}
            <a
              href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            , and about how Google protects your data{" "}
            <a
              href="https://www.google.com/analytics/learn/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . You can prevent the use of Google Analytics relating to your use
            of our Site by downloading and installing the browser plugin
            available{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>Social Media Cookies:</strong> These cookies are used when
            you share information using a social media sharing button or “like”
            (or similar) button on our Site or you link your account or engage
            with our content on or through a social networking website such as
            Facebook or Twitter. The social network will record that you have
            done this. Social networks use their own cookies.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>Targeted and advertising cookies:</strong> These cookies
            track your browsing habits to enable us and third-party advertising
            networks to deliver ads that may be of interest to you. These
            cookies use information about your browsing history to group you
            with other users who have similar interests or browsing behavior.
            Based on the cookies that the third-party advertising network sets
            on our Site and other sites, advertisers can display advertisements
            that may be relevant to your interests on our Site and while you are
            on third party websites. You can choose to disable cookies, as
            described below, or to opt out of the use of your browsing behavior
            for purposes of targeted advertising. For opt-out instructions,
            please review the “Targeted online advertising” portion of the “Your
            Choices” section of this Privacy Policy.
          </li>
        </ol>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Disabling cookies</strong>
          <br />
          You can typically remove or reject cookies via your browser settings.
          In order to do this, follow the instructions provided by your browser
          (usually located within the “settings,” “help,” “tools,” or “edit”
          menus). Many browsers are set to accept cookies until you change your
          settings.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          For further information about cookies, including how to see what
          cookies have been set on your computer or mobile device and how to
          manage and delete them, visit{" "}
          <a
            href="https://www.allaboutcookies.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.allaboutcookies.org
          </a>
          .
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          If you do not accept our cookies, you may experience some
          inconvenience in your use of our Site. For example, we may not be able
          to recognize your computer or mobile device, and you may need to log
          in every time you visit our Site.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Other technologies</strong>
          <br />
          In addition to cookies, our Sites may use other technologies, such as
          Flash technology, pixel tags, and software development kits (or SDKs)
          to collect information automatically.
        </p>
        <h2 className="text-xl font-bold mt-12 mb-4">
          4. Use of Your Personal Data
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          We may use your Personal Data for the following purposes:
        </p>
        <ol className="mt-4 list-decimal list-inside ml-4 text-gray-700 leading-6">
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>To provide our Sites and Services:</strong> We use your
            Personal Data to facilitate the creation of and secure your account
            on our network, identify you as a User in the system, send you a
            welcome e-mail to verify ownership of the e-mail address provided
            when your account was created, respond to your inquiries related to
            employment opportunities or other requests, provide the Service and
            customer support you request, resolve disputes, collect fees, and
            troubleshoot problems, prevent, detect, and investigate potentially
            prohibited or illegal activities, and enforce our User Agreement,
            customize, measure and improve our Service and content, tell you
            about our Service, service updates, and promotional offers based on
            your communication preferences, compare information for accuracy,
            and verify it with third parties, and other uses as described when
            we collect the information.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>To communicate with you:</strong> If you request information
            from us (such as signing up for our newsletter), register on the
            Sites, or participate in our contests or promotions, we may send you
            Elcovia-related marketing communications as permitted by law. You
            will have the ability to opt out of such communications.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>
              Use of Personal Data About Buyers on Behalf of Our Sellers:
            </strong>{" "}
            We use personal information we collect about buyers from or on
            behalf of our sellers to provide services only as directed or
            authorized by the seller. We do not use this information for our own
            purposes. Typically, our clients direct or authorize us to use
            personal information collected on their behalf to enable ecommerce
            and payments functionality on our clients’ websites, to manage the
            delivery of electronic goods, and to deliver communications from the
            sellers to their buyers.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>To comply with law:</strong> We use your personal
            information as we believe necessary or appropriate to comply with
            applicable laws, lawful requests, and legal process, such as to
            respond to subpoenas or requests from government authorities.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>For compliance, fraud prevention, and safety:</strong> We
            use your personal information as we believe necessary or appropriate
            to (a) enforce the terms and conditions that govern the Sites; (b)
            protect our rights, privacy, safety or property, and/or that of you
            or others; and (c) protect, investigate and deter against
            fraudulent, harmful, unauthorized, unethical, or illegal activity.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>With your consent:</strong> We will request your consent to
            use your personal information where required by law, such as where
            we would like to send you certain marketing messages. If we request
            your consent to use your personal information, you have the right to
            withdraw your consent at any time in the manner indicated when we
            requested the consent or by contacting us. If you have consented to
            receive marketing communications from our third-party partners, you
            may withdraw your consent by contacting those partners directly.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            <strong>To create anonymous data:</strong> We may create Anonymous
            Data records from Personal Data by excluding information that makes
            the data personally identifiable to you. We use this Anonymous Data
            to analyze request and usage patterns so that we may enhance the
            content of our Service and improve site navigation. We reserve the
            right to use Anonymous Data for any purpose and disclose Anonymous
            Data to third parties in our sole discretion.
          </li>
        </ol>
        <h2 className="text-xl font-bold mt-12 mb-4">
          5. Our Disclosure of Your Personal Data
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          We may disclose Personal Data in the following circumstances:
        </p>
        <ol className="mt-4 list-decimal list-inside ml-4 text-gray-700 leading-6">
          <li className="font-normal text-sm text-gray-900 leading-6">
            To respond to legal requirements: We may disclose Personal Data to
            respond to legal requirements, enforce our policies, respond to
            claims that a listing or other content violates the rights of
            others, or protect anyone's rights, property, or safety. Such
            information will be disclosed in accordance with applicable laws and
            regulations.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To our service providers: We may share your Personal Data with
            service providers under contract who help with our business
            operations, such as fraud investigations, bill collection, affiliate
            and rewards programs.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To our subsidiaries and corporate affiliates: We may share your
            Personal Data with our subsidiaries and corporate affiliates for
            purposes consistent with this Privacy Policy.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To our sellers: We may disclose your Personal Data to our sellers
            when you make a purchase with them using our Services.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To third parties with your explicit consent: We may share your
            Personal Data with other third parties to whom you explicitly ask us
            to send your information or about whom you are otherwise explicitly
            notified and consent to when using a specific service.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To third-party payment processors: We currently use Stripe as our
            payment processor. Stripe may use personal information it collects
            when processing payments on our behalf as directed by us and for
            certain additional purposes. The information obtained by Stripe in
            connection with processing payments is subject to Stripe’s Privacy
            Policy.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To professional advisors: We may disclose your Personal Data to
            professional advisors, such as lawyers, bankers, auditors, and
            insurers, where necessary in the course of the professional services
            that they render to us.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To law enforcement or authorized third-parties: We may disclose
            information to law enforcement, governmental agencies, or authorized
            third-parties in response to a verified request relating to a
            criminal investigation or alleged illegal activity or any other
            activity that may expose us, you, or any other Elcovia User to legal
            liability. In such events, we will disclose information relevant to
            the investigation, such as name, city, state, ZIP code, telephone
            number, e-mail address, User ID history, IP address, fraud
            complaints, bidding and listing history, and anything else we may
            deem relevant to the investigation.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            To other business entities: In the event of a business transaction,
            such as a merger, consolidation, or acquisition, we may share your
            Personal Data with the other business entity involved. In such
            cases, we will make reasonable efforts to require that the recipient
            follows this Privacy Policy with respect to your Personal Data. In
            the event of insolvency, bankruptcy, or receivership, your Personal
            Data may also be transferred as a business asset.
          </li>
        </ol>
        <h2 className="text-xl font-bold mt-8 mb-4">6. Account Protection</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Your password is the key to your account. Use unique numbers, letters,
          and special characters, and do not disclose your Elcovia password to
          anyone. If you do share your password or your Personal Data with
          others, remember that you are responsible for all actions taken in the
          name of your account. If you lose control of your password, you may
          lose substantial control over your Personal Data and may be subject to
          legally binding actions taken on your behalf. Therefore, if your
          password has been compromised for any reason, you should immediately
          notify Elcovia and change your password.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">7. Your Choices</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>
            Accessing, Reviewing, and Changing Your Personal Data:
          </strong>{" "}
          You may change any of your Personal Data in your account by editing
          your profile within your account or by sending an e-mail to us at the
          e-mail address set forth below. You may request deletion of your
          Personal Data by us, but please note that we may be required to keep
          this information and not delete it (or to keep this information for a
          certain time, in which case we will comply with your deletion request
          only after we have fulfilled such requirements). Upon your request, we
          will close your account and remove your Personal Data from view as
          soon as reasonably possible, based on your account activity and in
          accordance with applicable law. We do retain Personal Data from closed
          accounts to comply with the law, prevent fraud, collect any fees owed,
          resolve disputes, troubleshoot problems, assist with any
          investigations, enforce our User Agreement, and take other actions
          otherwise permitted by law.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Marketing Communications:</strong> You may opt-out of
          marketing-related emails by clicking on a link at the bottom of our
          marketing emails, or by contacting us at support@elcovia.com. However,
          you may continue to receive service-related and other non-marketing
          emails.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Targeted Online Advertising:</strong> Some of our business
          partners may participate in organizations or programs that provide
          choices to individuals regarding the use of their browsing behavior or
          mobile application usage for targeted advertising purposes. You can
          opt-out of receiving targeted advertising on websites through members
          of the Network Advertising Initiative by visiting
          www.networkadvertising.org/choices, or the Digital Advertising
          Alliance by visiting www.aboutads.info/choices. European users can
          opt-out through the European Interactive Digital Advertising Alliance
          by visiting https://www.youronlinechoices.eu/. For mobile app users,
          the AppChoices mobile app available at
          https://www.youradchoices.com/appchoices may be used to opt-out of
          receiving targeted advertising in mobile apps through participating
          members of the Digital Advertising Alliance. Please note that we may
          also work with companies that offer their own opt-out mechanisms and
          may not participate in the opt-out mechanisms listed above. If you
          choose to opt-out of targeted advertisements, you may still see
          advertisements online, but they may not be relevant to you.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Do Not Track Signals:</strong> Some Internet browsers may be
          configured to send "Do Not Track" signals to the online services that
          you visit. Currently, we do not respond to "Do Not Track" signals. To
          find out more about "Do Not Track," please visit
          http://www.allaboutdnt.com.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Choosing Not to Share Your Personal Information:</strong>{" "}
          Where we are required by law to collect your personal information, or
          where we need your personal information in order to provide our
          services to you, if you do not provide this information when requested
          (or you later ask to delete it), we may not be able to provide you
          with our services. We will tell you what information you must provide
          to receive the services by designating it as required in or on the
          Sites or through other appropriate means.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">8. Security</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Your information is stored on our servers located in the United
          States. We use a variety of security technologies and procedures to
          help protect your Personal Data from unauthorized access, use, or
          disclosure. However, as you probably know, third parties may
          unlawfully intercept or access transmissions or private
          communications, and other Users may abuse or misuse your Personal Data
          that they collect from the Site. Therefore, we do not promise, and you
          should not expect, that your Personal Data or private communications
          will always remain private.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          9. International Transfers
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Elcovia is headquartered in Germany and has service providers in the
          United States and other countries, and your personal information may
          be transferred to the United States or other locations outside of your
          state, province, country, or other governmental jurisdiction where
          privacy laws may not be as protective as those in your jurisdiction.
          By using our Sites or providing us with any information, you consent
          to the transfer, processing, and storage of your personal information
          in the United States and other countries where Elcovia and its service
          providers operate.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          European Union users should read the important information provided at{" "}
          <a
            href="https://www.dataprivacymonitor.com/cybersecurity/cross-border-data-transfers-cutting-through-the-complexity/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            this link
          </a>{" "}
          about transfer of personal information outside of the European
          Economic Area.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">10. Third Parties</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Except as otherwise expressly included in this Privacy Policy, this
          document addresses only the use and disclosure of information we
          collect from you. If you disclose your information to others, whether
          they are bidders, buyers, or sellers on our Site or other sites
          throughout the Internet, different rules may apply to their use or
          disclosure of the information you disclose to them. Elcovia does not
          control the privacy policies of third parties, and you are subject to
          the privacy policies of those third parties where applicable. We
          encourage you to ask questions before you disclose your Personal Data
          to others.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          11. Changes to this Privacy Policy
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          We reserve the right to modify this Privacy Policy at any time. We
          encourage you to periodically review this page for the latest
          information on our privacy practices. If we make material changes to
          this Privacy Policy, we will notify you by updating the date of this
          Privacy Policy and posting it on the Sites and in the app stores where
          our mobile applications are available for download. We may (and, where
          required by law, will) also provide notification of changes in another
          way that we believe is reasonably likely to reach you, such as via
          e-mail (if you have an account where we have your contact information)
          or another manner through the Sites.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Any modifications to this Privacy Policy will be effective upon our
          posting of the new terms and/or upon implementation of the new changes
          on the Sites (or as otherwise indicated at the time of posting). In
          all cases, your continued use of the Sites after the posting of any
          modified Privacy Policy indicates your acceptance of the terms of the
          modified Privacy Policy.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">12. Contact Us</h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          If you have any questions or concerns at all about our Privacy Policy,
          please feel free to email us at support@elcovia.com.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">
          13. Additional Information for European Union Users
        </h2>
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Personal information</strong>
          <br />
          References to “personal information” in this Privacy Policy are
          equivalent to “personal data” governed by European data protection
          legislation.
        </p>{" "}
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Controller</strong>
          <br />
          For purposes of European data protection legislation, Elcovia, Inc. is
          the controller of personal information that we collect for our own
          business purposes. See the Contact Us section above for contact
          details.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          Elcovia acts as a processor to sellers through the Services, as
          described throughout this Privacy Policy. When we act as a processor
          to a seller, the relevant seller is the data controller of your
          personal information processed in connection with the sale or delivery
          of goods to you.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Legal bases for processing</strong>
          <br />
          We use your personal information only as permitted by law. We are
          required to inform you of the legal bases of our processing of your
          personal information, which are described in the table below. If you
          have questions about the legal basis of how we process your personal
          information, contact us at support@elcovia.com.
        </p>
        <br />
        <table className="w-full border-collapse border border-gray-300 font-normal text-sm text-gray-900 leading-6">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2">
                Processing Purpose
              </th>
              <th className="border border-gray-300 px-4 py-2">Legal Basis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                To provide our Sites and Services
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Processing is necessary to perform the contract governing our
                provision of the Services or to take steps that you request
                prior to signing up for the Services.
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                To communicate with you, To create anonymous data, For
                compliance, fraud prevention and safety
              </td>
              <td className="border border-gray-300 px-4 py-2">
                These processing activities constitute our legitimate interests.
                We make sure we consider and balance any potential impact on you
                (both positive and negative) and your rights before we process
                your personal information for our legitimate interests. We do
                not use your personal information for activities where our
                interests are overridden by the impact on you (unless we have
                your consent or are otherwise required or permitted to by law).
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                To comply with law
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Processing is necessary to comply with our legal obligations.
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                With your consent
              </td>
              <td className="border border-gray-300 px-4 py-2">
                Processing is based on your consent. Where we rely on your
                consent you have the right to withdraw it anytime in the manner
                indicated in the Service or by contacting us at
                support@elcovia.com.
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Use of Personal Data on Behalf of Our Sellers
              </td>
              <td className="border border-gray-300 px-4 py-2">
                We use personal data to comply with the instructions of the data
                controller, our sellers and, to the extent necessary, to perform
                the contract governing our provision of the Services or to take
                steps that you request prior to signing up for the Services.
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Use for new purposes</strong>
          <br />
          When we act as a data controller, we may use your personal information
          for reasons not described in this Privacy Policy where permitted by
          law and the reason is compatible with the purpose for which we
          collected it. If we need to use your personal information for an
          unrelated purpose, we will notify you and explain the applicable legal
          basis.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Retention</strong>
          <br />
          We will only retain your personal information for as long as necessary
          to fulfill the purposes for which we collected it, including for the
          purposes of satisfying any legal, accounting, or reporting
          requirements.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          To determine the appropriate retention period for personal
          information, we consider the amount, nature, and sensitivity of the
          personal information, the potential risk of harm from unauthorized use
          or disclosure of your personal information, the purposes for which we
          process your personal information and whether we can achieve those
          purposes through other means, and the applicable legal requirements.
        </p>
        <p className="font-normal text-sm text-gray-900 leading-6">
          When we no longer require the personal information we have otherwise
          collected about you, we will dissociate such information from the
          information attached to your content. In some circumstances we may
          anonymize your personal information (so that it can no longer be
          associated with you), in which case we may use this information
          indefinitely without further notice to you.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Your rights</strong>
          <br />
          European data protection laws give European Union users certain rights
          regarding their personal information. If you are located within the
          European Union, you may ask us to take the following actions in
          relation to your personal information that we hold in the capacity of
          a data controller:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="font-normal text-sm text-gray-900 leading-6">
            Opt-out. Stop sending you direct marketing communications. You may
            continue to receive service-related and other non-marketing emails.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Access. Provide you with information about our processing of your
            personal information and give you access to your personal
            information.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Correct. Update or correct inaccuracies in your personal
            information.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Delete. Delete your personal information.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Transfer. Transfer a machine-readable copy of your personal
            information to you or a third party of your choice.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Restrict. Restrict the processing of your personal information.
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Object. Object to our reliance on our legitimate interests as the
            basis of our processing of your personal information.
          </li>
        </ul>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          You can submit these requests by email to support@elcovia.com or our
          postal address provided above. We may request specific information
          from you to help us confirm your identity and process your request.
          Applicable law may require or permit us to decline your request. If we
          decline your request, we will tell you why, subject to legal
          restrictions. If you would like to submit a complaint about our use of
          your personal information or response to your requests regarding your
          personal information, you may contact us as described above or submit
          a complaint to the data protection regulator in your jurisdiction. You
          can find your data protection regulator{" "}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            here
          </a>
          .
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          To the extent we act as a processor on behalf of a seller, individuals
          should contact the relevant seller to exercise the rights and choices
          described in this section.
        </p>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          <strong>Cross-Border Data Transfer</strong>
          <br />
          Whenever we transfer your personal information out of the EEA to
          countries not deemed by the European Commission to provide an adequate
          level of personal information protection, the transfer will be based
          on:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="font-normal text-sm text-gray-900 leading-6">
            Pursuant to the recipient’s compliance with standard contractual
            clauses, EU-US Privacy Shield, or Binding Corporate Rules
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            Pursuant to the consent of the individual to whom the personal
            information pertains
          </li>
          <li className="font-normal text-sm text-gray-900 leading-6">
            As otherwise permitted by applicable EEA requirements.
          </li>
        </ul>
        <br />
        <p className="font-normal text-sm text-gray-900 leading-6">
          Please contact us if you want further information on the specific
          mechanism used by us when transferring your personal information out
          of the EEA.
        </p>
      </div>
    </>
  );
};
