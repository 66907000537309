import { template } from "lodash";
import { TemplateFeature } from "../../../../models/TemplateFeature";
import { localizedValueWithKey } from "../../../../utils/supported-locales";
import { classNames } from "../../../../utils/utils-react";

type TemplateFeaturesSectionProps = {
  features: Record<string, TemplateFeature[]>;
  locale: string;
};

/**
 * Displays the template features.
 */
export const TemplateFeaturesSection = ({
  features,
  locale,
}: TemplateFeaturesSectionProps) => {
  const templateFeatures = localizedValueWithKey(
    features,
    locale
  ) as TemplateFeature[];

  return (
    <>
      {templateFeatures && (
        <>
          <div className="mt-6 lg:mt-16 space-y-8 lg:space-y-12">
            {templateFeatures?.map(
              (feature: TemplateFeature, index: number) => (
                <section
                  key={feature.title}
                  className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center space-y-8 lg:space-y-12 lg:space-y-0 lg:gap-x-16 group"
                >
                  {/* Content Layout */}
                  <div
                    className={classNames(
                      index % 2 === 0
                        ? "lg:col-start-1"
                        : "lg:col-start-8 xl:col-start-",
                      "lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4",
                      "order-2", // Reversed order for smaller screens,
                      "text-center lg:text-left max-w-xl mx-auto lg:mx-0 lg:max-w-none"
                    )}
                  >
                    <h3 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl leading-7">
                      {feature.title}
                    </h3>
                    <p className="mt-3 text-md sm:text-lg font-normal text-gray-600 leading-6">
                      {feature.desc}
                    </p>
                  </div>

                  {/* Image Layout */}
                  <div
                    className={classNames(
                      index % 2 === 0
                        ? "lg:col-start-6 xl:col-start-5"
                        : "lg:col-start-1",
                      "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8",
                      "order-1" // Reversed order for smaller screens
                    )}
                  >
                    <img
                      src={feature.image}
                      alt={feature.image}
                      className="transition-all ease-in-out duration-300 object-cover object-center rounded-xl shadow-sm rounded-lg border border-1 border-gray-200/50 group-hover:shadow-md group-hover:md:scale-[1.01]"
                    />
                  </div>
                </section>
              )
            )}
          </div>
        </>
      )}
    </>
  );
};
