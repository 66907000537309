import { DocumentData, QueryDocumentSnapshot, Timestamp } from "firebase/firestore";

/**
 * Represents a feature for a Notion Template.
 */
export class TemplateFeature {
    public title: string;
    public desc: string;
    public image: string;
    public created: Timestamp;

    constructor(
        title: string,
        desc: string,
        image: string,
        created: Timestamp = Timestamp.now(),
    ) {
        this.title = title;
        this.desc = desc;
        this.image = image;
        this.created = created;
    }
}

export const templateFeatureConverter = {
    toFirestore(feature: TemplateFeature): DocumentData {
        return {
            title: feature.title,
            desc: feature.desc,
            image: feature.image,
            created: feature.created,
        };
    },
    fromFirestore(data: DocumentData): TemplateFeature {
        return new TemplateFeature(
            data.title,
            data.desc,
            data.image,
            data.created
        );
    },
};

