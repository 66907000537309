import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./HtmlEditorStyles.css";

import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
  genKey,
  Modifier,
  RawDraftContentState,
  RichUtils,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";

export const HtmlInputType = {
  SIMPLE: "simple",
  RICH: "rich",
};

/**
 * Props for the CustomEditor component.
 * @property {string} value - The initial HTML value to populate the editor with.
 * @property {function} onChange - Callback function to receive the converted HTML content.
 */
type HtmlEditorComponentProps = {
  value: string;
  onChange: (value: string) => void;
  inputType: (typeof HtmlInputType)[keyof typeof HtmlInputType];
};

/**
 * Component for rendering an editor and converting its content to HTML.
 * @param {CustomEditorProps} props - The props for the component.
 */
const HtmlEditorComponent: React.ForwardRefRenderFunction<
  Editor,
  HtmlEditorComponentProps
> = (
  { value, onChange, inputType }: HtmlEditorComponentProps,
  ref: ForwardedRef<Editor>
) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  /**
   * Initialize the editor's content state based on the provided HTML value.
   */
  useEffect(() => {
    const initialContentState = getEditorStateFromValue();
    if (
      !editorState
        .getCurrentContent()
        .equals(initialContentState.getCurrentContent())
    ) {
      setEditorState(initialContentState);
    }
  }, [value]);

  /**
   * Returns the initial editor state based on the provided HTML value.
   * @returns The initial editor state.
   */
  function getEditorStateFromValue() {
    const contentBlocksFromHTML = convertFromHTML(value);
    const initialContentState = ContentState.createFromBlockArray(
      contentBlocksFromHTML.contentBlocks,
      contentBlocksFromHTML.entityMap
    );
    return EditorState.createWithContent(initialContentState);
  }

  /**
   * Handles the change in the editor's content state and updates the HTML representation.
   * @param editorState The new editor state.
   */
  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onChange(editorStateToHtml(editorState));
  };

  /**
   * Handles the change in the raw content state and updates the HTML representation.
   * @param rawContentState The new raw content state.
   */
  const handleChange = (rawContentState: RawDraftContentState) => {
    // const newEditorState = EditorState.createWithContent(
    //   convertFromRaw(rawContentState)
    // );
    // setEditorState(newEditorState);
    setEditorState(editorState);
  };

  /**
   * Handles the change in the block type and updates the editor state accordingly.
   * @param blockType The new block type.
   */
  const handleBlockTypeChange = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  /**
   * Converts an EditorState to its HTML representation.
   * @param editorState The EditorState to convert.
   * @returns The HTML representation of the EditorState's content.
   */
  function editorStateToHtml(editorState: EditorState): string {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    return convert(rawContentState);
  }

  /**
   * Converts a raw content state to HTML.
   * @param rawContentState The raw content state to convert.
   * @returns The HTML representation of the content state.
   */
  function convert(rawContentState: RawDraftContentState): string {
    const contentState = convertFromRaw(rawContentState);
    const html = stateToHTML(contentState);
    return html;
  }

  /**
   * Handles the "Enter" key press in the editor.
   * @param {SyntheticKeyboardEvent} event - The keyboard event.
   * @param {EditorState} editorState - The current editor state.
   */
  const handleReturn = (
    event: SyntheticKeyboardEvent,
    editorState: EditorState
  ) => {
    // Prevent the default behavior of creating a new block
    event.preventDefault();

    if (event.shiftKey) {
      // Insert a line break in the current block
      const contentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        "\n"
      );
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        "insert-characters"
      );
      setEditorState(newEditorState);
      return true;
    } else {
      // Create a new EditorState with a line break at the current selection position
      const newContentState = Modifier.splitBlock(
        editorState.getCurrentContent(),
        editorState.getSelection()
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "split-block"
      );
      setEditorState(newEditorState);
    }
    return true;
  };

  const handlePastedText = (text: string, html: string | undefined) => {
    if (html) {
      // Convert the HTML content to ContentState
      const blocksFromHTML = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // Apply the pasted content at the current selection position
      const newContentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        contentState.getBlockMap()
      );

      // Create a new EditorState with the modified content
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );

      // Update the editor state with the modified content
      setEditorState(newEditorState);

      // Return true to indicate that you've handled the paste
      return true;
    }
    // If no HTML content, handle as plain text
    const newContentState = convertFromRaw({
      entityMap: {},
      blocks: [
        {
          key: genKey(), // Generate a key for the block
          type: "unstyled",
          text: text.trim(), // Remove leading/trailing spaces
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    });

    // Update the editor state with the modified content
    const newEditorState = EditorState.createWithContent(newContentState);
    setEditorState(newEditorState);

    // Return true to indicate that you've handled the paste
    return true;
  };

  return (
    <div>
       
      <Editor
        ref={ref}
        handleReturn={handleReturn}
        onChange={handleChange}
        editorState={editorState}
        handlePastedText={(text, html) => handlePastedText(text, html)}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          options: [
            "inline", // Bold, Italic, Underline, Strikethrough, Monospace, Superscript, Subscript
            // "colorPicker", // Text Color
            // "fontSize", // Font Size
            "blockType", // Normal, H1, H2, H3, H4, H5, H6, Blockquote, Code, OL, UL
            "list", // Ordered List, Unordered List
            "history", // Undo, Redo
            // "link", // Insert Link
          ],
          inline: {
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              //   "superscript",
              //   "subscript",
            ],
            inDropdown: false,
          },
          blockType: {
            inDropdown: true,
            options: [
              "Normal",
              "H1",
              "H2",
              "H3",
              //   "H4",
              //   "H5",
              //   "H6",
              "Blockquote",
              //   "Code",
              //   "OL",
              //   "UL",
            ],
          },
          normal: {
            icon: <span>N</span>,
            wrapper: (
              <p onClick={() => handleBlockTypeChange("unstyled")}>Normal</p>
            ),
          },
          list: {
            inDropdown: false,
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="w-full block bg-white rounded-md text-gray-900 ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
        editorClassName="editor-content my-8 mx-4"
      />
    </div>
  );
};

export default forwardRef(HtmlEditorComponent);
