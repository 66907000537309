import React from "react";
import "./HtmlTemplateTextStyles.css";

/**
 * Component for rendering HTML content with optional localization.
 * @param {string} html - The HTML content to render.
 */
function HtmlTemplateTextComponent({ html }: { html: string }) {
  const fixedHtml = html
    .replace(/<h3>(.*?)<strong>(.*?)<\/strong>(.*?)<\/h3>/gi, "<h4>$1$2$3</h4>")
    .replace(/<h2>(.*?)<strong>(.*?)<\/strong>(.*?)<\/h2>/gi, "<h3>$1$2$3</h3>")
    .replace(/<h1>(.*?)<strong>(.*?)<\/strong>(.*?)<\/h1>/gi, "<h2>$1$2$3</h2>")

    .replace(/h3/g, "h4")
    .replace(/h2/g, "h3")
    .replace(/h1/g, "h2");
  return (
    <div
      id="template-description"
      className="text-lg text-gray-700 leading-7"
      dangerouslySetInnerHTML={{
        __html: fixedHtml,
      }}
    ></div>
  );
}

export default HtmlTemplateTextComponent;
