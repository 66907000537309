import React, {
  useRef,
  forwardRef,
  ForwardedRef,
  ChangeEvent,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import { classNames } from "../../../utils/utils-react";

type TextAreaProps = {
  name?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder?: string;
  // Optional attributes
  defaultClassName?: string;
  additionalClassName?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  // Common event props
  onMouseEnter?: (event: MouseEvent<HTMLTextAreaElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLTextAreaElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  // Additional props
  count?: boolean;
  rows?: number;
};

const TextAreaComponent: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = (
  {
    name,
    onChange,
    value,
    placeholder,
    defaultClassName = "w-full block rounded-md border-0 py-2 pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
    additionalClassName,
    id,
    required,
    disabled,
    maxLength,
    minLength,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    count,
    rows,
  },
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [charCount, setCharCount] = useState(value.length);

  useEffect(() => {
    setCharCount(value.length);
  }, [value]);

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaValue = event.target.value;
    onChange(event);
    setCharCount(textareaValue.length);
  };

  const baseClassName = additionalClassName
    ? `${defaultClassName} ${additionalClassName}`
    : defaultClassName;

  let className = baseClassName;

  const isBelowMinLength = minLength !== undefined && charCount < minLength;
  const isAboveMaxLength = maxLength !== undefined && charCount > maxLength;

  return (
    <div style={{ position: "relative" }}>
      <textarea
        name={name}
        onChange={handleTextAreaChange}
        value={value}
        ref={ref || textAreaRef}
        className={className}
        id={id}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        rows={rows}
      />
      {count && (
        <span
          className={classNames(
            isBelowMinLength
              ? "text-red-500"
              : isAboveMaxLength
              ? "text-red-500"
              : "text-gray-500",
            "absolute text-xs text-gray-500 right-0 bottom-0 p-2"
          )}
        >
          {charCount}/{maxLength}
        </span>
      )}
    </div>
  );
};

export default forwardRef(TextAreaComponent);
