import { getStorage, ref as storageRef, ref, uploadBytes, UploadResult, deleteObject } from '@firebase/storage';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, StorageReference } from 'firebase/storage';

import { FileReferencePath, FireStorageReferencePath } from '../models/data/FireStorageReferencePath';
import { v4 } from 'uuid';

const auth = getAuth();
const currentUser = auth.currentUser;
const authUid = currentUser?.uid;
const storage = getStorage();

/**
 * Uploads a byte array file to a template.
 * @param uid The ID of the user who owns the template.
 * @param file The byte array of the file to upload.
 * @param templateId The ID of the template to upload the file to.
 * @param overwrite Whether or not to overwrite any existing file with the same name.
 * @return A Promise that resolves to the URL of the uploaded file on success, or throws an exception on failure.
 */
export async function uploadFileToTemplate(
  uid: string,
  file: File,
  templateId: string,
  overwrite: boolean = false,
  useDynamicId: boolean = false,
): Promise<string> {
  const referencePath = new FireStorageReferencePath.TemplateReferencePath();
  const path = referencePath.getFilePath(
    uid,
    templateId,
    useDynamicId ? `${v4()}${getExtension(file.name)}` : file.name
  );
  console.log(`uploadFileToTemplate() - userId: ${uid} Path:`, path);
  const ref: StorageReference = storageRef(storage, path);
  const result = await uploadFile(ref, file, overwrite);
  return result;
}

/**
 * Uploads a file to profile.
 * @param uid The ID of the user who owns the template.
 * @param file The byte array of the file to upload.
 * @param overwrite Whether or not to overwrite any existing file with the same name.
 * @return A Promise that resolves to the URL of the uploaded file on success, or throws an exception on failure.
 */
export async function uploadFileToProfile(
  uid: string,
  file: File,
  overwrite: boolean = false,
): Promise<string> {
  const referencePath = new FireStorageReferencePath.ProfileReferencePath();
  const path = referencePath.getFilePath(
    uid,
    file.name
  );
  console.log(`uploadFileToProfile() - userId: ${uid} Path:`, path);
  const ref: StorageReference = storageRef(storage, path);
  const result = await uploadFile(ref, file, overwrite);
  return result;
}

/**
 * Uploads a file to Firebase Storage.
 * @param ref The StorageReference to upload the file to.
 * @param byteArray The byte array of the file to upload.
 * @param uri The URI of the file to upload.
 * @param overwrite Whether or not to overwrite any existing file with the same name.
 * @return A Promise that resolves to the URL of the uploaded file on success, or rejects with an error on failure.
 */
async function uploadFile(
  ref: StorageReference,
  file: File,
  overwrite: boolean = false
): Promise<string> {
  try {
    if (!overwrite) {
      // Check if the file already exists in Firebase Storage.
      try {
        const checkDownloadUrl = await getDownloadURL(ref);
        console.log('uploadFile() - DownloadUrl was found for ref:', ref);
        return checkDownloadUrl;
      } catch (error) {
        console.log(
          'uploadFile() - DownloadUrl cannot be found for file with ref:',
          ref
        );
      }
    }

    console.log('uploadFile() - File will be uploaded');

    // Upload the file to Firebase Storage.
    let uploadTask: Promise<UploadResult>;
    if (file) {
      // If the file is a byte array, upload it using put().
      uploadTask = uploadBytes(ref, file);
      console.log('uploadFile() - File was uploaded');
    } else {
      throw new Error('Unknown file type');
    }

    // Get the download URL for the uploaded file.
    const snapshot = await uploadTask;
    const downloadUrl = await getDownloadURL(snapshot.ref);

    if (downloadUrl) {
      console.log('uploadFile() - DownloadUrl for file was received:', downloadUrl);
      return downloadUrl;
    } else {
      console.log('uploadFile() - It was not possible to generate a downloadUrl for the file.');
      throw new Error('Download URL is null');
    }
  } catch (error) {
    console.warn(error);
    throw error;
  }
}

function getExtension(filename: string): string {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex >= 0) {
    return filename.slice(dotIndex);
  }
  return '';
}

export async function deleteFile(imageUrl: string): Promise<void> {
  try {
    // Get a reference to the image in Firebase Storage and delete it.
    const ref = storageRef(storage, imageUrl);
    await deleteObject(ref);
  } catch (error) {
    console.error(error);
    throw error;
  }
}



