import disposableEmailDomains from 'disposable-email-domains';

/**
 * Check if the email is valid.
 * @param email The email address.
 * @returns True if the email is valid, false otherwise.
 */
export function isInvalidEmail(email: string) {
    const emailDomain = email.split("@")[1];
    return disposableEmailDomains.includes(emailDomain);
}