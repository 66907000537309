import React from "react";

import { classNames } from "../../../utils/utils-react";
import { Logger } from "../../../utils/utils-logging";
import LoadingScreenComponent from "../loading/LoadingScreenComponent";
import LoadingComponent from "../loading/LoadingComponent";

const logger = new Logger("LargeElcoviaButtonComponent");

interface Props {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  text: string;
  target?: string;
  rel?: string;
  parentClassNames?: string;
  prefix?: string;
  children?: React.ReactNode;
}

const LargeElcoviaButtonComponent: React.FC<Props> = ({
  onClick,
  disabled,
  loading,
  text,
  target,
  rel,
  parentClassNames,
  prefix,
}) => {
  const baseClassNames = classNames(
    "flex text-gray-800 bg-white hover:bg-gray-100 border border-gray-300 items-center",
    "transition-all ease-in-out duration-300 rounded shadow-sm font-medium outline-none",
    "aspect-[5/2] w-full",
    disabled == false ? "cursor-not-allowed" : "cursor-pointer"
  );

  const image = process.env.PUBLIC_URL + "/icons/elcovia.svg";
  return (
    <div className={parentClassNames}>
      <a onClick={onClick} target={target || ""} rel={rel || ""}>
        <button disabled={disabled} type="button" className={baseClassNames}>
          {loading ? (
            <LoadingComponent />
          ) : (
            <>
              <img className="h-12 w-12 ml-6" src={image} />
              <div className="ml-4 block text-start">
                <p className="font-medium text-xs text-gray-600 line-clamp-1">
                  {prefix}
                </p>
                <p className="font-semibold text-md text-gray-800 line-clamp-1 mr-6">
                  {text}
                </p>
              </div>
            </>
          )}
        </button>
      </a>
    </div>
  );
};

export default LargeElcoviaButtonComponent;
